@import "../../../../variables";
@import "../../../../mixin";
// Variables
$step-form-font-size: 16px;
$step-form-font-size-mobile: 12px;
$step-form-gap: 16px;
$step-form-gap-mobile: 8px;
$step-form-padding-top: 36px;
$step-form-padding-bottom: 48px;
$step-form-padding-mobile: 24px 0;
$circle-size: 36px;
$circle-size-mobile: 24px;
$separator-size: 24px;
$separator-size-mobile: 20px;

// Steps Container
.step-form__steps-container {
  display: flex;
  align-items: center;
  overflow-x: auto;
  font-size: $step-form-font-size;
  gap: $step-form-gap;
  padding-top: $step-form-padding-top;
  padding-bottom: $step-form-padding-bottom;

  @include media-query(xl, max) {
    font-size: $step-form-font-size-mobile;
    gap: $step-form-gap-mobile;
    padding: $step-form-padding-mobile;
  }
  @include media-query(md, max) {
    justify-content: space-between;
  }
}

// Individual Step
.step-form__step {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: $step-form-gap;
  flex-direction: row;
  flex-shrink: 0;

  @include media-query(md, max) {
    flex-direction: column;
    gap: $step-form-gap-mobile;
  }
}

// Step Name
.step-form__step-name {
  width: max-content;
  font-weight: 400;
  color: $gray-text;
  font-family: $text-font;
}

// Step Circle
.step-form__step-circle {
  border-radius: 50%;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  flex-shrink: 0;
  width: $circle-size;
  height: $circle-size;
  font-family: $primary-font;
  @include media-query(md, max) {
    width: $circle-size-mobile;
    height: $circle-size-mobile;
  }

  &--inactive {
    background-color: $white;
    color: $gray-text;
    border: 1px solid $gray-text;

    // Adjacent Step Name Styling
    & ~ .step-form__step-name {
      color: $gray-text;
      font-weight: 400;
    }
  }

  &--active {
    background-color: $body-text-color;
    color: $white;

    // Adjacent Step Name Styling
    & ~ .step-form__step-name {
      color: $body-text-color;
      font-weight: 500;
    }
  }

  &--complete {
    background-color: $primary-text;
    background-image: url("../Icons/check.svg");
    background-repeat: no-repeat;
    background-position: center;
    color: transparent;

    @include media-query(md, max) {
      background-image: url("../Icons/check-small.svg");
    }

    // Adjacent Step Name Styling
    & ~ .step-form__step-name {
      color: $primary-text;
      font-weight: 500;
    }
  }
}

// Separator
.step-form__separator {
  background-image: url("../Icons/chevron-right.svg");
  background-repeat: no-repeat;
  background-position: center;
  flex-shrink: 0;
  width: $separator-size;
  height: $separator-size;

  @include media-query(md, max) {
    width: $separator-size-mobile;
    height: $separator-size-mobile;
  }

  // Flip the background-image if the document direction is RTL
  [dir="rtl"] & {
    transform: scaleX(-1);
  }
}
