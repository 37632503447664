.featured-press {
  padding: 60px 0 30px 0;

  &__wrapper {
    padding: 14px 0px 5px;
    border: 2px solid #000;
    box-shadow: 0 0.1rem 0.1rem rgba(0, 0, 0, 0.1);
    background-color: #f9f9fa;
  }

  &__title {
    text-align: center;
    h3 {
      margin-bottom: 23px;
    }
  }

  &__text {
    padding: 20px;
    @media (max-width: 767px) {
      padding: 0 40px;
    }
  }

  &__header {
    margin-top: 1rem;
  }

  &__header {
    margin-bottom: 0.4rem;
  }

  &__text {
    font-size: 14px;
    color: rgba(72, 72, 72, 0.6);
    transition: margin-left 0.1s ease;
  }

  &__link {
    margin-top: 1rem;
    a {
      &:link,
      &:visited,
      &:hover,
      &:active {
        color: #484848;
        text-decoration: underline;
        text-transform: uppercase;
        font-weight: 500;
      }
    }
  }

  &__image {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    width: 94%;
    @media (max-width: 767px) {
      margin-bottom: 3rem;
      width: 80%;
    }
  }
}
