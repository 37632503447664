.error {
  flex-grow: 0.9;
  display: flex;
  justify-content: center;
  position: relative;
  flex-direction: column;
  text-align: center;

  &.floating {
    position: fixed;
    bottom: 0;
    z-index: 9999;
    text-align: left;
    transform: translateY(100%);
    transition: transform 400ms cubic-bezier(0.645, 0.045, 0.355, 1) 200ms;
  }

  &.floating.slide-in {
    transform: translateY(0%);
  }

  &.floating.slide-out {
    transform: translateY(100%);
    transition: transform 400ms cubic-bezier(0.645, 0.045, 0.355, 1) 0ms;
  }

  &__icon {
    margin-bottom: 30px;

    .icon {
      width: 50px;
      height: 50px;
      opacity: 0.2;
    }
  }

  &__content {
    padding: 0 20px;
  }

  &.floating &__content {
    background-color: #fff;
    box-shadow: 0 3px 4px rgba(0, 0, 0, 0.1);
    padding: 15px 35px 15px $container-padding;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }

  &__close {
    padding: 0;
    margin: 0;
    position: absolute;
    right: 5px;
    top: 12px;
    border: none;
    background: none;
    display: flex;
    justify-content: center;
    align-items: center;

    &:focus,
    &:hover {
      outline: none;
    }

    &:focus-visible {
      background-color: white;
      box-shadow: 0 0 0 3px white, 0 0 0 5px $primary-darkest;
    }

    .icon {
      width: 30px;
      height: 30px;
    }
  }

  &__message {
    margin: 0;
    font-size: 13px;
    font-family: $primary-font;
  }

  &__redirect-message {
    font-size: 12px;
    font-family: $primary-font;
    font-feature-settings: "tnum";
    font-variant-numeric: tabular-nums;

    a {
      color: inherit;
    }
  }
}

@media (min-width: 768px) {
  .error {
    &.floating {
      bottom: 15px;
      right: 15px;
      transform: translateX(calc(100% + 30px));
    }

    &.floating.slide-in {
      transform: translateX(0%);
    }

    &.floating.slide-out {
      transform: translateX(calc(100% + 30px));
    }

    &.floating &__content {
      background-color: #fff;
      box-shadow: 0 3px 4px rgba(0, 0, 0, 0.1);
      padding: 20px 45px 20px 20px;
      border-top: none;
      max-width: 400px;
      border-radius: 16px;
      hyphens: auto;
    }

    &__close {
      right: 10px;
      top: 10px;
      background-color: rgba(0, 0, 0, 0.05);
      border-radius: 40px;
      padding: 6px;

      &:hover {
        background-color: rgba(0, 0, 0, 0.1);
      }

      .icon {
        width: 24px;
        height: 24px;
      }
    }
  }
}
