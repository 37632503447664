@mixin defaultFocus {
  &:focus {
    outline: none;
  }
  &:focus-visible {
    box-shadow: 0 0 0 2px white, 0 0 0 4px $primary;
  }
}

.search-filter-button {
  $height: 55px;
  $label-text-color: #67767c;

  $textColor: black;

  $filled-text-color: #000000;
  $placeholder-text-color: #4b5154;

  [data-empty="true"],
  .select-filter__placeholder {
    color: $placeholder-text-color !important;
  }

  [data-empty="false"] {
    font-weight: 600;
    color: $filled-text-color;
  }

  @mixin states {
    &.disabled {
      opacity: 0.3;
      cursor: not-allowed;
    }

    &.loading {
      cursor: wait;
      opacity: 0.3;
    }
  }

  &__holder {
    width: 100%;
    height: $height;
    position: relative;

    @include states();
  }

  @mixin button {
    display: block;
    background: none;
    border: none;
    width: 100%;
    height: 100%;
    text-align: left;
    font-family: $primary-font;
    font-weight: 500;
    color: $textColor;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    height: 56px;
    padding: 0;

    @include states();
  }

  &__children {
    @include button();
  }

  &__button {
    @include button();
    padding-top: 12px;
    padding-left: 13px;
    padding-right: 5px;

    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    @include defaultFocus();
  }

  &__label {
    position: absolute;
    z-index: 1;
    padding-left: 14px;
    padding-top: 10px;

    font-family: $text-font;
    font-size: 9px;
    color: $label-text-color;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
}

.guests {
  &__holder {
    height: inherit;

    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 13px;
  }
  &__button-label {
    padding-top: 11px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__buttons {
    $size: 45px;

    margin-top: -2px;
    margin-right: 5px;
    display: flex;
    touch-action: manipulation;
    border: 1px solid rgba($primary-darker, 0.2);
    border-radius: $size;

    button {
      border: none;
      user-select: none;
      touch-action: manipulation;
      background-color: rgba($primary-darker, 0);
      border-radius: $size;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      padding-top: 3px;
      margin: 0;
      width: $size;
      height: $size;
      font-size: 20px;
      transition: all 80ms ease;
      color: black;

      @include defaultFocus();

      &:hover:not(:disabled) {
        box-shadow: 0 2px 4px 2px rgba($primary-darker, 0.18);
      }
      &:active:not(:disabled) {
        transform: scale(0.9);
        box-shadow: 0 1px 2px 1px rgba($primary-darker, 0.45);
      }

      &:disabled {
        opacity: 0.4;
        cursor: not-allowed;
      }
    }
  }
}

.primary-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 56px;
  width: 100%;
  background: $primary;
  border-radius: 16px;
  border: none;
  color: white;
  font-weight: 600;
  font-family: $primary-font;
  font-size: 17px;
  padding-top: 2px;

  background: $primary;

  $gradient-radial: radial-gradient(
    circle at center,
    shade($primary, 20%) 0%,
    shade($primary, 3%) 40%,
    tint($primary, 1%) 64%,
    tint($primary, 4%) 80%,
    tint($primary, 7%) 100%
  );

  background-image: $gradient-radial;
  background-size: 200% 200%;

  @include defaultFocus();

  &:disabled {
    cursor: default;
    opacity: 0.7;
  }

  &--circular {
    border-radius: 50px;
    padding: 0 20px;
  }
}

.close-button {
  padding: 0;
  margin: 0;
  background: white;
  color: black;
  padding: 10px;
  border: 1px solid rgba($primary-darker, 0.1);
  border-radius: 50px;

  @include defaultFocus();
}

.text-button {
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  font-weight: 500;
  color: black;
  text-decoration: underline;
}

@include media-breakpoint-up(lg) {
  .guests {
    &__buttons {
      $size: 32px;

      margin-right: 5px;
      display: flex;
      touch-action: manipulation;
      border: 1px solid rgba($primary-darker, 0.1);
      border-radius: $size;
      padding: 2px;

      button {
        border: none;
        user-select: none;
        touch-action: manipulation;
        background-color: rgba($primary-darker, 0);
        border-radius: $size;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        padding-top: 3px;
        margin: 0;
        width: $size;
        height: $size;
        font-size: 20px;
        transition: all 30ms ease;

        @include defaultFocus();

        &:hover:not(:disabled) {
          box-shadow: 0 2px 4px 2px rgba($primary-darker, 0.12);
        }
        &:active:not(:disabled) {
          transform: scale(0.95);
          box-shadow: 0 1px 2px 1px rgba($primary-darker, 0.24);
        }

        &:disabled {
          opacity: 0.4;
          cursor: not-allowed;
        }
      }
    }
  }
}
