@mixin priceText() {
  &__from {
    display: block;
    font-family: $primary-font;
    font-size: 12px;
    font-weight: 500;
    margin-top: -2px;
    margin-bottom: -4px;
    opacity: 0.7;
  }

  &__data {
    font-family: $primary-font;
    font-variant: tabular-nums;
    font-weight: 600;
    font-size: 18px;
  }

  &__night {
    font-family: $primary-font;
    font-size: 14px;
    font-weight: 500;
    margin-left: 3px;
    letter-spacing: 0.2px;
  }
}

$border: 1px solid rgba($primary-darkest, 0.2);
.suite__book-modal {
  &--error {
    height: 80%;
    display: flex;
    align-items: center;
  }
  .book-modal {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;

    &--mobile {
      padding-bottom: 200px;
      overflow-y: scroll;
    }

    &__swipeable-card-top,
    &__section {
      padding: 0 $container-padding;
    }

    &__check-availability {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      z-index: 2;

      .check-availability {
        background: rgba(white, 0.8);
        backdrop-filter: saturate(180%) blur(8px);
        box-shadow: 0 0 0 1px rgba($primary, 0.25);
        padding: 8px $container-padding;
        display: flex;
        align-items: center;

        &__price {
          flex: 0 0 40%;
          .price {
            color: $body-text-color;

            &__holder {
              display: flex;
              align-items: center;
              margin-bottom: -2px;
            }

            @include priceText();

            &__dates-button {
              display: block;
              border: none;
              background: none;
              padding: 0;
              margin: -4px 0 -1px 0;
              font-weight: 600;
              font-family: $primary-font;
              color: $primary-darkest;
              text-decoration: underline;
              font-size: 13px;
            }
          }
        }
        &__button {
          flex: 0 0 60%;
          height: 54px;
          font-weight: 600;
          font-size: 16px;
        }
      }
    }

    &__header {
      padding-top: 10px;
      padding-bottom: 10px;
      order: 1;

      .price {
        @include priceText();
      }
    }

    &__form {
      order: 2;
      .date-range {
        margin-bottom: 5px;
      }
    }

    &__continue {
      position: absolute;
      bottom: 55px;
      width: 100%;

      &.continue-mobile {
        padding-top: 15px;
        background: white;
        padding-bottom: 10px;
      }

      .continue-info {
        margin-top: 4px;
        text-align: center;
        font-size: 13px;
      }
      .continue-tooltip {
        position: absolute;
        opacity: 0;
        bottom: 0px;
        text-align: center;
        background: white;
        left: 50%;
        width: 70%;
        transform: translateX(-50%);
        right: 0;
        box-shadow: 0 1px 2px 1px rgba(black, 0.05),
          0 0 0 1px rgba($primary-darkest, 0.2);
        font-size: 11px;
        pointer-events: none;
        border-radius: 50px;
        padding: 3px 20px;
        font-weight: 500;
      }
      .continue-button:hover + .continue-tooltip {
        opacity: 1;
      }
    }

    &__quotation-summary {
      margin-top: 30px;
      order: 3;

      .quotation-summary {
        &__details {
          border-bottom: $border;
          margin-bottom: 15px;
          padding-bottom: 10px;

          .summary-item {
            display: flex;
            justify-content: space-between;
            align-items: center;

            font-family: $primary-font;
            color: $body-text-color;
            font-weight: 500;
            font-size: 16px;

            margin-bottom: 5px;

            &__data {
              font-variant: tabular-nums;
            }
            &__positive-amount {
              color: $success-info;
            }
          }
        }

        &__total {
          display: flex;
          justify-content: space-between;
          align-items: center;

          font-family: $primary-font;
          font-weight: 600;
          color: black;
          font-size: 17px;
          .total {
            &__data {
              font-variant: tabular-nums;
              letter-spacing: 0.5px;
            }
          }
        }
      }
    }

    &__deposit-info {
      border: $border;
      padding: 14px;
      margin: 5px;
      margin-top: 15px;
      display: flex;
      background: rgba($primary, 0.06);
      border-radius: 8px;
      order: 4;
      display: none;

      &--shown {
        display: flex;
      }

      .deposit-info {
        &__check-icon {
          .icon {
            filter: $icon-to-primary;
          }
        }
        &__description {
          margin-left: 10px;
          font-size: 14px;
          color: $body-text-color;

          .house-rules-anchor {
            padding: 0;
            margin: 0;
            background: none;
            border: none;
            text-decoration: underline;
            font-weight: 500;
            color: black;
          }
        }
      }
    }

    &__quotation-error {
      display: block;
      order: 5;
      width: 100%;
      padding: 0;

      .quotation-error {
        border: 1px solid rgba($error-info, 0.2);
        padding: 14px;
        margin: 5px;
        margin-top: 0;
        display: flex;
        background: rgba($error-info, 0.09);
        color: $danger-color-dark;
        border-radius: 8px;

        &__icon {
          .icon {
            filter: $icon-to-primary;
          }
        }

        &__message {
          margin-left: 10px;
        }
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .suite__book-modal {
    .book-modal {
      &__check-availability {
        .check-availability {
          .container {
            justify-content: space-between !important;
          }
          &__price {
            flex: 0 0 auto;
            margin-left: -10px;
            padding-right: 10px;

            .price {
              &__data {
                font-variant: none;
                font-size: 16px;
              }
              &__night {
                letter-spacing: 0;
              }
            }
          }
          &__button {
            flex: 0 0 auto;
            width: max-content;
            font-size: 15px;
            white-space: nowrap;
            padding-left: 18px;
            padding-right: 18px;
            margin-right: -10px;
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .suite__book-modal {
    display: block;
    position: sticky;
    top: 84px;
    flex: 0 0 100%;
    width: 400px;

    .book-modal {
      display: block;
      position: relative;
      width: 100%;
      margin-top: 40px;
      border-radius: 20px;
      box-shadow: 0 0 0 1px rgba($primary, 0.25),
        0 2px 4px 2px rgba($primary, 0.06);

      &__header {
        padding-top: 20px;
        padding-bottom: 15px;
      }

      &__check-availability {
        display: none;
      }

      &__continue {
        position: relative;
        bottom: unset;
        margin-top: 15px;
      }

      &__deposit-info {
        margin: 0;
        padding: 20px;
        border: unset;
        border-top: $border;
        border-radius: unset;
        background: unset;
        display: flex;
      }

      &__quotation-error {
        .quotation-error {
          margin: 15px 0 0 0;
          border: none;
          padding: 10px 20px;
          border-top: 1px solid rgba($error-info, 0.2);
          border-radius: unset;
        }
      }
    }
  }
}
