.homepage__app-showcase .app-showcase {
  margin: 45px (-$container-padding) 0 (-$container-padding);
  z-index: 0;

  background-color: $light-background;
  box-shadow: 0 0 0 1px $light-background-border;

  &__image-holder {
    z-index: 1;
    display: block;
    height: 320px;
    overflow-x: hidden;
    overflow-y: hidden;

    $transformX: 0%;
    $fadeInAmount: 15%;

    @keyframes fadeInUp {
      from {
        opacity: 0;
        -webkit-transform: translate3d($transformX, $fadeInAmount, 0);
        transform: translate3d($transformX, $fadeInAmount, 0);
      }
      to {
        opacity: 1;
        -webkit-transform: translate3d($transformX, 0%, 0);
        transform: translate3d($transformX, 0%, 0);
      }
    }

    .showcase-image-wrapper {
      position: relative;
      overflow: hidden;
      width: 100%;
      height: inherit;
      transform: translate3d($transformX, 0%, 0);
      opacity: 0;
      display: flex;
      justify-content: center;

      &[data-animate="true"] {
        opacity: 1;
        animation-duration: 1000ms;
        animation-delay: 0;
        animation-name: fadeInUp;
      }
    }
    .showcase-image {
      object-fit: cover;
      height: 107%;
      left: 0;

      &--desktop {
        display: none;
      }
    }
  }

  &__title {
    z-index: 2;
    padding: 30px 60px 0 $container-padding;
  }

  &__amenities {
    z-index: 2;
    padding: 15px $container-padding 25px $container-padding;

    .amenity {
      margin-bottom: 25px;
      display: flex;

      &__icon {
        margin-right: 20px;
      }

      &__text {
        font-size: 14px;
      }
    }
  }

  &__badges {
    border-top: 1px solid $light-background-border;
    padding: 25px;
    width: 100%;
    display: flex;
    align-content: center;
    box-shadow: 0 3px 6px 3px rgba($primary-darkest, 0.05) inset;

    .store-badge {
      height: 50px;
    }
  }

  &__articles {
    padding: $container-padding;

    display: flex;
    width: 100%;
    scroll-snap-type: x mandatory;
    overflow-x: scroll;

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none;
    }

    .article {
      min-width: fit-content;
      margin-bottom: 30px;
      margin-right: 30px;
      padding-left: $container-padding;
      scroll-snap-align: start;

      a {
        text-decoration: none;
        color: black;
      }

      &:last-of-type {
        min-width: 100vw;
        padding-right: $container-padding;
      }

      &__logo {
        margin-bottom: 5px;

        .logo-01 {
          height: 26px;
        }
        .logo-02 {
          height: 26px;
        }
        .logo-03 {
          height: 26px;
        }
      }

      &__text {
        font-size: 13px;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .homepage__app-showcase .app-showcase {
    position: relative;

    &__container {
      padding-right: 265px !important;
    }

    &__image-holder {
      height: 620px;
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);

      .showcase-image {
        height: 100%;

        &--desktop {
          display: block;
        }
        &--mobile {
          display: none;
        }
      }

      .showcase-screenshot {
        background: black;
        border-radius: 30px;
        position: absolute;
        left: 17px;
        border-radius: 30px;
        margin-top: 16px;
        z-index: -1;
        height: calc(100% - 28px);
        opacity: 0;

        transition: opacity 140ms ease;

        &[data-loaded="false"] {
          opacity: 0 !important;
        }

        &[data-show="true"] {
          opacity: 1;
        }
      }

      .screenshot-carousel-bg {
        display: block;
        content: "";
        background: black;
        border-radius: 30px;
        position: absolute;
        left: 14px;
        z-index: -2;
        border-radius: 30px;
        margin-top: 14px;
        height: calc(100% - 28px);
        width: calc(100% - 30px);
      }
    }

    &__amenities {
      max-width: 540px;
    }
    &__badges {
      box-shadow: none;
    }

    &__articles {
      display: block;
      width: 100%;

      .article {
        display: inline-block;
        padding-left: 0;
        &:last-of-type {
          min-width: unset;
          padding-right: 30px;
        }

        &__text {
          width: 90%;
          font-size: 13px;
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .homepage__app-showcase .app-showcase {
    position: relative;
    padding-top: 30px;

    &__articles {
      .article {
        min-width: auto;
        width: calc(100% / 3 - 30px);
      }
    }

    &__container {
      position: relative;
    }

    &__image-holder {
      height: 570px;
      right: 15px;
      top: -58px;
      transform: unset;
    }
  }
}
