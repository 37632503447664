.floating-label {
  position: relative;
  margin-bottom: 20px;

  @mixin label-floating-state {
    $padding: 5px;
    color: $primary !important;
    font-size: 14px;
    top: 0;
    background: $white;
    font-weight: 600;
    color: #b3b3b3;
    border-radius: $border-radius;
    pointer-events: all;
    padding: 0 $padding;
    margin-left: -$padding;

    &.textarea-label {
      top: -6px;
    }
  }

  &__input-area {
    position: relative;
    display: flex;
    align-items: center;
  }

  &__text-input {
    /* Remove First */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    font-weight: 400;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    background: white;
    color: black;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15);

    &:focus,
    &:hover {
      color: black;
    }

    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px rgba($primary, 0.9);
    }

    // On user click float label
    &:not(:placeholder-shown) ~ .floating-label__label {
      @include label-floating-state();
    }

    // Autofill
    &:-webkit-autofill,
    &:-webkit-autofill:focus {
      -webkit-text-fill-color: $primary;
      -webkit-box-shadow: 0 0 0 1000px white inset,
        0 0 0 1px rgba(0, 0, 0, 0.15);
      -webkit-text-fill-color: #000;
    }

    &:-webkit-autofill ~ .floating-label__label {
      @include label-floating-state();
    }
  }

  &__label {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    left: 15px;
    font-weight: 500;
    font-family: $primary-font;
    color: #696969;
    font-size: 16px;
    opacity: 1;
    padding: 0;
    margin: 0;
    line-height: 14px;
    background: none;
    pointer-events: none;
    transition: all 150ms cubic-bezier(0.645, 0.045, 0.355, 1);

    &::before {
        content: attr(data-before-label-content) !important;
      }
    &.textarea-label {
      top: 0;
      top: 15px;
      transform: unset;
    }
  }

  .show-floating-label &__label {
    @include label-floating-state();
  }

  select:read-only,
  textarea:read-only,
  input:read-only {
    cursor: default;
    background: rgba($color: $primary, $alpha: 0.1);

    & ~ label {
      background: $body-bg;
      color: $primary !important;
    }
  }

  select {
    -moz-appearance: none; /* Firefox */
    -webkit-appearance: none; /* Safari and Chrome */
    appearance: none;
    font-family: $text-font;
  }

  select ~ span {
    pointer-events: none;
    position: absolute;
    right: 18px;
    top: 18px;
  }
  .info-text,
  .error-text {
    font-family: $text-font;
    font-size: 11px;
    font-weight: 500;
    text-align: left;
    margin-top: 3px;
    gap: 4px;
    display: flex;
    align-items: center;
  }

  .error-text {
    color: $error-info;
    margin-left: 0;

    &__icon {
      font-size: 14px;
    }
  }

  &.error {
    input:not(:focus),
    textarea:not(:focus) {
      background-color: tint($error-info, 93%);
      box-shadow: 0 0 0 1px $error-info;
    }

    .floating-label__text-input:not(:focus) ~ .floating-label__label {
      color: shade($error-info, 20%) !important;
    }

    input:focus ~ label {
      background: $white;
    }

    label {
      color: $error-info;
    }

    .error-message {
      display: block;
    }
  }

  .password-input {
    padding-right: 70px;
  }

  .show-password {
    font-size: 12px;
    color: #5d5d5d;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-weight: 500;
    margin: 0;
    cursor: pointer;
    user-select: none;
    background: none;
    border: none;
    padding: 0;
    min-height: unset;
    padding: 10px;
    border-radius: 50px;
    height: max-content;

    &:hover {
      color: black;
      text-decoration: underline;
    }
    &:focus {
      outline: none;
    }
    &:focus-visible {
      color: $primary;
      box-shadow: 0 0 0 3px rgba($primary-darkest, 0.9);
    }
  }

  .password {
    padding-right: 35px;
  }

  .country-flag {
    height: 20px;
    width: 30px;
    overflow: hidden;

    display: inline-block;
    img {
      width: 30px;
      height: 20px;
      margin-top: -2px;
      object-fit: cover;
    }
  }

  .country-list {
    font-family: $primary-font;
    font-size: 15px;
    font-weight: 500;
    color: $dark-text;
    position: absolute;
    max-height: 200px;
    padding: 15px 0;
    border-radius: $border-radius * 2;
    overflow-y: scroll;
    background-color: $white;
    box-shadow: 0 5px 10px 5px rgba(0, 0, 0, 0.05);
    margin-top: 10px;
    z-index: 2;
    font-size: 15px;
    cursor: pointer;
    user-select: none;
    width: 100%;

    .country-item {
      position: relative;
      padding: 14px 15px 10px 15px;

      .country-name {
        position: absolute;
        top: 17px;
        line-height: 14px;
        left: 60px;
        padding-right: 70px;
      }

      .dial-code {
        position: absolute;
        right: 15px;
        top: 15px;
        font-weight: 600;
      }
      &:active {
        background-color: $primary;
        color: $white;
      }
    }
    .country-item:hover {
      background-color: $primary;
      transition: background-color 150ms ease-in-out;
      color: $white;
    }
  }

  &.country-select {
    input {
      cursor: pointer;

      &:focus {
        cursor: text;
      }
    }
  }

  &.country-code {
    input {
      padding-left: 66px;
    }
    .code {
      position: absolute;
      top: 24px;
      left: 30px;

      img {
        height: 25px;
        margin-top: -4px;
      }
    }

    .plus {
      position: absolute;
      top: 23px;
      left: 65px;
    }
  }

  &.file {
    input {
      cursor: pointer;

      background: white;

      &::-webkit-file-upload-button {
        display: none;
      }
    }

    .uploaded {
      position: absolute;
      right: 30px;
      top: 22px;

      .icon {
        color: $primary;
        width: 20px;
        height: 20px;
        opacity: 0;
        transform: scale(0.7);
        transition: opacity 0.2s ease, transform 0.2s ease;
      }

      &.show {
        .icon {
          opacity: 1;
          transform: scale(1);
        }
      }
    }

    .progress-holder {
      height: 30px;
      background: none;
      width: 100%;
      position: absolute;
      bottom: 16px;
      pointer-events: none;
      width: calc(100% - 30px);
      border-radius: $border-radius;
      overflow: hidden;
      display: flex;

      progress {
        -webkit-appearance: none;
        align-self: flex-end;
        width: 100%;
        height: 2px;
      }

      ::-webkit-progress-bar {
        background-color: $white;
      }

      ::-webkit-progress-value {
        background-color: $primary;
      }
    }
  }

  &.white-bg {
    input,
    select,
    textarea {
      background: $white;
    }
  }
}

.error .country-list {
  top: 100% !important;
}

@media (min-width: 768px) {
  .floating-label {
    &__text-input {
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.08);
    }
  }
}
