/* location-input */
.location-input {
  width: 100%;

  span {
    position: absolute;
    z-index: 1;
    right: 0;
    top: 0;
    margin-top: 17px;
    margin-right: 18px;
  }

  input {
    padding-right: 50px;
    padding-top: 20px;

    font-size: 16px;
    font-family: $primary-font;
    color: $dark-text;
    font-weight: 600;

    &::placeholder {
      font-weight: 500;
      color: rgba($color: $dark-text, $alpha: 0.8);
    }
  }
}

@media (min-width: 768px) {
  .location-input {
  }
}

@media (min-width: 992px) {
  .location-input {
  }
}

@media (min-width: 1200px) {
  .location-input {
  }
}
