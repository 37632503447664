@import "src/_main-imports.scss";

.buildingExperience {
  background: $light-background;
  margin: 24px 0;
  border-radius: 16px;

  p {
    font-family: $text-font;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    /* or 26px */

    letter-spacing: -0.02em;

    /* Text Gray */

    color: $gray-text;
    small {
      font-family: $text-font;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 160%;
      /* or 19px */

      letter-spacing: -0.02em;

      color: #7a7a7a;
    }
  }
}
