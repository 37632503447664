.logout-modal {
  position: fixed;
  background: $body-bg;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 999;

  .content_holder {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    text-align: center;
    margin-top: -50px;

    .buttons {
      display: flex;
      justify-content: center;

      .btn {
        margin: 0 8px;
        font-weight: 500;
        font-size: 14px;
        padding: 12px 20px 11px 20px;

        &.cancel {
          box-shadow: 0 0 0 1px rgba($color: #000000, $alpha: 0.1);

          &:hover {
            background: rgba($primary, 0.2);
            opacity: 0.85;
          }
        }

        &.log-out {
          background: $primary;
          color: white;
        }

        &:hover {
          opacity: 0.85;
        }
      }
    }
  }
}
