/* Google Maps */
.gm-style iframe + div {
  border: none !important;
  outline: none;

  &:focus {
    outline: none;
  }
}
.google_maps {
  &__container {
    position: relative;
    display: block;
    height: 100%;
    width: 100%;
  }

  $ui-border-radius: 12px;
  &__search-ui {
    position: absolute;
    display: none;
    width: 100%;
    height: 100%;
    z-index: 1;
    top: 0;
    left: 0;
    pointer-events: none;

    * {
      pointer-events: all;
    }

    .search-ui {
      &__corner {
        position: absolute;
        right: 15px;
        top: 15px;
        display: flex;
        flex-direction: column;
      }

      &__item {
        position: relative;
        background: white;
        border-radius: $ui-border-radius;
        box-shadow: 0 6px 12px 6px rgba(0, 0, 0, 0.1);
        margin: 6px 0;

        &:first-of-type {
          margin-top: 0;
        }

        &:last-of-type {
          margin-bottom: 0;
        }
      }

      &__button {
        color: #000;
        background: none;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 45px;
        width: 45px;
        font-size: 26px;

        &:first-of-type {
          border-top-left-radius: $ui-border-radius;
          border-top-right-radius: $ui-border-radius;
        }

        &:last-of-type {
          border-bottom-left-radius: $ui-border-radius;
          border-bottom-right-radius: $ui-border-radius;
        }

        &:focus {
          outline: none;
        }
        &:focus-visible {
          outline: none;
          box-shadow: inset 0 0 0 3px $primary;
          color: $primary;
        }
        &:hover {
          background: rgba(0, 0, 0, 0.05);
        }
      }

      &__button-divider {
        display: block;
        height: 1px;
        width: 70%;
        margin: 0 auto;
        background: rgba(0, 0, 0, 0.1);
      }
    }

    &.force-show {
      display: block;
    }
  }

  &__auto-pan {
    display: none;
  }

  &__placeholder {
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding: 20px;
    background-color: white;
    padding: 40vh 0;

    .content {
      position: relative;
      text-align: center;
      vertical-align: center;

      .icon {
        height: 25px;
        width: 25px;
      }

      p {
        margin-top: 20px;
        font-size: 13px;
        opacity: 0.5;
        font-family: $primary-font;
        font-weight: 500;
      }
    }

    &.error {
      .icon {
        color: var(--danger);
      }
    }

    &.loading {
      animation: mapLoadingAnimation 1s ease-in-out infinite;

      .icon {
        color: $primary;
        animation: spin 1s linear infinite;
      }
    }
  }
}

@media (min-width: 768px) {
  .google_maps {
    &__search-ui {
      display: block;
    }
  }
}

@media (min-width: 1200px) {
  .google_maps {
    &__marker {
      position: relative;
      background: $primary;
      // display: none; // remove
      height: 30px;
      line-height: 30px;
      width: 80px;
      left: 0;
      margin-left: -30px;
      top: 0px;
      margin-top: -38px;
      text-align: center;
      box-shadow: 0px 1px 2px 0 rgba(0, 0, 0, 0.1);
      border: 1px solid rgb(110, 130, 134);
      color: #fff;
      font-family: $primary-font;
      border-radius: $border-radius * 2;
      font-size: 14px;
      font-weight: 600;
      cursor: pointer;
      transition: background-color 80ms ease, border-color 80ms ease;

      &:after,
      &:before {
        top: 100%;
        left: 50%;
        border: solid transparent;
        content: "";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        transition: border-color 80ms ease;
      }

      &:after {
        border-top-color: $primary;
        border-width: 6px;
        margin-left: -6px;
      }
      &:before {
        border-top-color: rgb(110, 130, 134);
        border-width: 7px;
        margin-left: -7px;
      }

      &.hovered,
      &.selected {
        background: $dark-bg;
        color: $white;
        border-color: #000;
        z-index: 1;

        &:after {
          border-top-color: $dark-bg;
        }

        &:before {
          border-top-color: #000;
        }
      }
    }

    &__auto-pan {
      display: block;
      position: absolute;
      top: 15px;
      width: 100%;
      display: flex;
      justify-content: center;
      z-index: 1;
      pointer-events: none;

      .auto-pan-button {
        pointer-events: all;
        border: none;
        padding: 5px 16px 5px 5px;
        background: white;
        border-radius: 8px;
        box-shadow: 0 6px 12px 6px rgba(0, 0, 0, 0.1);
        color: black;
        font-size: 13px;
        font-weight: 500;
        display: flex;
        align-items: center;

        .icon_holder {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 25px;
          height: 25px;
          border: 1px solid rgba(0, 0, 0, 0.12);
          border-radius: 4px;
          margin-right: 10px;

          &.active {
            background: $primary;
          }

          .icon {
            width: 20px;
            height: 20px;
            padding-right: 1px;
            color: white;
          }
        }

        &:focus {
          outline: none;
        }
        &:focus-visible {
          outline: none;
          box-shadow: inset 0 0 0 3px $primary;
          color: $primary;
        }
        &:hover {
          background: whitesmoke;
        }
      }
    }

    &__search-ui {
      .button_close {
        display: none;
      }

      .item-zoom {
        margin-top: -6px;
      }
    }

    &__placeholder {
      background-color: rgba(0, 0, 0, 0.03);
    }
  }
}

@keyframes mapLoadingAnimation {
  0% {
    background: rgba(0, 0, 0, 0.02);
  }
  50% {
    background: rgba(0, 0, 0, 0.04);
  }
  100% {
    background: rgba(0, 0, 0, 0.02);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
