.suite__floating-topbar {
  display: none;
}
@include media-breakpoint-up(lg) {
  .suite__floating-topbar {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10;
    background: white;
    height: 74px;
    border-bottom: 1px solid rgba($primary, 0.2);
    box-shadow: 0 1px 2px 1px rgba($primary-darkest, 0.02);

    .nav-buttons {
      display: flex;
      height: 100%;
      align-items: center;

      $margin: 1px;
      $padding-horizontal: 8px;

      .nav-button {
        margin: 0 $margin;
        padding: 4px $padding-horizontal;
        background: none;
        border: none;
        border-radius: 9px;
        font-family: $primary-font;
        font-weight: 500;
        letter-spacing: -1%;
        display: flex;

        .icon-holder {
          overflow-x: hidden;
          width: 0;
          display: flex;
          align-items: center;
          height: 100%;
          margin-top: 1px;
          transition: all 200ms ease;
        }

        &:first-of-type {
          margin-left: -$padding-horizontal;
        }

        &:focus {
          outline: none;
        }
        &:focus-visible {
          box-shadow: 0 0 0 1px black, 0 0 0 3px white, 0 0 0 1px black;
        }
        &[data-active="true"],
        &:hover {
          background: #ebf1f3;
        }
        &[data-active="true"] {
          .icon-holder {
            width: 22px;
          }
        }
      }
    }

    .right-buttons {
      display: flex;
      align-items: center;
      position: relative;

      .book-modal-button {
        display: flex;
        align-items: center;
        white-space: nowrap;
        pointer-events: none;
        transform: translateX(-15px);
        opacity: 0;
        transition: transform 100ms ease, opacity 100ms ease;

        &--shown {
          opacity: 1;
          pointer-events: all;
          transform: translateX(0);
        }

        .book-button-left {
          text-align: right;
          margin-right: 15px;

          .price {
            color: $body-text-color;

            &__holder {
              display: flex;
              align-items: center;
              margin-bottom: -2px;
            }

            &__from {
              display: block;
              font-family: $primary-font;
              font-size: 12px;
              font-weight: 500;
              margin-top: -2px;
              margin-bottom: -4px;
              opacity: 0.7;
            }

            &__data {
              font-family: $primary-font;
              font-variant: tabular-nums;
              font-weight: 600;
              font-size: 18px;
            }

            &__night {
              font-family: $primary-font;
              font-size: 14px;
              font-weight: 500;
              margin-left: 3px;
              letter-spacing: 0.2px;
            }

            &__dates-button {
              display: block;
              border: none;
              background: none;
              padding: 0;
              margin: -4px 0 -1px 0;
              font-weight: 600;
              font-family: $primary-font;
              color: $primary-darkest;
              text-decoration: underline;
              font-size: 13px;
            }
          }
        }

        .book-button {
          padding-left: 30px;
          padding-right: 30px;
        }
      }

      .contact-button {
        position: relative;
        transform: translateX(100%);
        font-size: 15px;
        white-space: nowrap;
        width: 0px;

        pointer-events: none;
        opacity: 0;
        transition: transform 200ms ease, opacity 100ms ease 50ms,
          width 100ms ease;

        &--shown {
          opacity: 1;
          margin-left: 15px;
          width: 85px;
          pointer-events: all;
          transform: translateX(0%);
        }
      }
    }

    .floating-container {
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &--show {
      display: block;
    }
  }
}
