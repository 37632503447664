// Imports
@import "~bootstrap/scss/bootstrap.scss";
@import "~react-dates/lib/css/_datepicker.css";

// Overrides
@import "Styles/Form/override-bootstrap-form";
@import "Styles/Utils/override-bootstrap";

// Form
@import "Styles/Form/location-input";
@import "Styles/Form/guests-input";
@import "Styles/Form/city-list";
@import "Styles/Form/checkbox";
@import "Styles/Form/radiobox";
@import "Styles/Form/floating-label";

// Layout
@import "Styles/Layout/topbar";
@import "Styles/Layout/mobile-menu";
@import "Styles/Layout/footer";
@import "Styles/Layout/image-card";
@import "Styles/Layout/modal-form";
@import "Styles/Layout/floating-modal";
@import "Styles/Layout/logout-modal";
@import "Styles/Layout/feedback-modal";
@import "Styles/Layout/error";
@import "Styles/Layout/notfound";
@import "Styles/Layout/skeleton-loader";
@import "Styles/Layout/swipeable-card";

// Auth
@import "Styles/Auth/auth-form";

// Utils
@import "Styles/Utils/google-maps";
@import "Styles/Utils/slick";
@import "Styles/Utils/slick-slider";
@import "Styles/Utils/datepicker";
@import "Styles/Utils/loading-dots";
@import "Styles/Utils/buttons";
@import "Styles/Utils/cookie-consent";
@import "Styles/Utils/masonry";

// NotFound
@import "Styles/NotFound/main";

// Home Page
@import "Styles/Home/main";
@import "Styles/Home/header";
@import "Styles/Home/input-area";
@import "Styles/Home/featured-stays";
@import "Styles/Home/app-showcase";
@import "Styles/Home/gallery";

// Search
@import "Styles/Booking/Search/main";
@import "Styles/Booking/Search/filters";
@import "Styles/Booking/Search/map-markers";

// Suite
@import "Styles/Booking/Suite/main";
@import "Styles/Booking/Suite/utils";
@import "Styles/Booking/Suite/about";
@import "Styles/Booking/Suite/book-modal";
@import "Styles/Booking/Suite/cancellation-policy";
@import "Styles/Booking/Suite/floating-topbar";
@import "Styles/Booking/Suite/house-rules";
@import "Styles/Booking/Suite/images";
@import "Styles/Booking/Suite/neighbourhood";
@import "Styles/Booking/Suite/our-place-offers";
@import "Styles/Booking/Suite/question-box";
@import "Styles/Booking/Suite/sleeping-arrangements";
@import "Styles/Booking/Suite/things-to-note";

// Booking Payment
@import "Styles/Booking/Payment/main";

// Booking Confirmation
@import "Styles/Booking/Confirmation/main";

// Account
@import "Styles/Account/edit-profile";
@import "Styles/Account/request-to-delete";

// About us
@import "Styles/AboutUs/main";

// About us
@import "Styles/ContactUs/main";

// Legals
@import "Styles/Legals/main";

// Blog
@import "Styles/Blog/main";
@import "Styles/Blog/page";

// Featured Press
@import "Styles/FeaturedPress/featured";

// FAQs
@import "Styles/FAQs/faqs";
@import "Styles/FAQs/help-center";

// Online Check-in
@import "Styles/Online-Check-In/main";
