// Gilroy
@font-face {
  font-family: "Gilroy";
  src: url(./Styles/GilroyFont/Gilroy-Bold.otf);
  font-weight: 900;
}

@font-face {
  font-family: "Gilroy";
  src: url(./Styles/GilroyFont/Gilroy-Semibold.otf);
  font-weight: 600;
}
@font-face {
  font-family: "Gilroy";
  src: url(./Styles/GilroyFont/Gilroy-Medium.otf);
  font-weight: 500;
}
@font-face {
  font-family: "Gilroy";
  src: url(./Styles/GilroyFont/Gilroy-Light.otf);
  font-weight: 300;
}
@font-face {
  font-family: "Gilroy";
  src: url(./Styles/GilroyFont/Gilroy-RegularItalic.otf);
  font-style: italic;
}
@font-face {
  font-family: "Gilroy";
  src: url(./Styles/GilroyFont/Gilroy-Regular.otf);
}
