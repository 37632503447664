// $header-height: 130px;
$footer-height: 70px;
$calendar-height: calc(100vh - #{$footer-height});
$calendar-max-width: 420px;
$z-index: 13;
$ui-z-index: $z-index + 1;

// Daypicker overrides
$day-color: $primary;
.custom_datepicker {
  position: fixed;
  min-width: 320px;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: $z-index;
  transform: translateY(100%);
  display: none;
  touch-action: manipulation;

  &--focused {
    display: block;
    transform: translateY(0);
  }

  &__container {
    position: relative;
    height: 100%;
    width: 100%;
    background: white;
  }

  &__calendar {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &__flexible {
    position: relative;
    height: calc(100% - #{$footer-height});
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    border-top: 1px solid rgba(black, 0.08);
    overflow-y: scroll;
    padding-bottom: 300px;

    .flexible {
      &__duration-text {
        margin-top: 45px;
        margin-bottom: 10px;
        font-size: 18px;
        color: rgba(black, 0.8);
        font-family: $primary-font;
        font-weight: 500;

        span {
          color: black;
          font-weight: 900;
        }
      }

      &__types {
        border-radius: 50px;
        padding: 3px 0px;
        width: max-content;
        margin: 0 auto;
        border: 1px solid rgba(black, 0.08);
      }

      &__button {
        border: none;
        margin: 0 5px;
        background: none;
        border-radius: 50px;
        padding: 10px 20px 8px 20px;
        font-family: $primary-font;
        font-weight: 600;

        &.active {
          background: $primary;
          color: white;
        }

        &:focus {
          outline: none;
        }

        &:focus-visible {
          box-shadow: 0 0 0 2px white, 0 0 0 4px $primary;
        }
      }

      &__year-divider {
        position: relative;
        font-weight: 500;
        font-size: 13px;
        margin-top: 20px;
        margin-bottom: 8px;
        opacity: 0.8;
        span {
          font-family: $text-font;
          background: white;
          padding: 0 10px;
        }

        &::before {
          content: " ";
          position: absolute;
          width: 100%;
          height: 1px;
          left: 0;
          right: 0;
          top: 50%;
          margin: 0 auto;
          transform: translateY(-50%);
          z-index: -1;
          border-top: 1px solid rgba(0, 0, 0, 0.12);
        }
      }

      &__month-text {
        display: block;
        margin: 0 auto;
        margin-top: 70px;
        margin-bottom: 20px;
        max-width: 250px;
        height: 50px;
      }

      &__months {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 300px;
        margin: 0 auto;

        .button {
          width: 100%;

          &__year {
            display: none;
          }

          &__month {
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;
            width: 100%;
            background: none;
            border-radius: 50px;
            margin-bottom: 10px;
            padding: 14px 20px 12px 20px;
            border: 1px solid rgba(black, 0.08);
            text-align: left;
            font-family: $primary-font;
            font-weight: 600;

            &.selected {
              background: $primary;
              color: white;

              .button__icon {
                display: block;
                position: absolute;
                right: 20px;
              }
              .icon {
                width: 20px;
                height: 20px;
              }
            }

            &:focus {
              outline: none;
            }

            &:focus-visible {
              box-shadow: 0 0 0 2px white, 0 0 0 4px $primary;
            }
          }
        }
      }
    }
  }

  &__picker {
    height: 100%;
    width: 100%;
    $z-index: 13;
  }

  &__header,
  &__footer {
    width: 100%;
    z-index: $ui-z-index;
    background: white;
  }

  &__header {
    display: block;
    padding: 20px;
  }
  &__footer {
    border-top: 1px solid rgba(black, 0.08);
    position: sticky;
    bottom: 0;
    height: $footer-height;
    padding: 0 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .button {
      height: max-content;
      padding: 14px 40px;
      font-weight: 500;
      border-radius: 50px;

      &__clear {
        padding: 14px 0;
      }

      &:focus {
        outline: none;
      }

      &:focus-visible {
        box-shadow: 0 0 0 2px white, 0 0 0 4px $primary;
      }
    }
    .right-buttons {
      display: flex;
      height: 100%;
      justify-content: flex-end;
      align-items: center;

      button {
        // margin-left: 20px;

        &:first-of-type {
          margin-left: 0;
        }
      }
    }
  }

  // Buttons, etc.
  &__button {
    padding: 0;
    background: none;
    border: none;

    &:focus {
      outline: none;
    }
  }
  &__button.button {
    // Calendar and flex choice
    &__flex_choice {
      margin: 0;
      padding: 10px 15px 9px 15px;
      font-weight: 500;
      border-radius: 50px;
      font-size: 14px;

      &.active {
        color: white;
        background: $primary;
      }

      &:focus {
        outline: none;
      }

      &:focus-visible {
        box-shadow: 0 0 0 2px white, 0 0 0 4px $primary;
      }
    }

    // Close
    &__close {
      position: absolute;
      right: 15px;
      top: 18px;
      .icon {
        width: 24px;
        height: 24px;
        color: $primary;
      }

      &.text-close {
        position: unset;
        right: unset;
        top: unset;
        width: max-content;
      }

      &:focus {
        outline: none;
      }

      &:focus-visible {
        box-shadow: 0 0 0 2px white, 0 0 0 4px $primary;
      }
    }

    &__apply {
      min-width: 150px;
      width: max-content;
    }
  }

  &__button-group {
    display: flex;
    width: max-content;
    margin: 0 auto;
    height: calc(100% - 50px);
    align-items: center;

    .button-group__holder {
      padding: 3px;
      border: 1px solid rgba(black, 0.08);
      border-radius: 50px;
    }
  }

  &__title {
    margin: 0 auto;
    text-align: center;
    font-size: 16px;
    padding-bottom: 15px;
    color: black;
  }
}
.DayPicker {
  &__hidden {
    visibility: visible;
  }

  // Header
  &_weekHeader {
    &__verticalScrollable {
      border-bottom: 1px solid rgba(black, 0.08);
    }

    &_ul {
      display: flex;
      justify-content: space-between;
      max-width: calc(#{$calendar-max-width} - 30px);
      margin: 0 auto;
      padding-bottom: 3px;
    }
    &_li {
      small {
        font-weight: 600;
        color: black;
        font-family: $primary-font;
      }
    }
  }

  // Navigation
  .DayPickerNavigation {
    &__verticalScrollableDefault {
      height: auto;
    }
    &_prevButton__verticalScrollableDefault {
      margin-top: 35px;
    }
    &_nextButton__verticalScrollableDefault {
      margin-top: 35px;
      margin-bottom: 350px;
    }
    &_button {
      border: none;
      box-shadow: none;
    }
    &_svg {
      &__vertical {
        width: 30px;
        height: 30px;
      }
    }

    &_button__disabled {
      opacity: 0;
      height: 10px;
      margin: 0;
      pointer-events: none;
    }
  }

  .CalendarMonth {
    margin-bottom: 50px;

    @mixin disabledDay {
      text-decoration: line-through;
      cursor: default;
      $disabledColor: #d0d0d0;
      color: $disabledColor;

      .custom_calendar_day {
        .day {
          color: $disabledColor;

          &:hover {
            border: none;
          }
        }
      }
      .custom_calendar_day.checkout_only {
        .day {
          color: $disabledColor;
        }
        &:active {
          .day {
            background: none;
            border: none;
            color: $disabledColor;
            .los {
              display: unset;
            }
          }
        }
      }
    }

    &_caption {
      font-family: $primary-font;
      color: black;
    }
    &_table {
      // display: block;
      // position: relative;
      width: 100% !important;
      max-width: $calendar-max-width;

      .CalendarDay {
        border: none;
        padding: 0;
        padding-bottom: 4px;

        &:hover,
        &focus,
        &:active {
          background: none;
        }

        .custom_calendar_day {
          height: 100%;
          width: 100%;
          position: relative;

          .day {
            font-family: $primary-font;
            font-weight: 600;
            font-size: 13px;
            position: relative;
            z-index: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            width: 100%;
            border-radius: 200px;
            border: 1px solid transparent;
            color: black;

            &:hover {
              border: 1px solid rgba($day-color, 0.3);
            }

            .los {
              pointer-events: none;
              display: none;
              position: absolute;
              white-space: nowrap;
              z-index: 1;
              transform: translate3d(0, -40px, 0);
              background: white;
              padding: 2px 8px;
              border-radius: 4px;
              color: black;
              box-shadow: 0 0 0 1px rgba($primary-darkest, 0.08);
            }
          }

          &.checkout_only {
            cursor: default;

            .day {
              color: rgb(113, 113, 113);
              cursor: default;

              &:hover {
                background: rgba($day-color, 0.08);
                color: rgba($primary-darkest, 0.8);
                .los {
                  display: block;
                }
              }
            }
          }
        }

        &__blocked_calendar,
        &__blocked_calendar:active,
        &__blocked_calendar:hover {
          background: none;
          border: none;
          color: grey;
          @include disabledDay();
        }

        &__blocked_out_of_range {
          @include disabledDay();
        }

        &__hovered_span {
          background: none;
        }

        &__selected {
          background: none;

          .custom_calendar_day {
            .day {
              background: $day-color;
            }
          }

          &_span {
            background: none;
            .custom_calendar_day {
              background: rgba($day-color, 0.08);
            }
          }

          &_start,
          &_end {
            position: relative;

            .custom_calendar_day {
              z-index: 1;

              &::after {
                content: "";
                display: block;
                position: absolute;
                width: 50%;
                height: 100%;
                right: 0;
                top: 0;
                background: rgba($day-color, 0.08);
              }
              .day {
                color: white;

                &:hover,
                &:focus,
                &:active {
                  border: 1px solid transparent;
                }
              }
            }
          }

          &_start {
            .custom_calendar_day {
              &::after {
                right: 0;
              }
            }
          }

          &_end {
            .custom_calendar_day {
              &::after {
                left: 0;
              }
            }
          }
        }

        &__selected_start {
          .custom_calendar_day {
            .day:hover {
              position: relative;
              .los {
                display: block;
              }
            }

            &--only_start_selected {
              .day .los {
                display: block;
              }
            }
          }
        }

        &__blocked_minimum_nights:not(.CalendarDay__selected) {
          .custom_calendar_day {
            cursor: default;

            .day {
              color: rgba(black, 0.5);
            }
          }
        }

        &__firstDayOfWeek {
          .custom_calendar_day {
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
            .day {
              .los {
                left: 0;
              }
            }
          }
        }

        &__lastDayOfWeek {
          .custom_calendar_day {
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
            .day {
              .los {
                right: 0;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .DayPicker {
    .CalendarMonthGrid {
      width: auto !important;
      max-width: $calendar-max-width;
    }
  }
}

@media (min-width: 992px) {
  .custom_datepicker {
    position: absolute;
    left: unset;
    right: 0;
    top: 60px;
    width: 800px;
    height: auto;
    transform: unset;
    border-radius: 20px;
    background: white;
    z-index: 3;
    box-shadow: 0 0 0 1px rgba($primary-darkest, 0.08),
      0 6px 12px 6px rgba($primary-darkest, 0.08);
    display: unset;
    visibility: hidden;
    overflow: hidden;

    &--in-view-tracker {
      display: block;
      position: absolute;
      width: 100%;
      height: 1px;
    }

    &--focused {
      visibility: visible;
      display: unset;
      transform: unset;
    }

    &--compact {
      width: 730px;
    }
    &--compact &__footer {
      height: 70px;
      padding: 10px;

      .button {
        height: 100%;
        &__apply {
          height: 100%;
        }
      }
    }

    &--compact &__header {
      padding: 10px;
    }

    &--months-animation-running {
      .DayPicker {
        &_transitionContainer {
          overflow: hidden;
        }
      }
    }

    &__container,
    &__header,
    &__footer {
      background: none;
    }

    &__container {
      height: max-content;
    }

    &__footer {
      border-top: 1px solid rgba(black, 0.08);
      position: relative;
      height: 70px;
      padding: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .button {
        height: 100%;
        padding: 14px 40px;
        font-weight: 500;
        border-radius: 50px;

        &__clear {
          padding: 14px 0;
        }

        &__apply {
          height: 100%;
          border-radius: 12px;
        }

        &:focus {
          outline: none;
        }

        &:focus-visible {
          box-shadow: 0 0 0 2px white, 0 0 0 4px $primary;
        }
      }
    }

    &__flexible {
      height: auto;
      min-height: 200px;
      max-height: calc(100vh - 400px);
      padding-bottom: 0px;

      .flexible {
        position: relative;
        &__duration-text {
          margin-top: 35px;
          font-size: 16px;
        }

        &__types {
          padding: 4px 0px;
        }

        &__month-text {
          margin-top: 45px;
          margin-bottom: 25px;
          max-width: unset;
        }

        &__months {
          display: block;
          flex-direction: unset;
          justify-content: unset;
          width: 100%;
          margin-bottom: 20px;

          .button {
            position: relative;
            height: auto;
            width: auto;
            display: inline-flex;

            &__month {
              border: none;
              min-width: 100px;
              width: max-content;
              margin: 0px 4px;
              margin-bottom: 20px;
              display: inline;
              justify-content: center;
              align-items: center;
              position: relative;
              text-align: center;
              box-shadow: 0 0 0 1px rgba($primary-darkest, 0.08);
              transition: all 80ms ease-in-out;

              &.selected {
                background: white;
                color: black;
                box-shadow: 0 0 0 2px $primary-dark;
                transform: translateY(-5px);

                .button__icon {
                  position: absolute;
                  margin: 0 auto;

                  right: -5px;
                  top: -5px;

                  background: $primary-dark;
                  width: 24px;
                  height: 24px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  border-radius: 30px;
                }
                .icon {
                  color: white;
                  width: 18px;
                  height: 18px;
                }
              }

              &:focus {
                outline: none;
              }

              &:focus-visible {
                box-shadow: 0 0 0 2px white, 0 0 0 4px $primary;
              }
            }
          }
        }
      }
    }

    &__calendar {
      margin-top: 0;
    }

    &__picker {
      display: flex;
      justify-content: center;
    }

    .custom-nav {
      position: absolute;
      border: none;
      background: none;
      padding: 0;
      margin: 0;
      top: -5px;
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 30px;

      &:focus {
        outline: none;
      }

      &:focus-visible {
        box-shadow: 0 0 0 2px rgba($primary-darkest, 0.9);
      }

      &:hover {
        background: rgba($primary-darkest, 0.08);
      }

      &.nav-left {
        left: 28px;
      }
      &.nav-right {
        right: 28px;
      }
    }
  }
  .DayPicker {
    background: none;
    &__hidden {
      visibility: hidden;
    }

    .DayPickerNavigation {
      z-index: 4;

      &_button__disabled {
        opacity: 0.3;
        height: unset;
        margin: unset;
        pointer-events: all;

        .custom-nav {
          cursor: not-allowed;
        }
      }
    }

    .CalendarMonthGrid {
      height: auto;
      background: none;
      z-index: 3;
    }

    .CalendarMonth {
      margin-bottom: 0px;
      background: none;

      &_caption {
        padding: 0;
        margin-bottom: 40px;
        font-size: 16px;
        font-family: $primary-font;
        color: rgba($primary-darkest, 0.9);

        strong {
          font-weight: 600;
        }
      }

      &_table {
        border-collapse: inherit;
        border-spacing: 0 2px;

        .CalendarDay {
          padding-bottom: 0px;

          .custom_calendar_day {
            height: 47px;
            width: 48px;
          }

          &__hovered_span {
            .custom_calendar_day {
              background: rgba($day-color, 0.08);
            }

            &:hover {
              .custom_calendar_day {
                background: none;

                &::after {
                  content: "";
                  display: block;
                  position: absolute;
                  width: 50%;
                  height: 100%;
                  left: 0;
                  top: 0;
                  background: rgba($day-color, 0.08);
                }

                .day {
                  background: $day-color;
                  color: white;
                }
              }
            }
          }
        }
      }
    }

    &_weekHeader {
      top: 38px;
    }
    &_weekHeader_li {
      font-size: 15px;
      opacity: 0.5;
    }

    &__withBorder {
      box-shadow: none;
      border-radius: 0px;
    }

    .DayPickerNavigation_button__horizontalDefault {
      top: -10px;
    }

    &_transitionContainer {
      overflow: unset;
    }
  }
}
