/* legal */
.legal {
  padding: 30px $container-padding;

  h2 {
    padding: 15px 0;
  }
  p {
    font-family: $text-font;
    font-size: 13px;
    line-height: 21px;
    color: #3d3d41;
  }

  a {
    color: $primary;
    text-decoration: none;
  }
}

@media (min-width: 768px) {
  .legal {
  }
}

@media (min-width: 992px) {
  .legal {
  }
}

@media (min-width: 1200px) {
  .legal {
  }
}
