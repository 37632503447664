.suite {
  // Utils
  $scrollGalleryZIndex: 99;
  &__scroll-gallery {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: $scrollGalleryZIndex;
    transition: opacity 200ms ease, transform 200ms ease;
    transform: translateY(40px);
    opacity: 0;
    pointer-events: none;

    &--active {
      transform: translateY(0px);
      opacity: 1;
      pointer-events: all;
    }

    .gallery {
      position: absolute;
      width: 100%;
      height: 100%;
      overflow-y: hidden;
      margin-top: 60px;

      &__container {
        height: 100%;
        width: 100%;
        overflow-y: scroll;
        background: white;

        .container {
          padding: 0;
        }
      }

      &__content {
        display: grid;
        gap: 8px;
        padding-bottom: 120px;
      }

      &__top {
        top: 0;
        position: fixed;
        width: 100%;
        height: 60px;
        background: white;

        .close-button {
          margin-top: 5px;
          margin-left: 5px;
        }
      }

      &__grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-column-gap: 8px;
        grid-row-gap: 8px;
        position: relative;
      }

      .gallery-item {
        position: relative;

        &::after {
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          content: "";
          background: black;
          z-index: 1;
          opacity: 0;
          transition: opacity 80ms ease;
        }
        .gallery-image {
          width: 100%;
          height: 100%;
          object-fit: cover;
          width: 100%;
          max-height: 100%;
          cursor: pointer;
        }

        &:hover::after {
          opacity: 0.1;
        }
      }
    }
  }

  &__full-screen-gallery {
    position: fixed;
    background: black;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: calc(#{$scrollGalleryZIndex} + 1);
    touch-action: pinch-zoom !important;
    opacity: 0;
    pointer-events: none;
    transition: all 200ms ease;

    .full-screen-gallery {
      &__top {
        position: absolute;
        top: 0;
        width: 100%;
        height: 60px;
        z-index: 1;

        .close-button {
          margin-top: 5px;
          margin-left: 5px;
          background: none;
        }
      }

      &__counter {
        position: fixed;
        right: 10px;
        top: 11px;
        z-index: 1;
        color: white;
        padding: 4px 8px;
        font-size: 16px;
        border-radius: 30px;
        font-weight: 500;
        font-variant-numeric: tabular-nums;
      }

      &__index-detector {
        display: block;
        position: fixed;
        left: 50%;
        top: 50%;
        width: 10px;
        height: 10px;
        z-index: 1;
        pointer-events: none;
      }

      &__gallery {
        height: 100%;
        width: 100%;
        padding-bottom: 120px;

        display: flex;
        overflow-x: scroll;
        width: 100%;
        scroll-snap-type: x mandatory;

        .gallery-item {
          display: block;
          flex: 0 0 100%;
          scroll-snap-align: center;
          margin: 0 5px;

          width: 100%;
          height: 100%;
          pointer-events: none;

          .gallery-image-holder {
            display: flex;
            flex: 0 0 100%;
            width: 100%;
            height: 100%;
            justify-content: center;
            align-items: center;

            .gallery-image {
              border-radius: 3px;
              display: block;
              width: 100%;
              max-width: 1200px;
              object-fit: cover;
              opacity: 0;
              transition: opacity 30ms ease 120ms;
            }
          }
        }
      }
    }

    &--active {
      opacity: 1;
      pointer-events: all;

      .full-screen-gallery {
        &__gallery .gallery-item {
          &[data-active="true"] {
            pointer-events: all;
          }
        }
        &__gallery .gallery-item .gallery-image-holder .gallery-image {
          opacity: 1;
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .suite {
    // Utils
    &__full-screen-gallery {
      transition: none 200ms ease;

      .full-screen-gallery {
        overflow-x: hidden;

        &__nav-buttons {
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          pointer-events: none;

          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 1;

          .nav-buttons-holder {
            pointer-events: none;
            position: relative;
            height: 50px;
            width: 100%;
            max-width: 1400px;
            display: flex;
            align-items: center;
          }

          .nav-button {
            position: absolute;
            pointer-events: all;
            border: none;
            background: none;
            border-radius: 50px;
            box-shadow: 0 0 0 1px rgba(white, 0.3);
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: box-shadow 50ms ease, transform 50ms ease;

            &:focus {
              outline: none;
            }
            &:hover {
              box-shadow: 0 0 0 1px rgba(white, 0.3),
                0 3px 6px 3px rgba(white, 0.2);
              background: rgba(white, 0.2);
            }
            &:active {
              transform: scale(0.95);
              box-shadow: 0 0 0 1px rgba(white, 0.3),
                0 1px 2px 1px rgba(white, 0.25);
              background: rgba(white, 0.2);
            }
            &:focus-visible {
              box-shadow: 0 0 0 3px rgba(white, 1), 0 0 0 1px rgba(white, 0.3);
            }

            &__left {
              left: 25px;
            }
            &__right {
              right: 25px;
              padding-right: 4px;
            }
          }
        }

        &__gallery {
          overflow-x: visible;
          scroll-snap-type: unset;

          .gallery-item {
            scroll-snap-align: unset;
            opacity: 0;
            position: absolute;
            will-change: opacity;
            transition: opacity 300ms ease;
            padding: 0 100px;

            &[data-active="true"] {
              opacity: 1;
            }
            .gallery-image-holder {
              .gallery-image {
                object-fit: contain;
                max-height: 70vh;
              }
            }
          }
        }
      }
    }

    &__scroll-gallery {
      .gallery {
        .gallery-item {
          .gallery-image {
            box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.04),
              0 4px 8px 4px rgba(0, 0, 0, 0.03);
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .suite {
  }
}

@include media-breakpoint-up(xl) {
  .suite {
  }
}
