// Define breakpoints
$breakpoints: (
  md: 768px,
  lg: 992px,
  xl: 1200px,
);

/**
 * Media query mixin that supports both min-width and max-width breakpoints.
 *
 * @param {String} $breakpoint - The breakpoint key (e.g., md, lg, xl).
 * @param {String} $type - The type of media query, either min or max.
 *
 * @example
 *   @include media-query(md, max) {
 *     // Styles for max-width: 768px
 *   }
 *   @include media-query(lg, min) {
 *     // Styles for min-width: 992px
 *   }
 */
@mixin media-query($breakpoint, $type: max) {
  $value: map-get($breakpoints, $breakpoint);

  @if $value {
    @if $type == max {
      @media (max-width: $value) {
        @content;
      }
    } @else if $type == min {
      @media (min-width: $value) {
        @content;
      }
    } @else {
      @warn "Unknown type `#{$type}` for media query. Use `min` or `max`.";
    }
  } @else {
    @warn "Unknown breakpoint `#{$breakpoint}`.";
  }
}
