.homepage__header {
  .header-carousel {
    position: relative;
    z-index: 1;
    overflow: hidden;
    user-select: none;

    // Title
    &__title {
      position: absolute;
      z-index: 12;
      display: flex;
      width: 100%;
      height: 100%;
      flex-direction: column;
      align-content: center;
      text-align: center;
      justify-content: center;
      user-select: text;

      .title {
        &__main,
        &__sub {
          color: white;
          padding: 0 20px;
        }

        &__main {
          font-size: 36px;
          line-height: 96.5%;
          font-weight: bold;
          text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3),
            0px 40px 80px rgba(0, 0, 0, 0.3);
        }

        &__sub {
          font-style: normal;
          font-weight: 600;
          font-size: 15px;
          line-height: 20px;

          text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
        }
      }
    }

    // Gradients
    &__gradients {
      position: absolute;
      width: 100%;
      height: 100%;
      user-select: none;
      pointer-events: none;
      z-index: 10;
      overflow: hidden;

      .gradient {
        &__text,
        &__vignette,
        &__logo {
          position: absolute;
          left: 0;
          top: 0;
          display: block;
          width: 100%;
          height: 100%;
        }

        &__text {
          background: radial-gradient(
            63.67% 62.72% at 50.05% 63.56%,
            rgba(0, 0, 0, 0.08) 0%,
            rgba(0, 0, 0, 0) 100%
          );
          mix-blend-mode: multiply;
        }

        mix-blend-mode: multiply;
      }
    }

    // Carousel images
    &__images {
      display: -webkit-box;
      overflow-x: hidden;
    }
    &__image-wrapper {
      display: block;
      position: relative;
      width: 100vw;
      transition: opacity 400ms ease;
    }
    &__image {
      display: block;
      width: 100%;
      object-fit: cover;
      height: 30vh;
      min-height: 350px;
    }

    &__listing-name {
      display: none;
    }

    // Dots
    &__dots {
      display: none;
    }
  }
}

@media (min-width: 375px) {
  .homepage__header {
    .header-carousel {
      &__title {
        .title {
          &__main {
            font-size: 42px;
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .homepage__header {
    .header-carousel {
      border-radius: 0;
      height: 88vh;
      min-height: 600px;

      // Title
      &__title {
        margin-top: -70px;
        .title {
          &__main {
            font-size: 93px;
            line-height: 96.5%;
          }

          &__sub {
            font-size: 16px;
            line-height: 20px;
          }
        }
      }

      // Gradient
      &__gradients {
        .gradient {
          &__logo {
            width: 180%;
            height: 180%;
            left: 50%;
            top: 50%;
            transform: translate3d(-50%, -50%, 0) rotate(25deg);
            background: linear-gradient(
              90deg,
              rgba(47, 51, 62, 0.5) 0%,
              rgba(24, 51, 59, 0) 37%,
              rgba(30, 64, 74, 0) 44%,
              rgba(47, 51, 62, 0.5) 100%
            );
            mix-blend-mode: multiply;
          }

          &__vignette {
            background: radial-gradient(
              44.66% 410.44% at 50% 50%,
              rgba(0, 0, 0, 0) 66.15%,
              rgba(0, 0, 0, 0.08) 100%
            );
          }
        }
      }

      // Carousel images
      &__image,
      &__images {
        height: 88vh;
        min-height: 600px;
        object-fit: cover;
      }

      // Dots

      &__name {
        font-size: 14px;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        color: white;
        font-family: $primary-font;
        font-weight: bold;

        .icon {
          margin-right: 10px;
        }
      }

      &__dots {
        position: absolute;
        bottom: 70px;
        width: 100%;
        z-index: 15;
        display: flex;
        justify-content: center;

        &::after {
          position: absolute;
          width: 600px;
          content: "";
          margin: -18px auto 0;
          z-index: -1;
          display: block;
          height: 50px;
          border-radius: 12px;
          -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px);
          opacity: 0.7;
          -webkit-mask: radial-gradient(
            circle,
            rgba(0, 0, 0, 0.9) 140px,
            transparent 300px
          );
          mask: radial-gradient(
            circle,
            rgba(0, 0, 0, 0.9) 140px,
            transparent 300px
          );
        }

        .dot {
          display: block;
          border: 0px solid white;
          margin: 3px;
          width: 9px;
          height: 9px;
          padding: 0px;
          border-radius: 18px;
          background-color: rgba(white, 0.5);
          transition: transform 100ms ease, background-color 100ms ease;

          &:focus {
            outline: none;
          }
          &:focus-visible {
            box-shadow: 0 0 0 1px rgba(white, 0), 0 0 0 2px white;
          }
          &:active {
            transform: scale(0.7);
          }

          &[data-highlight="true"] {
            background-color: white;
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .homepage__header {
    .header-carousel {
      // Title
      &__title {
        .title {
          &__main {
            font-size: 86px;
            line-height: 96.5%;
          }
        }
      }

      &__listing-name {
        display: block;
        position: absolute;
        width: 100%;
        margin: 0 auto;
        text-align: center;
        z-index: 16;
        bottom: 90px;
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .homepage__header {
    .header-carousel {
      // Title
      &__title {
        .title {
          &__main {
            font-size: 95px;
            line-height: 96.5%;
          }

          &__sub {
            font-size: 18px;
            line-height: 20px;
          }
        }
      }
    }
  }
}
