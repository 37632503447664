.masonry-grid {
  display: flex;
  // margin-left: -15px; /* gutter size offset */
  // padding-right: 15px;
  width: 100%;
}
.masonry-column {
  // padding-left: 15px; /* gutter size */
  background-clip: padding-box;

  // customize here
  & > div {
    margin-bottom: 15px;
  }
}
