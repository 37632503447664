@import "./../../../../../fonts";
@import "./../../../../../variables";
@import "./../../../../../imports";

.question {
  background-color: $white;
  border: 1px solid rgba($primary, 0.25);
  border-radius: 16px;
  margin: 16px 0;
  height: calc(100% - 16px);
  display: flex;
  .section {
    height: 100%;

    position: relative;
    // padding: 87px 16px;
    padding: 64px 16px;
    .title {
      h3 {
        font-size: 17px;
      }
      .icon {
        filter: $icon-to-primary;
      }
    }

    .content {
      font-size: 14px;
      padding-left: 32px;

      .background {
        position: absolute;
        right: 0;
        bottom: 0;
        filter: $icon-to-primary;
        opacity: 0.1;
        display: block;
        pointer-events: none;
      }
    }
  }

  .buttons {
    margin-top: 20px;
    display: flex;
    align-items: center;
    .button {
      margin: 8px 0;
      a {
        display: flex;
        flex-direction: row;
        gap: 4px;
        .icon {
          filter: $icon-to-primary;
        }
      }
    }
  }

  .question__section {
    padding: 0;
  }
}

html[dir="rtl"] {
  .question {
    .section {
      .content {
        padding-left: 0;
        padding-right: 32px;
        .background {
          left: 0;
          right: unset;
        }
      }
    }
  }
}
