/* edit-profile */

.edit-profile {
  .update-profile-loading {
    background: $loading-bg;
    height: 430px;
    border-radius: $border-radius * 2;
  }
  .form-title {
    font-family: $primary-font;
    font-size: 16px;
    color: $dark-text;
    font-weight: 600;
    margin: 10px 0 5px 0;
  }

  .info {
    user-select: none;
    font-family: $primary-font;
    font-weight: 600;
    margin: 0;
    margin-top: -7px;
    margin-bottom: 20px;
    font-size: 13px;
    color: #676872;
    a {
      font-weight: 500;
      text-decoration: underline;
    }
  }

  .birthday-error {
    font-size: 13px;
    margin-bottom: 25px;
    color: #c65760;
    font-weight: 600;
    font-family: $primary-font;
    margin-left: 0;
  }

  .info-box {
    margin-top: 30px;
    max-width: calc(100% - 30px);
    margin-left: 15px;
    padding: 20px 25px 25px 20px;
    height: max-content;
    background: $white;
    border-radius: $border-radius * 2;
    box-shadow: 0 6px 12px 6px rgba($primary-darkest, 0.08);

    .icon {
      width: 22px;
      height: 22px;
      color: $primary-darkest;
    }

    .title {
      font-family: $primary-font;
      font-size: 20px;
      font-weight: 600;
      margin-top: 10px;
      padding-bottom: 10px;
      display: flex;
      align-items: flex-start;

      .icon {
        margin-right: 10px;
      }
    }

    .description {
      font-family: $primary-font;
      font-weight: 500;
      color: $dark-text;
      font-size: 14px;
      padding-left: 45px;
    }
  }
}

@media (min-width: 768px) {
  .edit-profile {
    .title {
      margin-top: 15px;
    }
  }
}

@media (min-width: 992px) {
  .edit-profile {
    .info-box {
      margin-top: 0;
      max-width: 100%;
    }
  }
}

@media (min-width: 1200px) {
  .edit-profile {
  }
}
