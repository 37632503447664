.swipeable-card {
  &__background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: black;
    z-index: 9;
  }
  &__content {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    border-radius: 20px 20px 0 0;
    box-shadow: 0 0 0 1px rgba($primary, 0.25),
      0 -2px 4px 2px rgba($primary, 0.06);
    background: white;
    display: block;
    z-index: 10;
  }
  &__children {
    display: block;
    position: relative;
    height: 100%;
  }

  &__top {
    position: relative;
    margin-bottom: 10px;
    padding: 0 20px;

    .top {
      &__handle {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 16px;

        &__line {
          width: 35px;
          height: 5px;
          border-radius: 10px;
          background: rgba(black, 0.3);
        }
      }
      &__close-button {
        border: 0;
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background: none;

        &--text {
          margin-top: -5px;
          font-size: 16px;
          font-weight: 500;
          color: $primary-dark;
        }
        &--icon {
          width: 40px;
          height: 40px;
          margin-top: -10px;
          margin-left: -10px;
        }

        .icon {
          filter: $icon-to-primary;
        }

        &:focus {
          outline: none;
        }
      }
    }
  }
}
