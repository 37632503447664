.suite__house-rules {
  .house-rules-item {
    margin-bottom: 35px;

    &__title-holder {
      display: flex;
    }

    &__icon {
      margin-right: 12px;
      .icon {
        filter: $icon-to-primary;
      }
    }

    &__title {
      font-family: $primary-font;
      font-size: 18px;
      font-weight: 500;
      color: $body-text-color;
      white-space: pre-line;
    }

    &__text {
      margin-top: 5px;
      font-size: 14px;
      line-height: 160%;
      white-space: pre-line;
    }
  }
}

@include media-breakpoint-up(md) {
  .suite__house-rules {
    .house-rules {
      &__content {
        padding-right: 0;
        width: calc(100% + 50px);
      }
    }

    .masonry-grid {
      display: flex;
      margin-left: -50px; /* gutter size offset */
      width: 100%;
    }
    .masonry-column {
      padding-left: 50px; /* gutter size */
      background-clip: padding-box;
    }

    .house-rules-item {
      margin-bottom: 45px;

      &__title-holder {
        position: relative;
        display: flex;
      }

      &__icon {
        position: absolute;
        margin-right: 12px;
        transform: translateX(-40px);
        .icon {
          filter: $icon-to-primary;
        }
      }

      &__title {
        font-family: $primary-font;
        font-size: 18px;
        font-weight: 500;
        color: $body-text-color;
      }

      &__text {
        margin-top: 5px;
        font-size: 14px;
        line-height: 160%;
        white-space: pre-line;
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .suite {
    &__house-rules {
      .house-rules {
        &__content {
          width: calc(100% + 80px);
        }
      }

      .masonry-grid {
        display: flex;
        margin-left: -80px; /* gutter size offset */
        width: 100%;
      }
      .masonry-column {
        padding-left: 80px; /* gutter size */
        background-clip: padding-box;
      }
    }
  }
}
