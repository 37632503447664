$image-shadow: 0 3px 12px 6px rgba(0, 0, 0, 0.08);
$marker-transition: all 160ms cubic-bezier(0.645, 0.045, 0.355, 1);

.search-map-markers {
  $listing-size: 60px;
  $cluster-size: 55px;

  &__listing-marker {
    cursor: pointer;
    user-select: none;

    .image {
      margin-top: calc(-#{$listing-size} / 2);
      margin-left: calc(-#{$listing-size} / 2);
      height: $listing-size;
      width: $listing-size;
      border-radius: $listing-size;
      object-fit: cover;
      box-shadow: 0 0 0 3px white, $image-shadow;
      transition: $marker-transition;
    }

    &.selected {
      z-index: 2;
      .image {
        box-shadow: 0 0 0 6px white, $image-shadow;
        transform: scale(1.2);
      }
    }

    .listing-label {
      display: none;
    }
  }

  &__cluster-marker {
    $cluster-size: 55px;

    user-select: none;
    cursor: pointer;
    background: white;
    margin-top: calc(-#{$cluster-size} / 2);
    margin-left: calc(-#{$cluster-size} / 2);
    height: $cluster-size;
    width: $cluster-size;
    border-radius: $cluster-size;
    z-index: 1;
    box-shadow: 0 3px 12px 6px rgba($primary-darkest, 0.08);

    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 3px;

    font-weight: bold;
    font-family: $primary-font;
    font-size: 14px;

    transition: $marker-transition;

    &.active {
      z-index: 2;
      transform: scale(1.2);
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0 0 0 6px #f6f6f6,
        0 3px 20px 10px rgba($primary-darkest, 0.2);
    }

    .cluster-label {
      display: none;
    }
  }

  $listing-content-height: auto;
  $slider-height: 180px;
  $listing-card-total-height: calc(
    #{$slider-height} + #{$listing-content-height}
  );

  &__floating-item {
    .listings-col {
      width: 100%;
      flex: 1;
      max-width: 100%;
      margin: 0;
      padding: 0;
      padding: 0 5px;
    }

    .search_page__listing-card {
      width: 100%;

      .box-shadow {
        box-shadow: 0 12px 24px 12px rgba(0, 0, 0, 0.07);
      }

      .listing {
        &__content {
          min-height: $listing-content-height;
        }

        &__bottom {
          display: none;
        }
      }
    }
    .search_page__slider {
      .slick {
        &__slider,
        &__image {
          height: $slider-height;
        }

        &__image img {
          height: $slider-height;
        }
      }
    }
    .search_page__slider-placeholder {
      height: $slider-height;
    }
  }

  &__floating-listing,
  &__floating-cluster {
    display: none;
    position: absolute;
    z-index: 2;
    left: 0;
    pointer-events: all;
  }

  &__floating-listing {
    $card-width: 250px;

    transform: translate3d(
      calc(#{$card-width} / 2 * -1),
      calc(-100% - 52px),
      0
    );
    width: $card-width;

    &.up {
      transform: translate3d(calc(#{$card-width} / 2 * -1), calc(32px), 0);
    }
  }

  &__floating-cluster {
    $card-width: 320px;

    transform: translate3d(
      calc(#{$card-width} / 2 * -1),
      calc(-100% - 52px),
      0
    );
    background-color: #ffffff;
    width: $card-width;
    border-radius: 24px;
    overflow: hidden;

    box-shadow: 0 12px 24px 12px rgba(0, 0, 0, 0.07);

    &.up {
      transform: translate3d(calc(#{$card-width} / 2 * -1), calc(52px), 0);
    }

    .cluster-title {
      display: block;
      font-family: $primary-font;
      font-size: 14px;
      font-weight: 600;
      color: black;
      padding-left: 15px;
      margin-top: 20px;
      user-select: none;

      width: 100%;
      padding-bottom: 15px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    }

    .floating-cluster-slider {
      background-color: rgba(0, 0, 0, 0.05);
      &__button {
        position: absolute;
        right: 7px;
        top: -47px;
        padding: 0;
        margin: 0;
        background-color: white;
        width: 40px;
        height: 40px;
        border: none;
        border-radius: 50px;

        &:focus {
          outline: none;
        }

        &.button-prev {
          padding-right: 2px;
          right: 50px;
        }
        &.button-next {
          padding-left: 1px;
        }

        &:focus-visible {
          box-shadow: 0 0 0 3px white inset, 0 0 0 2px $primary;
        }

        &:hover {
          background-color: white;
          box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.09);
        }

        &:active {
          transform: scale(1.02);
        }

        .icon {
          width: 16px;
          height: 16px;
        }
      }
    }

    .search_page__listing-card {
      width: 100%;
      margin-bottom: 15px;

      .box-shadow {
        box-shadow: 0 3px 6px 3px rgba(0, 0, 0, 0.05);
      }
    }
  }
}

@media (min-width: 768px) {
  .search-map-markers {
    &__floating-listing,
    &__floating-cluster {
      display: block;
    }
  }
}

@media (min-width: 1200px) {
  .search-map-markers {
    $box-shadow: 0 3px 12px 6px rgba($primary-darkest, 0.08);
    $box-shadow-hard: 0 3px 12px 6px rgba($primary-darkest, 0.14);

    &__listing-marker,
    &__cluster-marker {
      user-select: none;
      cursor: pointer;
      background: white;
      margin-top: unset;
      margin-left: unset;
      transform: translate3d(-50%, -50%, 0);
      width: max-content;
      height: 35px;
      padding: 7px 15px 5px 15px;
      border-radius: 20px;
      z-index: 1;
      box-shadow: $box-shadow;

      display: flex;
      justify-content: center;
      align-items: center;

      font-weight: bold;
      font-family: $primary-font;
      font-size: 13.5px;

      .image {
        display: none;
      }

      &.selected,
      &.active {
        z-index: 5;
        background-color: $primary-darkest;
        color: white;
        transform: translate3d(-50%, -50%, 0);
        box-shadow: $box-shadow-hard;
      }
      &.hovered {
        z-index: 4;
        box-shadow: 0 0 0 3px $primary-darkest;
      }

      .listing-label,
      .cluster-label {
        display: block;
      }

      .cluster-label {
        margin-left: 4px;
      }
    }
    &__cluster-marker {
      z-index: 3;
    }

    &__floating-listing,
    &__floating-cluster {
      z-index: 6;
    }

    &__floating-listing {
      $card-width: 250px;

      transform: translate3d(
        calc(#{$card-width} / 2 * -1),
        calc(-100% - 27px),
        0
      );
      width: $card-width;

      &.up {
        transform: translate3d(calc(#{$card-width} / 2 * -1), calc(10px), 0);
      }
    }

    &__floating-cluster {
      $card-width: 320px;

      transform: translate3d(
        calc(#{$card-width} / 2 * -1),
        calc(-100% - 27px),
        0
      );
      width: $card-width;

      &.up {
        transform: translate3d(calc(#{$card-width} / 2 * -1), calc(25px), 0);
      }
    }
  }
}
