.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
  padding-right: $container-padding;
  padding-left: $container-padding;
}

.container-light-primary-bg {
  background: #f6f8f9;
}

body,
html {
  min-width: 320px;
}

@include media-breakpoint-up(sm) {
  .container-max-sm {
    max-width: map-get($container-max-widths, "sm");
  }
}
@include media-breakpoint-up(md) {
  .container-max-md {
    max-width: map-get($container-max-widths, "md");
  }
}
@include media-breakpoint-up(lg) {
  .container-max-lg {
    max-width: map-get($container-max-widths, "lg");
  }
}
@include media-breakpoint-up(xl) {
  .container-max-xl {
    max-width: map-get($container-max-widths, "xl");
  }
}
@include media-breakpoint-up(xxl) {
  .container-max-xxl {
    max-width: map-get($container-max-widths, "xxl");
  }
}
