.suite {
  &__our-place-offers {
    .our-place-offers {
      &__content {
        .content {
          &__title {
            font-family: $primary-font;
            font-size: 18px;
            font-weight: 500;
            color: black;
          }
          &__subtitle {
            font-size: 14px;
            color: rgba(black, 0.6);
          }

          &__items {
            margin-top: 25px;
            display: block;
            .unit-offers-item {
              display: inline-block;
              width: 50%;
              margin-bottom: 15px;

              &__content {
                display: flex;
                align-items: center;
                padding-right: 10px;
              }

              &__icon {
                margin-right: 12px;
              }
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .suite {
    &__our-place-offers {
      .our-place-offers {
        &__content {
          padding-right: 0;
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .suite {
  }
}

@include media-breakpoint-up(xl) {
  .suite {
    &__our-place-offers {
      .our-place-offers {
        &__content {
          padding-right: 15px;
          .content {
            &__items {
              margin-top: 25px;
              .unit-offers-item {
                width: 33%;

                &__content {
                  padding-right: 30px;
                }

                &__icon {
                  margin-right: 15px;
                }
              }
            }
          }
        }
      }
    }
  }
}
