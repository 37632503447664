.notfound {
  &,
  &__card {
    display: flex;
    justify-content: center;
  }
  &__card {
    flex-direction: column;
    align-items: center;
    margin-top: 100px;
    width: 500px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
    padding: 47px;
  }
  &__message {
    font-size: 18px;
  }
}
