.faqs {
  margin-top: 0.6rem;
  margin-bottom: 2rem;
  overflow: hidden;
  user-select: none;

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 0;
    font-size: 13px;
    padding: $container-padding;
    font-weight: 500;
    border: none;
    background: none;
    width: 100%;
    justify-content: space-between;
    text-align: left;

    &:focus {
      outline: none;
    }

    &:focus-visible {
      box-shadow: 0 0 0 2px $primary;
    }
  }

  &__list {
    margin: 0;
    margin-block-start: 0px;
    padding-inline-start: 0px;
    padding: 0;
    padding-left: calc(38px - #{$container-padding});
  }

  &__item {
    border-radius: 12px;
    margin-bottom: 2px;

    &.active {
      background: rgba($primary, 0.1);
    }
    &:not(.active):hover {
      background: rgba($primary, 0.1);
    }
  }

  &__icon {
    margin-left: 10px;

    .active {
      transform: rotate(180deg);
    }
  }

  &__content {
    height: 0;
    opacity: 0;
    visibility: hidden;

    p {
      font-size: 13px;
      line-height: 21px;
      font-family: $primary-font;
      font-weight: 500;
      color: black;
      padding: $container-padding;
      padding-bottom: 25px;
      padding-top: 0;
      margin: 0;
      user-select: text;
    }

    &.active {
      height: auto;
      opacity: 1;
      visibility: visible;
    }
  }
}

.freq {
  svg {
    width: 28px !important;
    height: 28px !important;
  }
}
