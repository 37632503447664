.suite {
  &__question-box {
    margin: 0px calc(#{$container-padding} / 2 * -1);
    margin-top: 40px;
    padding: calc(#{$container-padding} * 0.5);
    border-radius: 12px;
    background-color: rgba($primary, 0.05);
    box-shadow: 0 0 0 1px rgba($primary, 0.3),
      0 3px 6px 3px rgba($primary, 0.16);
    padding-top: 30px;

    &__content {
      font-size: 14px;
    }
    .short-text {
      padding-left: 32px;
    }
    .background {
      display: none;
    }
    .buttons {
      padding-left: 32px;
      margin-top: 20px;
      display: flex;
      align-items: center;

      &__contact {
        width: 200px;
      }
      &__or {
        opacity: 0.4;
        margin: 0 20px;
      }
      &__whatsapp,
      &__email {
        span {
          display: none;
        }
      }
      &__email {
        margin-left: 20px;
      }
    }

    .suite__section {
      padding: 0;
    }
  }
}

@include media-breakpoint-up(md) {
  .suite {
    &__question-box {
      position: relative;

      margin: 0px calc(#{$container-padding} / 2 * -1);
      margin-top: 40px;

      padding: 30px calc(#{$container-padding} * 0.5) 30px
        calc(#{$container-padding} * 0.5);

      border-radius: unset;
      background-color: unset;
      box-shadow: unset;

      .question-box {
        &__content {
          padding-right: 0;
        }
      }

      .short-text {
        padding-left: 0;
        width: 70%;
      }

      &::after {
        pointer-events: none;
        position: absolute;
        content: "";
        display: block;
        width: calc(100% + 50px);
        height: 100%;
        left: -50px;
        top: 0;

        border-radius: 20px;
        box-shadow: 0 0 0 1px rgba($primary, 0.25),
          0 2px 4px 2px rgba($primary, 0.06);
      }

      .buttons {
        padding-left: 0;

        &__contact {
          width: 200px;
        }
        &__or {
          opacity: 0.4;
          margin: 0 20px;
        }
        &__whatsapp,
        &__email {
          text-decoration: none;
          .icon {
            filter: $icon-to-primary;
          }
          span {
            display: unset;
            margin-left: 5px;
            color: black;
            text-decoration: none;
          }
        }
        &__email {
          margin-left: 20px;
        }
      }

      .suite__section {
        padding: 0;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .suite {
    &__question-box {
      .background {
        position: absolute;
        right: 30px;
        top: 30px;
        filter: $icon-to-primary;
        opacity: 0.1;
        display: block;
      }
    }
  }
}
