.homepage__gallery .gallery {
  margin-top: 25px;

  $fadeInAmount: 15%;

  @keyframes fadeInUp {
    from {
      opacity: 0;
      -webkit-transform: translate3d(0, $fadeInAmount, 0);
      transform: translate3d(0, $fadeInAmount, 0);
    }
    to {
      opacity: 1;
      -webkit-transform: translate3d(0, 0%, 0);
      transform: translate3d(0, 0%, 0);
    }
  }

  .gallery-item {
    position: relative;
    z-index: 1;
    transform: translate3d(0, 0%, 0);

    &[data-in-view="true"] {
      opacity: 1;
      animation-duration: 600ms;
      animation-delay: 0;
      animation-name: fadeInUp;
    }

    &__image-holder {
      position: relative;
      z-index: -1;

      .image {
        width: 100%;
        height: 300px;
        object-fit: cover;
        border-radius: 8px;
      }
    }

    &__text {
      position: relative;
      z-index: 1;
      margin: -60px 20px 30px -10px;

      background: rgba(white, 0.75);
      border-radius: 12px;
      padding: 20px;
      backdrop-filter: saturate(180%) blur(25px);
      box-shadow: 0 0 0 1px white inset;

      .headline {
        display: flex;
        align-content: center;

        .text {
          font-size: 20px;
          margin-left: 10px;
        }
      }
    }

    &.item-2 .gallery-item__text,
    &.item-4 .gallery-item__text {
      margin: -60px -10px 30px 20px;

      .headline {
        .text {
          margin-right: 10px;
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .homepage__gallery .gallery {
    margin-top: 75px;
    margin-bottom: 40px;
    position: relative;

    &__row {
      display: flex;
    }
    .gallery-item {
      width: 100%;
      margin-right: 8px;

      &.item-1 {
        width: 80%;
      }
      &.item-2 {
        margin-top: 30px;
      }
      &.item-3 {
        margin-top: -30px;
      }

      &.item-4 {
        margin-top: 15px;
        width: 70%;
      }

      &.item-2,
      &.item-4 {
        margin-left: 8px;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .homepage__gallery .gallery {
    margin-bottom: 90px;

    .gallery-item {
      margin-right: 10px;
      &__text {
        margin: -60px 20px 30px 20px;
        padding: 20px 30px;

        .headline {
          .text {
            line-height: 28px;
          }
        }
      }

      &.item-1 {
        width: 80%;
        width: 50%;
      }
      &.item-2 {
        margin-top: 30px;
        .gallery-item__text {
          width: 500px;
        }
      }
      &.item-3 {
        margin-top: 0px;
      }

      &.item-4 {
        margin-top: 40px;
        width: 70%;
      }

      &.item-2 .gallery-item__text,
      &.item-4 .gallery-item__text {
        margin: -60px 20px 30px 20px;
      }

      &.item-2,
      &.item-4 {
        margin-left: 10px;
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .homepage__gallery .gallery {
    margin-top: 125px;

    .gallery-item {
      margin-right: 15px;

      &__image-holder {
        .image {
          height: 420px;
        }
      }

      &.item-1 {
        width: 80%;
      }
      &.item-2 {
        margin-top: 120px;

        .gallery-item__image-holder {
          .image {
            height: 340px;
          }
        }
      }
      &.item-3 {
        margin-top: 0px;

        .gallery-item__image-holder {
          .image {
            height: 340px;
          }
        }
      }

      &.item-4 {
        margin-top: 40px;
        width: 80%;
      }

      &.item-2,
      &.item-4 {
        margin-left: 15px;
      }
    }
  }
}
