.homepage {
  &__header-area {
    position: relative;
    z-index: 1;
  }

  .homepage-section-title {
    font-size: 28px;
    line-height: 28px;
    font-weight: 600;
    color: black;
  }

  .homepage-section-subtitle {
    font-size: 14px;
    color: #222222;
    max-width: 500px;
    font-family: $text-font;
  }
}

@include media-breakpoint-up(md) {
  .homepage {
    .homepage-section-title {
      font-size: 32px;
      line-height: 32px;
    }

    .homepage-section-subtitle {
      font-size: 15px;
    }
  }
}

@include media-breakpoint-up(lg) {
  .homepage {
    .homepage-section-title {
      font-size: 36;
      line-height: 36px;
    }
  }
}
