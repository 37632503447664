.help-center {
  padding-top: 30px;
  margin-top: 0;

  &__heading {
    margin-bottom: 15px;
    font-size: 35px;
    font-weight: 600;
  }

  &__search {
    position: relative;
    width: 100%;
    margin-bottom: 30px;

    .form-control {
      height: unset;
    }
    .search-group {
      position: relative;
      height: 40px;

      width: 100%;
      max-width: 600px;
    }
    .search-input {
      width: 100%;
      height: 100%;
      box-shadow: 0 0 0 1px rgba($primary-darkest, 0.1);
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
        Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
      font-size: 16px;
      padding-left: 35px;
      padding-right: 35px;
      border-radius: 50px;

      &:focus {
        box-shadow: 0 0 0 1px $primary;

        &::placeholder {
          opacity: 0;
        }
      }
    }
    .search-icon {
      position: absolute;
      left: 12px;
      top: 52%;
      transform: translateY(-50%);
      opacity: 0.6;
    }
  }

  &__search-results {
    .search-results {
      display: flex;
      justify-content: center;
      &__container {
        margin-bottom: 50px;
        max-width: 600px;
      }
    }

    .search-result {
      margin-bottom: 40px;
      mark {
        background-color: yellow;
        color: black;
        margin: 0;
        padding: 0;
      }
      p {
        margin: 0;
        padding: 0;
      }
      &__question {
        font-family: $primary-font;
        font-size: 18px;
        font-weight: 600;
        color: black;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 5px;
      }

      &__answer {
        border-top: 1px solid $light-border;
        display: block;
        white-space: pre-line;
        font-family: $text-font;
        font-size: 14px;
        line-height: 170%;
        letter-spacing: -0.1px;
        padding: 7px 15px 10px 0;
      }
    }

    .no-result {
      padding-right: 30px;
      &__title {
        font-size: 20px;
      }

      &__contact {
        font-size: 14px;
        a {
          color: $primary;
        }
      }
    }
  }

  .sections {
    transition: all 400ms cubic-bezier(0.22, 1, 0.36, 1);

    &__hide {
      position: fixed;
      transform: translateY(20px);
      opacity: 0;
      pointer-events: none;
      transition: all 200ms cubic-bezier(0.22, 1, 0.36, 1);
    }

    &__loading {
      .section {
        &__content .questions {
          display: block;
          overflow: hidden;
          height: 500px;
          border-radius: 12px;
        }
      }
    }
  }

  .section {
    margin-bottom: 50px;

    .questions {
      .question {
        border: none;
        background: none;
        padding: 0;
        margin: 0;
        position: relative;
        margin: 4px 0;
        width: 100%;
        text-align: left;
        touch-action: manipulation;

        &:focus {
          outline: none;
        }
        &:focus-visible {
          .question__question {
            text-decoration: underline;
            color: black;
          }
        }

        &.selected {
          .question__question {
            font-weight: 600;
            color: black;

            .icon {
              color: $primary;
              opacity: 1;
            }
          }
        }

        &:not(.question.selected):hover {
          .question__question {
            color: rgba(#000, 0.8);
          }
        }

        &__question {
          font-family: $primary-font;
          font-size: 15px;
          font-weight: 500;
          color: rgba(#000, 0.5);
          padding: 12px 0 6px 0;
          display: flex;
          min-height: 55px;
          justify-content: space-between;
          align-items: center;

          .icon {
            margin-left: 10px;
            width: 20px;
            opacity: 0.3;
            height: 20px;
          }
        }

        &__answer-holder {
          display: block;
          position: relative;
          height: 0;
          overflow-y: hidden;
          transition: height 300ms cubic-bezier(0.33, 1, 0.68, 1);
        }

        &__answer {
          border-top: 1px solid $light-border;
          position: absolute;
          display: block;
          height: max-height;
          white-space: pre-line;
          font-family: $text-font;
          font-size: 12.5px;
          line-height: 170%;
          letter-spacing: -0.1px;
          padding: 7px 15px 10px 0;
          cursor: default;
          color: $black;

          p {
            display: block;
            margin-bottom: 15px;
            cursor: text;
            user-select: text;
          }
        }
      }
    }
  }
}
@include media-breakpoint-up(lg) {
  .help-center {
    padding-top: 50px;

    &__heading {
      text-align: center;
    }

    &__search {
      display: flex;
      justify-content: center;
      padding-bottom: 30px;
      border-bottom: 1px solid rgba($primary-darkest, 0.1);
    }

    &__search-results {
      .search-results {
        &__container {
          width: 600px;
        }
      }

      .no-result {
        padding-right: 0;
        text-align: center;
        margin-top: 160px;
        &__title {
          font-size: 22px;
        }

        &__contact {
          font-size: 14px;
          a {
            color: $primary;
          }
        }
      }
    }
  }
}
