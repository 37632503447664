$input-area-border: rgb(0, 0, 0);

.homepage__input-area {
  position: relative;
  margin-top: 0;
  width: 100%;
  z-index: 15;

  .input-area {
    background: rgba(255, 255, 255, 0.92);
    border: 1px solid #ffffff;
    box-shadow: 0px 8px 16px 8px rgba(0, 0, 0, 0.03);
    backdrop-filter: saturate(340%) blur(24px);
    border-radius: 24px;
    padding: 12px;
  }

  .holder {
    margin-bottom: 10px;

    .search-filter-button {
      margin-left: -3px;

      &__label {
        margin-left: -1px;
      }

      &__children,
      &__button {
        border: none;
        border-radius: 12px;
        background-color: rgba(255, 255, 255, 0);
        transition: background-color 60ms ease;
      }
    }

    &:hover,
    &:active,
    &:focus {
      &::after {
        border-top: 3px solid rgba($primary-darkest, 0.8);
      }
    }

    &::after {
      position: absolute;
      display: block;
      content: "";
      width: calc(100% - 32px);
      margin-left: 8px;
      height: 1px;
      border-top: 1px solid rgba($input-area-border, 0.1);
      transition: border 50ms ease-out;
    }

    .select-filter {
      &__control {
        &--menu-is-open,
        &--is-focused {
          box-shadow: none;
        }
      }

      // Menu
      &__menu {
        background: rgba(255, 255, 255, 1);
        backdrop-filter: saturate(20%) blur(40px);
        box-shadow: 0 0 0 1px white, 0 6px 12px 6px rgba(0, 0, 0, 0.24);
        margin-left: -2px;
        margin-top: 6px;
      }

      &__menu-list {
        padding: 10px;
      }
      &__option {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 4px;
        cursor: pointer;

        &:last-of-type {
          margin-bottom: 0px;
        }
      }
    }

    &__guests {
      &::after {
        display: none;
      }
    }

    &__search {
      margin-bottom: 0;

      &::after {
        display: none;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .homepage__input-area {
    margin-top: 0;
    transform: translateY(-50%);
    margin-bottom: -40px;

    .input-area {
      display: flex;
      padding: 6px;
      padding-left: 12px;

      .holder {
        margin-bottom: 0;
        position: relative;
        width: 100%;
        $padding-extra: 4px;
        padding-right: 10px;

        .search-filter-button {
          margin-left: -3px;

          &__label {
            padding-top: 5px;
            font-size: 11px;
          }

          &__children,
          &__button {
            padding-top: calc(#{$padding-extra} - 3px);
            font-size: 17px;
            font-weight: 500;
          }
        }

        &__city {
          width: 70%;

          .select-filter {
            // Menu
            &__menu {
              border-radius: 20px;
              margin-top: 8px;
              background: rgba(255, 255, 255, 1);
              box-shadow: 0 0 0 1px rgba($primary-darkest, 0.08),
                0 6px 12px 6px rgba($primary-darkest, 0.08);
            }

            &__option {
              border-radius: 12px;
            }
          }
        }

        &__guests {
          width: 70%;
          .guests__holder {
            justify-content: flex-start;
          }

          .guests__button-label {
            width: 120px;
            padding-top: calc(4px + 16px);
          }
          .guests__buttons {
            margin-left: 10px;
          }
        }

        &__city,
        &__dates {
          border-bottom: 2px solid transparent;
          &:hover {
            border-bottom: 2px solid rgba($primary-darkest, 0.8);
          }
        }

        &__dates {
          .search-filter-button__button {
            max-width: 280px;
            padding-top: calc(#{$padding-extra} + 16px);
          }
          .custom_datepicker {
            margin-top: 20px;
            left: 50%;
            right: 0;
            transform: translateX(-50%);
          }
        }

        &__city,
        &__guests,
        &__dates {
          padding-top: 4px;
        }

        &__search {
          width: 70%;
          padding-right: 0;

          .search-button {
            height: 60px;
          }
        }

        &::after {
          width: 1px;
          height: 16px;
          top: 50%;
          right: 10px;
          transform: translateY(-50%);
          border-top: none;
          border-left: 1px solid rgba($input-area-border, 0.2);
        }
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .homepage__input-area {
    margin: 0 auto;
    max-width: 1000px;

    .input-area {
      padding: 8px 8px 8px 12px;

      .holder {
        &__city,
        &__guests,
        &__dates {
          padding-top: 3px;
        }

        &__search {
          .search-button {
            height: 60px;
          }
        }
      }
    }
  }
}
