@keyframes scaleAnim {
  0% {
    transform: scale(0.9);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.9);
  }
}

.Animation {
  animation: scaleAnim 1s ease-in-out infinite alternate;
  overflow: visible;
}
