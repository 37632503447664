/* Slider */

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;

  &:focus {
    outline: none;
  }

  &.dragging {
    cursor: pointer;
    cursor: hand;
  }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;

  &:before,
  &:after {
    content: "";
    display: table;
  }

  &:after {
    clear: both;
  }

  .slick-loading & {
    visibility: hidden;
  }
}
.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  [dir="rtl"] & {
    float: right;
  }
  img {
    display: block;
  }
  &.slick-loading img {
    display: none;
  }

  display: none;

  &.dragging img {
    pointer-events: none;
  }

  .slick-initialized & {
    display: block;
  }

  .slick-loading & {
    visibility: hidden;
  }

  .slick-vertical & {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }
}
.slick-arrow.slick-hidden {
  display: none;
}

.closer {
  display: none;
}

.gallery-holder {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 101vh;
  z-index: 24;
  background: rgba(0, 0, 0, 0.95);
  vertical-align: 100%;
  opacity: 0;
  margin-top: -1px;

  .carousel {
    position: absolute;
    height: 100vh !important;
    top: 0;
    left: 0;
    width: 100vw;
    border: none;
    border-radius: 0 !important;
    z-index: -1;
    opacity: 1;

    .nav-button {
      z-index: 20;
    }

    .closer {
      display: block;
      position: absolute;
      z-index: 9;
      height: 100vh;
      width: 100vw;
    }

    .slick-slider {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      margin-top: 0;
      height: 100vh;
      border: none;
      border-radius: 0 !important;

      .slick-list {
        z-index: 10;
        top: calc(50% - 20px);
        transform: translateY(-50%);

        .slick-track {
          position: relative;

          .slick-slide {
            position: relative;
            padding: 12px;
            height: 80vh;
            display: flex;
            align-items: center;

            .carousel-image-holder {
              max-height: 80vh;
              padding: 5px;

              .carousel-image {
                padding: 0;
                height: 100%;
                max-height: 80vh;
                width: 100%;
                object-fit: contain;
                border: none;
                border-radius: $border-radius none;
              }
            }
          }
        }
      }

      .slick-dots {
        position: fixed;
        height: 100vh;
        z-index: -1;
        top: auto;

        ul {
          position: absolute;
          bottom: 20px;
        }
      }
    }

    &.gallery {
      cursor: default;
    }
  }

  &.fade-in {
    opacity: 1;
    transition: opacity 0.2s ease;
  }

  &.fade-out {
    opacity: 0;
    transition: opacity 0.2s ease 0.1s;
  }
}

.gallery-placeholder {
  background: $body-bg;
}

.carousel {
  .close-icon {
    display: none;
    cursor: pointer;

    &.show {
      display: block;
      position: fixed;
      right: 0;
      top: 0;
      z-index: 20;
      margin-top: 20px;
      margin-right: 15px;
      color: white;

      .icon {
        width: 25px;
        height: 25px;
      }
    }
  }

  .slick-list {
    overflow: hidden;
    border-radius: $border-radius;
  }

  .slick-dots {
    position: absolute;
    bottom: 0;
    width: 100%;

    ul {
      position: relative;
      margin: 0;
      padding: 0;
      width: 100%;
      display: block;
      text-align: center;

      li {
        font-size: 0;
        opacity: 0.7;
        display: inline;

        button {
          min-height: auto !important;
          outline: none;
          border: none;
          border: 1px solid transparent;
          background: white;
          font-size: 0;
          padding: 2px;
          border-radius: 8px;
          margin: 10px 5px;
        }
      }
      li.slick-active {
        opacity: 1;

        button {
          box-shadow: 0 0 0 1px white;
        }
      }
    }
  }

  .carousel-image {
    width: 100%;
    opacity: 0;
    object-fit: cover;

    &.loaded {
      opacity: 1;
      transition: opacity 0.2s ease-in-out 0.2s;
    }
  }

  .nav-button {
    position: absolute;
    z-index: 5;
    display: block;
    top: 50%;
    transform: translateY(-50%);
    background: $light-bg;
    color: $primary;
    border-radius: 100%;
    cursor: pointer;
    box-shadow: 0px 3px 5px 2px rgba(0, 0, 0, 0.2);
    opacity: 0;
    transition: opacity 0.1s ease-out, padding 0.1s ease, margin-right 0.1s ease,
      margin-left 0.1s ease;

    &.next {
      right: 0;
      margin-right: 20px;
      padding: 8px 11px 8px 12px;

      &:active {
        padding: 7px 11px 6px 11px;
      }
    }

    &.prev {
      left: 0;
      margin-left: 20px;
      margin-right: 22px;
      padding: 8px 12px 8px 11px;

      &:active {
        margin-left: 22px;
        padding: 6px 10px 7px 9px;
        transition: padding 0.2s ease;
      }
    }
  }

  &:hover {
    .nav-button {
      opacity: 0.9;
    }
  }

  &.fade-out {
    opacity: 0;
    transition: opacity 100ms ease;
  }

  &.fade-in {
    opacity: 1;
    transition: opacity 100ms ease 100ms;
  }

  &.gallery {
    cursor: pointer;
  }
}

@media (min-width: 992px) {
  .gallery-placeholder {
    margin-bottom: 20px;
  }

  .gallery-holder {
    background: $body-bg;

    .carousel {
      background-color: $body-bg !important;

      .close-icon {
        color: $dark-text;
        padding: 12px 15px 10px 15px;
        border-radius: $border-radius * 2;
        background: $white;
        box-shadow: 0 4px 8px 4px rgba(0, 0, 0, 0.05);
        border: 1px solid transparent;
        font-weight: 600;
        font-family: $primary-font;
        left: 0;
        right: auto;
        margin-left: 50px;
        margin-top: 30px;

        .icon {
          width: 20px;
          height: 20px;
          margin-right: 6px;
          margin-top: -2px;
        }

        &:hover {
          border: 1px solid $border-color;
          box-shadow: 0 8px 16px 8px rgba(0, 0, 0, 0.03);
        }
      }

      .slide-pagination {
        font-size: 16px;
        font-family: $primary-font;
        margin-top: 5vh;
        font-weight: 600;
        color: $dark-text;
        width: 100%;
        text-align: center;
      }

      .closer {
        height: 1px;
        width: 1px;
      }

      .slick-slider {
        position: absolute;

        .slick-list {
          height: 90vh;
          top: calc(50%);
          transform: translateY(-50%);
          margin: 0 auto;

          .slick-track {
            .slick-slide {
              position: relative;
              padding: 12px;
              height: 90vh;
              display: flex;
              align-items: center;
              justify-content: center;

              .carousel-image-holder {
                max-height: 90vh;
                padding: 5px 140px;

                .carousel-image {
                  position: relative;
                  padding: 0;
                  height: 100%;
                  margin-top: 20px;
                  max-height: 77vh;
                  width: 100%;
                  object-fit: contain;
                  border: none;
                  border-radius: $border-radius none;
                }

                .image-text {
                  position: relative;
                  margin-top: 15px;
                  z-index: 100;

                  .image-description,
                  .image-title {
                    color: $dark-text;
                    text-align: center;
                    font-size: 14px;
                    margin: 0;
                    padding: 0;
                  }
                  .image-title {
                    font-family: $primary-font;
                    font-size: 16px;
                    font-weight: 600;
                  }
                }
              }
            }
          }
        }
      }

      .slick-dots {
        ul {
          li {
            opacity: 0.3;

            button {
              background: $dark-bg;
            }
          }
          li.slick-active {
            opacity: 1;

            button {
              box-shadow: none;
              background: $primary;
            }
          }
        }
      }

      .nav-button {
        position: absolute;
        opacity: 1;
        // box-shadow: none;
        color: $dark-bg;
        padding: 15px 20px;
        background: $white;
        border: 1px solid $border-color;
        box-shadow: 0 4px 8px 4px rgba(0, 0, 0, 0.04);

        &.prev {
          left: 30px;
        }

        &.next {
          right: 30px;
        }

        &:active {
          margin-left: 22px;
          padding: 14px 18px;
        }
      }
    }
  }
}

@media (max-height: 750px) and (min-width: 992px) {
  .gallery-holder {
    .carousel {
      .carousel-image-holder {
        .image-text {
          display: flex;
          justify-content: center;
          align-items: center;

          .image-description {
            display: inline-block;
            margin-top: -1px !important;
            padding-left: 15px !important;
          }
          .image-title {
            font-size: 15px !important;
            display: inline-block;
          }
        }
      }
    }
  }
}

@media (max-height: 570px) and (min-width: 992px) {
  .gallery-holder {
    .carousel {
      .slide-pagination {
        display: none;
      }
      .slick-slider {
        .slick-list {
          height: 100vh;

          .slick-track {
            .slick-slide {
              height: 100vh;

              .carousel-image-holder {
                max-height: 100vh;
                padding: 0px 140px;

                .carousel-image {
                  margin-top: 0;
                  max-height: 100vh;
                }
                .image-text {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 1200px) {
  .template {
  }
}
