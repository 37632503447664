@import "src/_main-imports.scss";

.videoBlock {
  .bg {
    width: 100%;
    object-fit: cover;
    object-position: center center;
    top: 0;
    right: 0;
    bottom: 0;
    border-radius: 8px;
    &:dir(rtl) {
      left: 0;
      right: unset;
    }

    @include media-breakpoint-up(lg) {
      width: 80%;
    }
  }

  .video {
    margin: 64px 0;

    .thumb {
      padding: 4px;
      background: white;
      -webkit-filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));
      filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));
      object-fit: cover;
      object-position: center center;
      transition: 250ms all ease-out;
    }
    .play {
      -webkit-filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));
      filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));
      transition: 250ms all ease-out;
    }

    &:hover {
      .play {
        transform: scale(1.1);
      }
      .thumb {
        -webkit-filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.25));
        filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.25));
      }
    }

    @include media-breakpoint-up(lg) {
      margin: 100px 0;
    }
  }

  &.isLeft {
    .bg {
      left: 0;
      right: unset;
      &:dir(rtl) {
        right: 0;
        left: unset;
      }
    }
  }
}

.videoModal {
  max-width: 85vw !important;
  max-height: 80vh !important;
  display: flex;
  overflow: visible !important;
  video {
    border-radius: 8px;
    width: 100%;
    height: auto;
    object-fit: contain;
    background: black;
  }
  .closeButton {
    background: white;
    -webkit-filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.1));
    filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.1));
    top: -22px;
    right: -12px;
  }
}

html[dir="rtl"] {
  .videoBlock {
    .bg {
      left: 0;
      right: unset;
    }

    &.isLeft {
      .bg {
        right: 0;
        left: unset;
      }
    }
  }
}
