.radiobox {
  position: relative;
  height: 30px;
  width: max-content;

  .input {
    position: absolute;
    width: 100%;
    z-index: 1;
    left: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
    cursor: pointer;
  }

  &:hover {
    .icon-holder:not(.checked) {
      border: 1px solid rgba($color: $primary, $alpha: 0.8);
    }
  }

  .icon-holder {
    cursor: pointer;
    left: 0;
    height: 18px;
    width: 18px;
    border-radius: 18px;
    border: 1px solid rgba($color: $primary, $alpha: 0.3);
    display: inline-block;

    .icon {
      margin: 1px 0 0 0;
      display: none;
    }

    .checked {
      background: $primary;
      box-shadow: 0 2px 4px 2px rgba($color: $primary, $alpha: 0.1);
      width: 14px;
      height: 14px;
      border-radius: 16px;
      .icon {
        color: $white;
        display: block;
      }
    }
  }
  .label {
    position: relative;
    font-size: 15px;
    font-family: $primary-font;
    font-weight: 600;
    color: $dark-text !important;
  }
}
