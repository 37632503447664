.auth-container {
}

.auth-form {
  font-size: 13px;
  font-weight: 500;
  color: $light-text;
  margin: 0 auto;
  min-height: 110vh;
  padding: 25px 15px;

  &__form-title {
    margin-bottom: 30px;
    font-size: 24px;
    color: #000;
    font-weight: 600;
  }

  &__error {
    // Position
    margin: 0 auto;
    margin-bottom: 35px;

    // Display
    text-align: center;
    max-width: 80%;

    // Font
    font-family: $text-font;
    font-size: 12px;
    font-weight: 500;
    color: $error-info;
  }

  &__login {
    .error-text {
      position: absolute;
      top: 106%;
      left: 0;
    }

    .sign-up,
    .reset-password,
    .forgot-password {
      margin-top: 3px;
      margin-bottom: 7px;
    }

    .reset-password-text,
    .forgot-password-text {
      font-size: 14px;
      text-align: center;
    }

    .login-button,
    .reset-password-button,
    .forgot-password-button {
      button {
        height: 55px;
      }
    }

    .forgot-password-button {
      padding-top: 100px;
    }
  }

  &__link {
    font-family: $primary-font;
    font-size: 14px;
    cursor: pointer;
    color: $dark-text;
    text-decoration: underline;
    font-weight: 500;
    background: none;
    border: none;
    margin: 0;
    padding: 0;
    height: unset;
    min-height: unset;

    &:focus {
      outline: none;
    }

    &:focus-visible {
      box-shadow: 0 0 0 4px white, 0 0 0 7px rgba($primary, 1);
    }
  }

  &__signup {
    .signup-button {
      min-height: 55px;
    }
  }

  &__signup-with-email {
    .password-strength {
      margin-bottom: 25px;
      font-family: $text-font;
      font-size: 11px;
      font-weight: 500;
      margin-top: -10px;

      &__item {
        margin: 1px 0;

        &.valid {
          color: $success-info;
        }

        &.invalid {
          color: $error-info;
        }

        &.valid.low {
          color: $warning-info;
        }
      }

      .item-icon {
        margin-right: 5px;
      }
    }

    .info {
      font-weight: 400;
      margin: 0;
      margin-top: -7px;
      margin-bottom: 20px;
      font-size: 11px;

      a {
        font-weight: 500;
        text-decoration: underline;
      }
    }

    .birthday-error {
      font-size: 13px;
      // margin-top: 7px;
      margin-bottom: 25px;
      color: #c65760;
      font-weight: 600;
      font-family: $primary-font;
      margin-left: 0;
    }
  }

  &__reset-password {
  }

  &__forgot-password {
  }

  &__verify-email {
    .verify-email-text {
      margin-top: -5px;
      text-align: center;
      font-family: $text-font;
      font-size: 13px;
      font-weight: 500;
      color: black;
    }
  }

  &__social-methods {
    padding-bottom: 15px;
    margin-top: 30px;

    .social-methods {
      &__method {
        margin-bottom: 10px;

        button {
          border: 1px solid rgba(0, 0, 0, 0.1);

          border-radius: 12px;
          background: white;
          font-family: $text-font;
          height: 55px;
          font-weight: 500;
          color: rgba(0, 0, 0, 0.9);
          width: 100%;
          text-align: left;
          padding: 0px 15px;
          font-size: 14px;
          box-shadow: 0 0 0 3px transparent;
          transition: box-shadow 0.1s ease;
          outline: none;
          display: flex;
          align-items: center;
          justify-content: space-between;

          span {
            svg {
              width: 23px;
              height: 23px;
            }
          }

          &:hover {
            box-shadow: 0 0 0 3px rgba($primary, 0.2);
          }

          &:active {
            box-shadow: 0 0 0 3px rgba($primary, 0.4);
            background: $white;
          }

          &:focus {
            outline: none;
          }

          &:focus-visible {
            box-shadow: 0 0 0 2px white, 0 0 0 5px rgba($primary, 1);
          }
        }

        &.email {
          .icon {
            width: 20px;
            height: 20px;
            margin-right: 1px;
          }
        }
        &.apple {
          .icon {
            color: #000000;
          }
        }

        &.facebook {
          .icon {
            color: #3579ea;
          }
        }

        &.google {
        }
      }
    }
  }

  &__social-methods-divider {
    position: relative;
    margin: 30px 0 20px 0;
    user-select: none;

    .divide {
      border-bottom: 1px solid $border-color;
      width: 45%;
      &:last-of-type {
        float: right;
      }
    }

    .divider-text {
      width: 100%;
      position: absolute;

      text-align: center;
      top: -8px;
      font-size: 13px;
      font-family: $primary-font;
      color: #a5a5a5;
      font-weight: 500;
      left: 0;
      right: 0;
    }
  }

  &__email-already-in-use {
    margin-bottom: 20px;

    display: flex;
    justify-content: center;
    flex-direction: column;

    .in-use {
      &__description {
        color: rgba(0, 0, 0, 0.9);
        font-weight: 500;

        text-align: center;
      }

      &__button {
        display: flex;
        justify-content: center;

        .button {
          background: none;
          padding: 3px 10px;
          margin: 0;
          margin-top: 4px;
          margin-left: 4px;
          border: none;
          color: rgba(0, 0, 0, 0.9);
          font-weight: 500;
          font-size: inherit;
          min-height: unset;
          border-radius: 6px;

          &:focus {
            outline: none;
          }
          &:hover,
          &:focus-visible {
            text-decoration: none;

            background: rgba(0, 0, 0, 0.05);
          }
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .auth-form {
    background: $white;
    max-width: 450px;
    margin-bottom: 30px;
    padding: 25px 20px;
    min-height: unset;
    border-radius: $border-radius-lg;
    box-shadow: 0px 10px 20px 10px rgba($primary, 0.08);

    input {
      background-color: rgba($color: $body-bg, $alpha: 0.7);
    }
  }

  .auth-container {
    margin-top: 50px;
    margin-bottom: 50px;

    .auth-form {
      box-shadow: 0 0 0 1px rgba($primary, 0.25);
      border-radius: $border-radius-lg;
      max-width: 500px;
      padding: 35px;

      &__form-title {
        text-align: center;
      }
    }
  }
}

@media (min-width: 992px) {
  .auth-form {
  }
}

@media (min-width: 1200px) {
  .auth-form {
  }
}
