@import "./_main-imports.scss";
@import "./Styles/i18n/i18n.scss";

/* Overall Styles */
html,
body,
#root {
  width: 100%;
  height: 100%;
}
html * {
  -webkit-text-size-adjust: none;
}

body {
  margin: 0;
  font-family: $text-font;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: scroll;
  font-size: 15px;
  min-width: 320px;
  height: 100%;
}
html,
body {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $primary-font;
  color: $dark-text;
  font-weight: 600;
}

.app {
  width: 100%;
  min-width: 320px;
}

ul {
  list-style: none;
  margin-left: -40px;
}

.icon,
.logo {
  user-select: none;
}

.no-pointer-events {
  pointer-events: none;
  user-select: none;
}

.min-full-page-height {
  height: calc(100vh - 400px);
}

.no-scrollbar {
  &::-webkit-scrollbar {
    opacity: 0;
    width: 0px;
    height: 0px;
  }
}

// REMOVE NUMBER ARROWS
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.text-font {
  font-family: $text-font;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

@include media-breakpoint-down(md) {
  .hide-mobile {
    display: none;
  }
}

@include media-breakpoint-up(md) {
  .hide-desktop {
    display: none;
  }
}

//Typography
.brand-color {
  color: $primary-text;
}
.stella_h1 {
  /* Mobile/H1 - Mobile */
  font-family: $primary-font;
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 53px;
  /* identical to box height, or 110% */
  letter-spacing: -0.02em;

  @include media-breakpoint-up(md) {
    /* Desktop/H1 */
    font-family: $primary-font;
    font-style: normal;
    font-weight: 700;
    font-size: 64px;
    line-height: 125%;
    /* identical to box height, or 91px */
    letter-spacing: -0.02em;
  }
}

.stella_h2 {
  /* Mobile/H1 - Mobile */
  font-family: $primary-font;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 125%;
  /* identical to box height, or 30px */

  letter-spacing: -0.02em;

  @include media-breakpoint-up(md) {
    /* Desktop/H1 */
    font-family: $primary-font;
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 130%;
    /* identical to box height, or 47px */

    letter-spacing: -0.02em;
  }
}

.stella_h3 {
  /* Mobile/H3 - Mobile */
  font-family: $primary-font;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  /* identical to box height, or 135% */

  letter-spacing: -0.02em;

  letter-spacing: -0.02em;

  @include media-breakpoint-up(md) {
    /* Desktop/H3 */
    font-family: $primary-font;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 130%;
    /* identical to box height, or 34px */

    letter-spacing: -0.02em;
  }
}

.stella_h4 {
  /* Mobile/H1 - Mobile */
  font-family: $primary-font;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 140%;

  @include media-breakpoint-up(md) {
    /* Desktop/H1 */
    font-family: $primary-font;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 140%;
  }
}

.stella_featured_para {
  /* Desktop/Feature Paragraph */

  font-family: $text-font;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  /* identical to box height, or 26px */

  letter-spacing: -0.02em;

  color: $gray-text;
}

.stella_btn {
  margin: 0;
  padding: 0 16px;
  height: 38px;
  border: none;
  border-radius: 8px;
  background: white;
  display: flex;
  align-items: center;
  box-shadow: 0 0 0 1px rgb(61 78 83 / 10%);
  font-family: $primary-font;
  font-weight: 600;
  font-size: 15px;
  line-height: 15px;
  transition: all 80ms ease-in-out;
  color: #222222;
  text-decoration: none;
  text-align: center;
  &:hover {
    box-shadow: 0 2px 4px 2px rgb(0 0 0 / 17%);
  }
}
.stella_primary_font {
  font-family: $primary-font;
}

$grid-2xl: 1536px;
