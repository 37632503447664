@import "src/_main-imports.scss";

.container {
  display: inline-flex;
  margin-top: 1.5rem;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-width: 420px;
  padding: 0 8px;

  .codeContainer {
    display: flex;
    margin-top: 0.75rem;
    margin-top: 0.875rem;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    .title {
      font-family: $primary-font;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 103%;
      text-align: center;
      color: $primary-text;
      margin: 16px 0;
    }

    .code {
      box-sizing: border-box;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 12px 44px;
      gap: 10px;
      background: $light-background;
      border: 1px solid $light-background-border;
      border-radius: 6px;
      .label {
        font-family: $primary-font;
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        text-align: center;
        color: $light-text;
      }
      .codeNumber {
        font-family: $primary-font;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 22px;
        text-align: center;
        letter-spacing: 0.15em;
        margin-bottom: 0;
        color: $gray-text;
      }
    }
    .message {
      font-family: $primary-font;
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      text-align: center;
      margin: 16px 0;
      color: $gray-text;
    }
  }

  .listingContainer {
    display: flex;
    padding: 0.75rem;
    margin-top: 0.75rem;
    margin-top: 0.875rem;
    background-color: $white;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    border-radius: 0.5rem;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);

    .listingTitle {
      font-family: $primary-font;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 102%;
      color: $body-text-color;
      margin: 0;
      text-align: left;
    }
    .listingLocation {
      font-family: $text-font;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 142%;
      color: $gray-text;
      margin: 0;
      text-align: left;
    }

    .datesContainer {
      .date {
        p {
          font-family: $primary-font;
          font-style: normal;
          font-weight: 600;
          font-size: 13px;
          line-height: 16px;
          color: $normal-icon-color;
        }
        h5 {
          margin: 0;
          font-family: $primary-font;
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 22px;
          color: $darker-text;

          span {
            font-family: $primary-font;
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 16px;
            color: $gray-text;
          }
        }
      }
    }
  }
}

.successFooter {
  h6 {
    color: $gray-text-dark;
    font-family: $primary-font;
    font-size: 15px;
    font-weight: 600;
  }

  p {
    color: $gray-text;
    font-family: $primary-font;
    font-size: 13px;
    font-weight: 500;
  }

  .icon {
    path {
      fill: white !important;
    }
  }
}

html[dir="rtl"] {
  .container {
    .listingContainer {
      .listingTitle {
        text-align: right;
      }
      .listingLocation {
        text-align: right;
      }
    }
  }
}
