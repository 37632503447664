$dot-size: 6px;
$gap-multiplier: 1.6;
$gap-size: calc(#{$dot-size} * #{$gap-multiplier});
$color: #000;
$light-color: #fff;
$speed: 350ms;

.loading {
  width: 100%;
  height: 90%;
  flex-grow: 0.9;
  display: flex;
  justify-content: center;
  align-items: center;

  &__dots {
    position: relative;
    width: $dot-size;
    height: $dot-size;
    border-radius: $dot-size;
    background-color: rgba($color, 0.1);
    animation: dotFlashing $speed infinite linear alternate;
    animation-delay: calc(#{$speed} / 2);

    &::after,
    &::before {
      content: "";
      display: inline-block;
      position: absolute;
      top: 0;
      background-color: rgba($color, 0.1);
      width: $dot-size;
      height: $dot-size;
      border-radius: $dot-size;
    }

    &::before {
      left: calc(#{$gap-size} * -1);
      animation: dotFlashing $speed infinite alternate;
      animation-delay: 0s;
    }

    &::after {
      left: $gap-size;
      animation: dotFlashing $speed infinite alternate;
      animation-delay: $speed;
    }
  }

  &.light {
    filter: invert(1);
  }
}

@media (min-width: 768px) {
  $dot-size: 7px;
  $gap-size: calc(#{$dot-size} * #{$gap-multiplier});

  .loading {
    &__dots {
      width: $dot-size;
      height: $dot-size;
      border-radius: $dot-size;

      &::after,
      &::before {
        width: $dot-size;
        height: $dot-size;
        border-radius: $dot-size;
      }

      &::before {
        left: calc(#{$gap-size} * -1);
      }

      &::after {
        left: $gap-size;
      }
    }
  }
}

@keyframes dotFlashing {
  0% {
    background-color: rgba($color, 1);
  }
  50%,
  100% {
    background-color: rgba($color, 0.1);
  }
}
