$light: #fff;
$skeleton-width: 240px;

.skeleton-loader-holder {
  .skeleton-loader {
    position: relative;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    overflow: hidden;
    transition: opacity 100ms cubic-bezier(0.075, 0.82, 0.165, 1) 50ms;
    opacity: 1;
    background: $skeleton_loader-color;
    box-shadow: 0 0 0 1px #e9eaec;

    &::before {
      content: "";
      display: block;
      position: absolute;
      left: -$skeleton-width;
      top: 0;
      height: 100%;
      width: $skeleton-width;
      background: linear-gradient(
        90deg,
        rgba($light, 0) 0%,
        rgba($light, 0.4) 30%,
        rgba($light, 0.7) 70%,
        rgba($light, 0) 100%
      );

      animation: skeletonLoader 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
    }
  }
}

@keyframes skeletonLoader {
  from {
    left: -$skeleton-width;
  }
  to {
    left: 100%;
  }
}
