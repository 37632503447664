// @import "src/_main-imports.scss";

.flexItem {
  img {
    width: 100%;
  }
  .description {
    width: 100%;
  }
}
