.not-found {
  margin: 50px 0px;

  .description {
    padding-top: 10px;
    border-bottom: 1px solid $border-color;
    padding-bottom: 20px;
  }

  .useful-links {
    margin-top: 40px;
    p {
      color: $dark-text;
    }
    ul {
      margin-top: -20px;
      a {
        margin-left: -20px;
        li {
          padding: 0;
          margin: -6px 0;
        }
      }
    }
  }
}
