$button-height: 38px;

@mixin button() {
  $box-shadow: 0 0 0 1px rgba($primary-darkest, 0.1);
  padding: 0;
  margin: 0;
  padding: 0 16px;
  height: $button-height;
  border: none;
  border-radius: $button-height;
  background: white;
  display: flex;
  align-items: center;
  box-shadow: $box-shadow;
  font-family: $primary-font;
  font-weight: 600;
  font-size: 15px;
  padding-top: 2px;
  transition: all 80ms ease-in-out;
  color: $body-text-color;

  &:focus {
    outline: none;
  }

  &:focus-visible {
    box-shadow: 0 0 0 2px $primary;
  }

  &:hover {
    background: rgba($primary-darkest, 0.09);
  }
}

@mixin primary() {
  background: $primary-dark;
  color: white;
  font-weight: 600;

  &:hover {
    background: $primary-dark;
    box-shadow: 0 2px 4px 2px rgba(black, 0.17);
  }
}

@mixin primaryLight() {
  box-shadow: 0 0 0 1px white;
  background: white;
  color: black;

  &:hover {
    background: white;
    box-shadow: 0 1px 2px 1px rgba(black, 0.12);
  }
}

.topbar {
  // Variables
  $mobile-menu-padding: 25px;
  $mobile-menu-list-item-padding: 10px;

  // Topbar
  position: relative;
  top: 0;
  left: 0;
  z-index: 4;
  width: 100%;
  min-width: 320px;
  height: $mobile-nav-height;
  // box-shadow: 0 0 0 1px rgba($primary-darkest, 0.06);
  touch-action: manipulation;
  background-color: white;
  transition: background-color 150ms ease-out;

  &--fixed {
    position: sticky;
    top: 0;
  }

  &--transparent-at-top &__dropdown {
    .dropdown__button {
      background: none;
      color: white;
      font-weight: 600;
      box-shadow: 0 0 0 1px rgba(white, 0.3);

      &:hover {
        background: rgba(black, 0.1);
      }
      &:focus-visible {
        box-shadow: 0 0 0 5px white !important;
      }
    }
  }

  .navlink {
    color: #222222;
    background: none;
    font-weight: 600;
    padding: 0;
    margin: 0;
    padding: 0 12px;
    height: 38px;
    border: none;
    border-radius: 38px;
    display: flex;
    align-items: center;
    font-family: "Gilroy", "Almarai", sans-serif;
    font-weight: 600;
    font-size: 15px;
    padding-top: 2px;
    transition: all 80ms ease-in-out;
    text-decoration: none;
    &:hover {
      background: rgba(61, 78, 83, 0.09);
    }
  }

  &--transparent-at-top {
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.2) 0%,
      rgba(0, 0, 0, 0) 100%
    );
    box-shadow: none;

    .navlink {
      color: white;

      &:hover {
        background: rgba(black, 0.1);
      }
      &:focus-visible {
        box-shadow: 0 0 0 5px white !important;
      }
    }

    .logo_image,
    .topbar__mobile-menu-button .icon {
      filter: brightness(1000%);
      color: white;
    }
    .topbar {
      &__dropdown .dropdown__content {
        box-shadow: 0 12px 24px 12px rgba($primary-darkest, 0.08),
          0 0 0 1px white inset;
      }
      &__language_switcher {
        .svgicon {
          filter: invert(94%) sepia(11%) saturate(25%) hue-rotate(42deg)
            brightness(107%) contrast(105%);
        }
      }

      &__dropdown {
        &--primary {
          .dropdown__button {
            @include primaryLight();
          }
        }
      }
    }
  }

  &.static {
    position: relative;
    transform: translateY(0) !important;
    margin-bottom: -$mobile-nav-height;
    box-shadow: 0 0 0 1px rgba($primary-darkest, 0.08);
  }

  &__desktop-only-item {
    display: none;
  }

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }

  &__logo {
    padding-top: 5px;
    .logo_image {
      height: 35px;
      user-select: none;
      // margin-left: -5px;
      transition: all 100ms ease;
    }
  }

  &__mobile-menu-button {
    $size: 26px;
    $padding: 12px;
    position: relative;
    border: none;
    background: none;
    color: black;
    pointer-events: all;
    padding: $padding;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 40px;
    margin-right: -$padding;

    .icon {
      display: block;
      width: $size;
      height: $size;
    }

    &:focus {
      outline: none;
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background: rgba($primary-darkest, 0.08);
      }
    }

    &:focus-visible {
      box-shadow: 0 0 0 2px $primary inset;
    }
  }

  &__holder {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: $mobile-nav-height;
    pointer-events: none;
    z-index: 3;

    &.mobile-menu-open {
      height: 100vh;
      pointer-events: all;
    }
  }

  &__content {
    position: relative;
    padding-top: $mobile-nav-height;
    background: white;
    width: 100%;
    height: calc(100vh);
    overflow-y: scroll;
    overflow-x: hidden;
    transform: translateY(-100%);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: transform 300ms cubic-bezier(0.25, 1, 0.5, 1);
  }

  &__mobile-background {
    position: fixed;
    z-index: -1;
    height: 100vh;
    width: 100vw;
    left: 0;
    top: 0;
    background-color: rgba(#000, 0.5);
    pointer-events: none;
    opacity: 0;
    transition: opacity 300ms ease-in-out;
  }

  &__items {
    height: 100%;
    display: flex;
    flex-direction: column;

    padding: $mobile-menu-padding;
    padding-bottom: 0;

    .items {
      display: flex;
      flex-direction: column;
      margin-bottom: 15px;
    }
  }

  &__holder.mobile-menu-open &__mobile-background {
    opacity: 1;
    pointer-events: all;
  }

  &__holder.mobile-menu-open &__content {
    transform: translateY(0%);
    min-height: 500px;
    transition: transform 500ms cubic-bezier(0.22, 1, 0.36, 1);
  }

  &__mobile-content {
    display: flex;
    justify-content: space-between;
    height: 100%;
    flex-direction: column;
    .main-content {
      .group {
        margin-bottom: 35px;
        &__title {
          font-family: $text-font;
          font-size: 10px;
          text-transform: uppercase;
          letter-spacing: 2px;
          color: rgba(#000, 0.5);
          user-select: none;
        }

        &__items {
          margin-bottom: 15px;
        }

        &__item {
          width: 100%;
          text-align: left;
          background: none;
          border: none;
          padding: 0;
          margin: 0;
          display: block;
          display: flex;
          justify-content: space-between;
          font-family: $primary-font;
          padding: 4px 0;
          font-weight: 500;
          color: black;
          text-decoration: none;

          &:focus-visible {
            box-shadow: 0 0 0 2px white, 0 0 0 4px $primary-darkest;
          }
        }
      }
      .small-group {
        .group__item {
          width: max-content;
        }
      }
      .account-group {
        .group__item {
          font-size: 18px;
          padding: 12px 0;
          margin-bottom: 6px;
          font-weight: 600;
          border-bottom: 1px solid rgba($primary-darkest, 0.12);
        }
      }
    }
    .bottom-content {
      padding-bottom: 150px;

      .bottom-group {
        &__link {
          font-family: $primary-font;
          font-size: 13px;
          margin-right: 8px;
          color: rgba(#000, 0.5);
          font-weight: 500;
          &:focus-visible {
            box-shadow: 0 0 0 2px white, 0 0 0 4px $primary-darkest;
          }

          &:last-of-type {
            margin-right: 0px;
          }
        }
      }

      .legals {
        padding-top: 10px;
        border-top: 1px solid rgba($primary-darkest, 0.12);

        &__link:not(:last-of-type) {
          &::after {
            margin-left: 8px;
            margin-bottom: 3px;
            content: "";
            display: inline-block;
            width: 3px;
            height: 3px;
            border-radius: 6px;
            background-color: rgba(#000, 0.3);
          }
        }
      }
    }
  }

  &__dropdown {
    position: relative;

    .dropdown {
      &__button {
        @include button();
      }

      &__chevron {
        opacity: 0.6;
      }

      &__content {
        padding: 6px 0;
        visibility: hidden;
        position: absolute;
        top: calc(#{$button-height} + 5px);
        right: -5px;
        border-radius: 20px;
        box-shadow: 0 12px 24px 12px rgba($primary-darkest, 0.08),
          0 0 0 1px rgba(black, 0.06) inset;
        background: rgba(white, 0.95);
        backdrop-filter: saturate(180%) blur(20px);
        opacity: 0;
        transition: opacity 80ms ease-in-out;
      }

      &__item {
        > * {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 8px 15px;
          margin: 1px 6px;
          width: max-content;
          min-width: 120px;
          text-align: left;
          background: none;
          border: none;
          font-size: 14px;
          color: $body-text-color;
          font-family: $primary-font;
          font-weight: 600;
          text-decoration: none;
          border-radius: 50px;

          &:focus {
            outline: none;
          }

          &:focus-visible,
          &:hover {
            background: rgba($primary-darkest, 0.1);
            text-decoration: none;
          }
        }
        &--has-icon > * {
          padding: 10px 15px;

          .custom-icon {
            width: 20px;
            height: 20px;
            opacity: 0.6;
          }
        }
      }
    }

    &--active .dropdown__content {
      visibility: visible;
      opacity: 1;
    }

    &--loading {
      @include button();
      width: 74px;
      height: 40px;
    }

    &--no-label-border {
      .dropdown__button {
        box-shadow: none !important;
        padding: 0 12px;
        padding-top: 3px;
        background: none;
      }
    }

    &--primary {
      .dropdown__button {
        @include primary();
      }
    }
  }
  &__language_switcher {
    .svgicon {
      filter: invert(56%) sepia(24%) saturate(326%) hue-rotate(146deg)
        brightness(97%) contrast(94%);
    }
  }
}

@media (min-width: 576px) {
  .topbar {
    &__holder {
      left: unset;
      right: 8px;
      width: 400px;
      background: none;

      &.mobile-menu-open {
        height: 100vh;
        pointer-events: all;
      }
    }

    &__mobile-background {
      display: none;
    }

    &__content {
      opacity: 0;
      pointer-events: none;
      position: relative;
      padding-top: 0px;
      margin-top: calc(#{$mobile-nav-height} + 8px);
      height: max-content;
      max-height: calc(100vh - #{$mobile-nav-height} - 15px);
      transform: unset;
      border-radius: 16px;
      box-shadow: 0 12px 24px 12px rgba($primary-darkest, 0.08),
        0 0 0 1px rgba(black, 0.15) inset;
      transition: unset;
      background: rgba(white, 0.92);
      backdrop-filter: saturate(180%) blur(20px);
    }

    &__holder.mobile-menu-open &__mobile-background {
      display: none;
    }

    &__holder.mobile-menu-open &__content {
      transform: unset;
      opacity: 1;
      pointer-events: all;
      min-height: 300px;
      transition: opacity 50ms ease-in-out;
    }

    &__items {
      padding: 20px;
    }

    &__mobile-content {
      .bottom-content {
        padding-bottom: 0;
        width: 100%;

        .legals {
          width: 100%;
          display: flex;
          justify-content: center;
        }
      }
    }

    &__language_switcher {
      .svgicon {
        filter: invert(94%) sepia(11%) saturate(25%) hue-rotate(42deg)
          brightness(107%) contrast(105%);
      }
    }
  }
}

@media (min-width: 768px) {
  .topbar {
    $button-height: 38px;
    height: $desktop-nav-height;
    border-bottom: none;

    &.static {
      position: fixed;
    }

    &__logo {
      padding-top: 3px;
      .logo_image {
        height: 40px;
        user-select: none;
      }
    }

    &__find-a-stella {
      opacity: 1;
      transition: opacity 200ms ease;
      .search_page__filters {
        position: absolute;
      }
      .filters-button {
        background: none !important;
      }
    }

    &__desktop-only-item {
      display: block;
    }

    &__contact {
      display: flex;
      position: relative;
      padding-right: 10px;

      &::after {
        display: block;
        position: absolute;
        content: "";
        height: 12px;
        background-color: rgba($primary-darkest, 0.3);
        width: 1px;
        right: 0;
        top: 14px;
      }
      .contact-item {
        padding: 8px;
        margin-right: 2px;
        border-radius: 50px;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
          background: rgba($primary-darkest, 0.09);
        }
        &:focus {
          outline: none;
        }
        &:focus-visible {
          box-shadow: 0 0 0 3px $primary;
        }
        .icon {
          filter: $icon-to-primary;
        }
      }
    }

    &__language_switcher {
      .svgicon {
        filter: $icon-to-primary;
      }
    }

    &__items {
      display: flex;
      justify-content: flex-end;
    }

    &__desktop {
      width: auto;
      display: flex;
      align-items: center;
    }

    &__dropdown {
      position: relative;

      .dropdown {
        &__button {
          @include button();
        }

        &__chevron {
          opacity: 0.6;
        }

        &__content {
          padding: 6px;
          visibility: hidden;
          position: absolute;
          top: calc(#{$button-height} + 5px);
          right: -5px;
          border-radius: 20px;
          box-shadow: 0 12px 24px 12px rgba($primary-darkest, 0.08),
            0 0 0 1px rgba(black, 0.06) inset;
          background: rgba(white, 0.95);
          backdrop-filter: saturate(180%) blur(20px);
          opacity: 0;
          transition: opacity 80ms ease-in-out;
        }

        &__item {
          > * {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 8px;
            margin: 0;
            width: max-content;
            width: 100%;
            min-width: 120px;
            text-align: left;
            background: none;
            border: none;
            font-size: 14px;
            color: $body-text-color;
            font-family: $primary-font;
            font-weight: 600;
            text-decoration: none;
            border-radius: 50px;
            white-space: nowrap;
            gap: 8px;

            &:focus {
              outline: none;
            }

            &:focus-visible,
            &:hover {
              background: rgba($primary-darkest, 0.1);
              text-decoration: none;
            }
          }
          &--has-icon > * {
            padding: 10px 15px;

            .custom-icon {
              width: 20px;
              height: 20px;
              opacity: 0.6;
            }
          }
        }
      }

      &--active .dropdown__content {
        visibility: visible;
        opacity: 1;
      }

      &--loading {
        @include button();
        width: 74px;
        height: 40px;
      }

      &--no-label-border {
        .dropdown__button {
          box-shadow: none !important;
          padding: 0 12px;
          padding-top: 3px;
          background: none;
        }
      }

      &--primary {
        .dropdown__button {
          @include primary();
        }
      }
    }

    &__guest {
      display: flex;
      align-items: center;

      .guest__button {
        @include button();

        &--signup {
          background: $primary-darkest;
          color: white;
          font-weight: 600;

          &:hover {
            background: $primary-dark;
          }
        }

        &--login {
          @include primary();
        }
      }
    }

    &--transparent-at-top &__dropdown,
    &--transparent-at-top &__guest {
      .dropdown__button,
      .guest__button {
        background: none;
        color: white;
        font-weight: 600;
        box-shadow: 0 0 0 1px rgba(white, 0.3);

        &:hover {
          background: rgba(black, 0.1);
        }
        &:focus-visible {
          box-shadow: 0 0 0 5px white !important;
        }
      }
    }
    &--transparent-at-top {
      .topbar {
        &__contact {
          &::after {
            background-color: rgba(white, 0.5);
          }

          .icon {
            filter: invert(94%) sepia(11%) saturate(25%) hue-rotate(42deg)
              brightness(107%) contrast(105%);
          }
        }

        &__find-a-stella {
          opacity: 0;
          pointer-events: none;
        }
        &__dropdown .dropdown__content {
          box-shadow: 0 12px 24px 12px rgba($primary-darkest, 0.08),
            0 0 0 1px white inset;
        }
        &__language_switcher {
          .svgicon {
            filter: invert(94%) sepia(11%) saturate(25%) hue-rotate(42deg)
              brightness(107%) contrast(105%);
          }
        }

        &__dropdown {
          &--primary {
            .dropdown__button {
              @include primaryLight();
            }
          }
        }

        &__guest {
          .guest__button {
            &--login {
              @include primaryLight();
            }
          }
        }
      }
    }

    &--fixed {
      .topbar {
        &__find-a-stella {
          .search_page__filters {
            position: fixed;
          }
        }
      }
    }
  }
}
@media (min-width: 1400px) {
  .topbar {
    &__holder,
    &__content,
    &__items {
      position: relative;
      height: auto;
      width: unset;
      right: unset;
      left: unset;
      top: unset;
      bottom: unset;
      margin: unset;
      padding: 0;
      transform: unset;
      overflow-y: unset;
      overflow-x: unset;
      opacity: 1;
      pointer-events: all;
      background: none;
      box-shadow: none;
      backdrop-filter: none;
    }

    &__mobile-only-item {
      display: none;
    }
  }
}
