.suite {
  &__things-to-note {
    margin: 0px calc(#{$container-padding} / 2 * -1);
    margin-top: 40px;
    padding: calc(#{$container-padding} * 0.5);
    border-radius: 12px;
    background-color: rgba($primary, 0.05);
    box-shadow: 0 0 0 1px rgba($primary, 0.3),
      0 3px 6px 3px rgba($primary, 0.16);

    .suite__section {
      padding: 0;
    }
    .things-to-note__content {
      white-space: pre-line;
      font-size: 14px;
    }
  }
}

@include media-breakpoint-up(md) {
  .suite {
    &__things-to-note {
      position: relative;
      font-size: 14px;
      margin: 0px calc(#{$container-padding} / 2 * -1);
      margin-top: 40px;

      padding: 15px calc(#{$container-padding} * 0.5) 30px
        calc(#{$container-padding} * 0.5);

      border-radius: unset;
      background-color: unset;
      box-shadow: unset;

      &::after {
        position: absolute;
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        left: -50px;
        top: 0;

        border-radius: 20px;
        box-shadow: 0 0 0 1px rgba($primary, 0.7);
      }

      .suite__section {
        padding: 0;
      }
    }
  }
}
