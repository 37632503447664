.request-to-delete {
  margin-top: 20px;
  box-shadow: 0 0 0 1px rgba($primary-darkest, 0.15);

  border-radius: 8px;
  padding: $container-padding;

  display: flex;
  gap: 12px;

  &__icon-holder {
    .icon {
      color: $primary-darkest;
      width: 22px;
      height: 22px;
    }
  }

  &__content .content {
    color: black;

    &__header {
      font-weight: 500;
      font-size: 16px;
      font-family: $text-font;
    }

    &__description {
      font-size: 13px;
      margin: 12px 0;
      opacity: 0.7;
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
        Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    }

    &__button {
      padding: 0;
      margin: 0;
      background: none;
      color: $danger-color-dark;
      border: none;
      font-size: 13px;
      font-weight: 500;
      font-family: $text-font;
      border-radius: 6px;

      .icon {
        font-size: 11px;
      }
    }
  }
}

.request-to-delete-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  background: none;

  &__bg {
    display: block;
    background: rgba(0, 0, 0, 0.6);
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
  }

  &__content {
    position: fixed;
    left: 0;
    width: 100vw;
    bottom: 0;
    background: white;
    border-radius: 20px 20px 0 0;

    .content {
      &__title {
        text-align: center;
        font-weight: 600;
        font-family: $primary-font;
        font-size: 17px;
        padding-top: 25px;
      }
      &__description {
        text-align: center;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
          Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
        padding: 12px;
        font-size: 12px;
        opacity: 0.8;
      }
      &__caution {
        text-align: center;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
          Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
        font-size: 12px;
        padding: 12px;
        border-radius: 6px;
        background-color: rgba($danger-color, 0.2);
        color: $danger-color-dark;
        box-shadow: 0 0 0 1px $danger-color;
        margin: 0 20px 20px 20px;
      }

      &__confirm-area {
        .confirm-area {
          &__verify {
            padding: 24px $container-padding;
            background: rgba(0, 0, 0, 0.02);
            box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.08);
          }

          &__label {
            font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
              Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
              sans-serif;
            font-size: 13px;
          }

          &__verification-text {
            font-weight: 700;
          }

          &__error,
          &__error-mutation,
          &__submitted {
            margin-top: 5px;
            font-weight: 600;
            font-family: $primary-font;
            font-size: 12px;
            color: $error-info;

            .icon {
              margin-right: 5px;
            }
          }

          &__error-mutation,
          &__submitted {
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: 15px;
            color: $danger-color-dark;
            font-weight: 500;
            margin-bottom: 15px;

            .icon {
              width: 20px;
              height: 20px;
              margin-bottom: 5px;
            }
          }

          &__submitted {
            color: black;
            font-weight: 600;
          }

          &__input {
            -webkit-appearance: none;
            width: 100%;
            height: 40px;
            border: none;
            border-radius: 4px;
            padding: 0 8px;
            font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
              Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
              sans-serif;
            box-shadow: 0 0 0 1px rgba($primary-darkest, 0.12);

            &:focus {
              box-shadow: 0 0 0 1px rgba($primary-darkest, 0.8);
            }
          }

          &__buttons {
            display: flex;

            .button {
              width: 100%;
              border-radius: 0;
              background: rgba(0, 0, 0, 0.04);
              font-family: $primary-font;
              font-size: 14px;
              font-weight: 600;
              color: black;

              &.verify {
                border-left: 1px solid rgba($primary-darkest, 0.08);
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 576px) {
  .request-to-delete-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    &__content {
      position: relative;
      left: unset;
      bottom: unset;
      width: 460px;
      border-radius: 12px;
      box-shadow: 0 0 0 1px rgba($primary-darkest, 0.12),
        0 6px 12px 6px rgba($primary-darkest, 0.12);

      .content {
        &__title {
          font-size: 20px;
        }
        &__description {
          font-size: 13px;
          padding: 12px 25px 20px 25px;
        }
        &__caution {
          font-size: 13px;
        }

        &__confirm-area {
          .confirm-area {
            &__buttons {
              .button {
                width: 100%;
                border-radius: 0;
                background: rgba(0, 0, 0, 0.04);
                font-family: $primary-font;
                font-size: 14px;
                font-weight: 600;
                color: black;

                &:focus {
                  outline: none;
                }

                &:hover {
                  opacity: 0.7;
                }
                &:focus-visible {
                  box-shadow: 0 0 0 2px white inset,
                    0 0 0 4px $primary-darkest inset;
                }

                &.verify {
                  border-left: 1px solid rgba($primary-darkest, 0.08);
                }
              }
            }
          }
        }
      }
    }
  }
}
