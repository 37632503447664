@import "src/variables";
@import "src/mixin";

.p_4 {
  padding: 1rem;
}
dd {
  margin-right: 0;
}
dl {
  margin-bottom: 0;
}
.summary-card {
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &__container {
    font-family: $primary-font;
    max-width: 427.33px;
    flex-basis: 427.33px;
    border-radius: 16px;
    border: 1px solid #d9e5e9;
    font-size: 16px;
    text-align: start;
    box-shadow: 0px 4px 4px 0px #234c5b1a;
    background-color: $white;
    @include media-query(lg, max) {
      max-width: unset;
      font-size: 12px;
      width: 100%;
    }
  }

  &__header {
    @include media-query(lg, max) {
      padding: 1rem;
      flex-grow: 0;
      display: flex;
      gap: 12px;
      cursor: pointer;
    }

    &__image {
      width: 100%;
      height: 200px;
      object-fit: cover;
      background-color: #e8e9eb;
      @include media-query(lg, max) {
        width: 70px;
        height: 70px;
        border-radius: 12px;
        align-self: center;
      }
    }

    hgroup {
      padding: 1rem;
      @include media-query(lg, max) {
        padding: 0;
        flex: 1;
      }
    }

    &__chevron-icon {
      will-change: transform;
      align-self: center;
      flex-shrink: 0;
      transition: transform 0.3 ease;
      transform: rotate(180deg);

      @include media-query(lg, min) {
        display: none;
      }

      &[data-toggle="true"] {
        transform: rotate(0);
      }
    }

    &__title {
      font-size: 18px;
      margin-bottom: 1rem;
      font-weight: 600;
      @include media-query(lg, max) {
        margin-bottom: 0.5rem;
        font-size: 14px;
      }
    }

    &__subtitle {
      font-size: 12px;
      margin-bottom: 0;
    }
  }

  &__separator {
    will-change: opacity;
    background-color: #e8e9eb;
    height: 8px;

    @include media-query(lg, max) {
      height: 5px;

      &[data-toggle="false"] {
        opacity: 0;
      }
    }
  }

  &__body {
    flex-shrink: 0;
    padding: 1.5rem 1rem;
    font-size: 16px;
    overflow: hidden;

    dt {
      font-weight: 600;
    }
    dd {
      font-size: 14px;
      color: #737373;
      margin-bottom: 0;
    }

    &__arrow-right {
      [dir="rtl"] & {
        transform: scaleX(-1);
      }
    }

    @include media-query(lg, max) {
      &[data-toggle="false"] {
        display: none;
      }
    }

    @include media-query(xl, max) {
      font-size: 14px;
      dd {
        font-size: 12px;
      }
    }

    @include media-query(md, max) {
      font-size: 12px;
    }
  }

  &__info {
    &__title {
      font-weight: 600;
      color: $black;
      font-size: 16px;
      @include media-query(lg, max) {
        font-size: 12px;
      }
    }

    &__subtitle {
      color: #737373;
      font-size: 14px;
      font-weight: normal;
      @include media-query(lg, max) {
        font-size: 12px;
      }
    }
  }
}
