@import "src/variables";
@import "src/mixin";

.booking-details {
  &__gray-section-container {
    min-height: 395px;
    margin: 80px 0;
    font-family: $primary-font;
    @include media_query(lg, max) {
      margin: 48px 0;
    }
  }
  &__gray-section {
    background: #f4f4f4;
    padding: 40px 0;
    &__title {
      font-size: 30px;
      @include media_query(md, max) {
        font-size: 24px;
      }
    }
  }
  &__list-container {
    @include media_query(lg, max) {
      display: flex;
      width: 100%;
      margin-bottom: 20px;
    }
  }
  &__list {
    list-style: none;
    list-style-position: none;
    padding: 0px;
  }
  &__list-item {
    margin-top: 20px;
  }

  &__stores-section {
    margin-top: 36px;
  }

  &__digital-unlock-section {
    display: flex;
    justify-content: start;
    position: relative;

    &__image:nth-of-type(1) {
      position: absolute;
      top: -75%;
      aspect-ratio: 0.4815/1;
    }
    &__image:nth-of-type(2) {
      position: absolute;
      top: -75%;
      aspect-ratio: 0.4815/1;
      margin-inline-start: 250px;
    }
    &__image-sm {
      width: 170.199px;
    }
  }
}
