html[dir="rtl"] {
  .homepage {
    &__app-showcase {
      .app-showcase {
        &__title {
          padding: 30px 25px 0 60px;
        }
        &__amenities {
          .amenity {
            &__icon {
              margin-right: 0;
              margin-left: 20px;
            }
          }
        }

        &__articles {
          .article {
            padding-left: unset;
            padding-right: $container-padding;
            margin-right: unset;
            margin-left: 30px;
          }
        }
        &__badges {
          padding: 25px;
        }
      }
    }

    &__gallery {
      .gallery {
        .gallery-item {
          &__text {
            margin: -60px -10px 30px 20px;
          }

          &.item-2 {
            .gallery-item {
              &__text {
                margin: -60px 20px 30px -10px;
              }
            }
          }
        }
      }
    }

    &__header {
      .header-carousel {
        &__title {
          .title {
            &__main {
              line-height: 140%;
            }
          }
        }
      }
    }
  }

  .topbar {
    &__dropdown .dropdown__content {
      left: -5px;
      right: unset;
    }
    &__mobile-menu-button {
      margin-left: -12px;
      margin-right: unset;
    }
    &__mobile-content {
      .main-content {
        .group {
          &__title {
            letter-spacing: 0;
          }
        }
      }
      .bottom-content {
        .bottom-group {
          &__link {
            margin-left: 8px;
            margin-right: unset;
            &:not(:last-of-type) {
              &::after {
                margin-right: 8px;
                margin-left: unset;
              }
            }
          }
        }
      }
    }
  }

  .search-filters {
    .select-filter {
      &__control {
        padding-left: unset;
        padding-right: 11px;
      }
    }
  }

  .search-filter-button {
    &__button {
      text-align: right;
      padding: 12px 13px 0 5px;
    }
    &__label {
      letter-spacing: 0px;
      padding-left: unset;
      padding-right: 14px;
    }
    &__children {
      text-align: right;
    }
  }
  .guests {
    &__holder {
      padding-left: unset;
      padding-right: 13px;
    }
    &__buttons {
      margin-left: 5px;
      margin-right: unset;
    }
  }

  .search_page {
    &__mobile-swipeable-card {
      .card-content {
        &__listing-area {
          .content {
            &__title {
              padding-left: 15px;
              padding-right: unset;
            }
            &__meta {
              .meta {
                &__item {
                  &::after {
                    margin-right: 7px;
                    margin-left: unset;
                  }
                }
              }
            }
          }
        }
      }
    }
    &__listing-card {
      .listing__bottom {
        .properties {
          .property {
            padding: 0 6px 0 10px;
            &::after {
              right: unset;
              left: 0;
            }
          }
        }
      }

      .listing {
        &__availability {
          padding-right: 18px;
          padding-left: unset;
          .icon-holder {
            margin-left: 5px;
            margin-right: unset;
          }
        }
      }
    }
    &__filters {
      .filters-button {
        .section {
          text-align: right;
        }
        .right {
          .chevron {
            padding-left: unset;
            margin-left: unset;
            padding-right: 11px;
            margin-right: 14px;
            border-left: none;
            border-right: 1px solid rgba(0, 0, 0, 0.1);
          }
        }
      }
    }
  }
  .search-map-markers {
    &__floating-cluster {
      .floating-cluster-slider {
        &__button {
          &.button-prev {
            padding-left: 2px;
            right: unset;
            left: 7px;
          }
          &.button-next {
            padding-left: 2px;
            right: unset;
            left: 50px;
          }
        }
      }

      .cluster-title {
        padding-right: 15px;
        padding-left: 0;
      }
    }
  }

  .footer {
    h4,
    .h4 {
      letter-spacing: 0px;
    }
  }

  .featured-stays {
    &__header-wrapper {
      .slider-buttons {
        .icon {
          transform: rotate(180deg);
        }
      }
    }
    &__slider .slider__wrapper {
      padding-right: calc(25px - 3px);
      padding-left: 0;
    }
  }

  .DayPicker {
    .CalendarMonth_table {
      .CalendarDay {
        &__selected_start {
          .custom_calendar_day {
            &::after {
              left: 0;
              right: unset;
            }
          }
        }
        &__lastDayOfWeek {
          .custom_calendar_day {
            border-radius: 8px 0 0 8px;

            .day {
              .los {
                left: 0;
                right: unset;
              }
            }
          }
        }
        &__firstDayOfWeek {
          .custom_calendar_day {
            border-radius: 0 8px 8px 0;
            .day {
              .los {
                right: 0;
                left: unset;
              }
            }
          }
        }
        &__hovered_span {
          &:hover {
            .custom_calendar_day {
              &::after {
                right: 0;
                left: unset;
              }
            }
          }
        }
      }
    }
  }

  .custom_datepicker {
    &__flexible {
      .flexible {
        &__months {
          .button {
            &__month {
              &.selected {
                .button {
                  &__icon {
                    left: 20px;
                    right: unset;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .suite {
    &__section {
      .section {
        &__title {
          padding-left: 40px;
          padding-right: unset;
          &__icon {
            margin-left: 10px;
            margin-right: unset;
          }
          &__subtitle {
            direction: ltr;
            text-align: right;
          }
        }

        &__content {
          padding-left: 10px;
          padding-right: unset;
        }
      }
    }

    &__neighbourhood {
      .neighbourhood {
        &__content {
          .content {
            &__map {
              .neighbourhood {
                &__marker {
                  .marker {
                    &__marker {
                      transform: translateX(50%);
                    }
                    &__house {
                      top: -66px;
                      transform: translateX(50%);
                    }
                  }
                }
              }
            }
            &__building-amenities {
              .amenities {
                .amenity-item {
                  &__content {
                    padding-left: 10px;
                    padding-right: unset;
                  }

                  &__icon {
                    margin-right: unset;
                    margin-left: 12px;
                  }
                }
              }
            }
          }
        }
      }
    }

    &__our-place-offers {
      .our-place-offers {
        &__content {
          .content {
            &__items {
              .unit-offers-item {
                &__content {
                  padding-left: 10px;
                  padding-right: unset;
                }
                &__icon {
                  margin-left: 12px;
                  margin-right: unset;
                }
              }
            }
          }
        }
      }
    }

    &__cancellation-policy {
      .policy {
        &__refunds {
          .refund {
            &::after {
              right: 7px;
              left: unset;
            }
            &__date {
              margin-right: 30px;
              margin-left: unset;
            }
            &__amount {
              margin-right: 30px;
              margin-left: unset;
            }
          }
        }
      }
    }

    &__book-modal {
      .book-modal {
        &__deposit-info {
          .deposit-info {
            &__description {
              margin-right: 10px;
              margin-left: unset;
            }
          }
        }
      }
    }

    &__house-rules {
      .house-rules-item {
        &__icon {
          margin-left: 12px;
          margin-right: unset;
        }
      }
    }

    &__question-box {
      .buttons {
        padding-right: 32px;
        padding-left: unset;
        &__email {
          margin-right: 20px;
          margin-left: unset;
        }
      }
    }

    &__scroll-gallery {
      .gallery {
        &__top {
          .close-button {
            margin-right: 5px;
            margin-left: unset;
          }
        }
      }
    }
    &__full-screen-gallery {
      .full-screen-gallery {
        &__counter {
          left: 10px;
          right: unset;
        }
        &__top {
          .close-button {
            margin-right: 5px;
            margin-left: unset;
          }
        }
      }
    }
  }

  .auth-form {
    &__social-methods-divider {
      .divide {
        &:last-of-type {
          float: left;
        }
      }
    }
  }

  .floating-label {
    &__label:not([dir~="ltr"]) {
      right: 15px;
      left: unset;
      line-height: 120%;
    }

    select ~ span {
      left: 18px;
      right: unset;
    }
    .show-password {
      left: 10px;
      right: unset;
    }

    &__text-input:not([dir~="ltr"]) {
      text-align: right;
    }
    .error-text {
      direction: rtl;
    }
  }

  .confirm-and-pay {
    &__breadcrumb {
      .go-back-button {
        .icon {
          transform: rotate(180deg);
        }
        span {
          margin-left: unset;
          padding-right: unset;
          margin-right: 8px;
          padding-left: 8px;
        }
      }
    }
    &__suite {
      .info {
        &__dates {
          .dates {
            &__arrow {
              transform: rotate(180deg);
            }
          }
        }
      }

      .header {
        &__location {
          direction: ltr;
          text-align: right;
        }
      }
    }
  }

  .booking-confirmation {
    .journey-area {
      .step {
        &__indicator {
          margin-left: 10px;
          margin-right: -5px;
        }
      }
    }
    .listing-details {
      &__property {
        .content-item {
          .date-arrow {
            transform: rotate(180deg);
          }
          .address {
            direction: ltr;
            text-align: right;
          }
        }
      }
    }
  }

  .request-to-delete {
    &__content {
      .content {
        &__button {
          .icon {
            transform: rotate(180deg);
          }
        }
      }
    }
  }

  .help-center {
    .section {
      .questions {
        .question {
          &__question {
            text-align: right;
            .icon {
              margin-right: 10px;
              margin-left: unset;
            }
          }

          &__answer {
            text-align: right;
            padding: 7px 0 10px 15px;
          }
        }
      }
    }
    &__search {
      .search {
        &-icon {
          right: 12px;
          left: unset;
        }
      }
    }
  }
  .floating-modal {
    .close-button {
      left: calc(28px - 12px);
      right: unset;
    }
  }
  .suite {
    &__section {
      .section {
        &__content {
          .select-dates {
            .text-button {
              svg {
                transform: rotate(180deg);
              }
            }
          }
        }
      }
    }
    &__book-modal {
      .book-modal {
        &__continue {
          .continue-tooltip {
            right: 50%;
            transform: translateX(50%);
            left: 0;
          }
        }
      }
    }
  }

  @media (min-width: 576px) {
    .topbar {
      &__holder {
        left: 8px;
        right: unset;
      }
    }
  }

  // ≥768px
  @include media-breakpoint-up(md) {
    .homepage {
      &__app-showcase {
        .app-showcase {
          &__container {
            padding-left: 265px !important;
            padding-right: unset !important;
          }
          .article {
            padding-right: unset;
          }

          // App showcase
          &__image-holder {
            right: unset;
            left: 20px;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }

      &__gallery {
        .gallery {
          .gallery-item {
            margin-right: 0;
            margin-left: 8px;
          }
        }
      }
    }

    .topbar {
      &__dropdown {
        .dropdown {
          &__content {
            left: -5px;
            right: unset;
          }
          &__button {
            margin-right: 8px;
            margin-left: unset;
          }
        }
      }
    }
    .custom_datepicker {
      left: 0;
      right: unset;
    }

    .search_page {
      &__listings {
        .listings {
          .alternative-title-holder {
            .scroll-buttons {
              .scroll-button {
                &__left {
                  .icon {
                    transform: rotate(180deg);
                  }
                }
                &__right {
                  .icon {
                    transform: rotate(180deg);
                  }
                }
              }
            }
          }
        }
      }

      &__filters {
        margin-right: 140px;
        right: 0;
        left: unset;
        height: 67px;
        width: auto;
        .filters-button--basic {
          width: auto !important;
        }
      }
    }

    .suite {
      &__about {
        .about {
          &__meta {
            .meta {
              &__item {
                .meta-icon {
                  margin-left: 5px;
                  margin-right: unset;
                }
              }
            }
          }
        }
      }

      &__section {
        .section {
          &__title {
            &__icon {
              left: unset;
              right: 0;
              transform: translate3d(46px, 4px, 0);
            }

            &__subtitle {
              direction: ltr;
              text-align: right;
            }
          }
          &__content {
            padding-left: 40px;
            padding-right: unset;
          }
        }
      }

      &__things-to-note {
        &::after {
          left: unset;
          right: -50px;
        }
      }

      &__house-rules {
        .house-rules-item {
          &__icon {
            margin-right: unset;
            margin-left: 12px;
            transform: translateX(40px);
          }
        }
      }

      &__question-box {
        .buttons {
          &__email {
            margin-right: 20px;
            margin-left: unset;
          }
          span {
            margin-right: 5px;
            margin-left: unset;
          }
        }

        &::after {
          left: unset;
          right: -50px;
        }
      }

      &__cancellation-policy {
        .policy {
          &__refunds {
            .refund {
              &__date {
                margin-right: 30px;
                margin-left: unset;
              }
            }
          }
        }
        .cancellation-policy {
          &__content {
            .policy {
              &__text {
                padding-left: 30px;
                padding-right: unset;
              }
            }
          }
        }
      }
    }

    .floating-modal {
      .auth-floating-container {
        .close-button {
          right: unset;
          left: 30px;
        }
      }
    }
  }

  // ≥992px
  @include media-breakpoint-up(lg) {
    .homepage {
      &__input-area {
        .input-area {
          .holder {
            &__dates {
              // Homepage Calendar Date Picker
              .custom_datepicker {
                margin-top: 20px;
                left: 0;
                right: 50%;
                transform: translateX(50%);
              }
            }
            &::after {
              left: 10px;
              right: unset;
            }

            &__guests .guests__buttons {
              margin-right: 10px;
              margin-left: 10px;
            }
          }
        }
      }

      // App showcase
      &__app-showcase {
        .app-showcase {
          &__image-holder {
            left: 15px;
            top: -58px;
            right: unset;
            transform: unset;
          }
        }
      }

      &__gallery {
        .gallery {
          .gallery-item {
            margin-right: 0;
            margin-left: 10px;
          }
        }
      }
    }
    .search-filter-button {
      &__button {
        text-align: right;
      }
      &__label {
        font-weight: 500;
        letter-spacing: 0px;
      }
    }

    .search_page {
      .listing-area {
        &__container {
          margin-right: unset;
          margin-left: -25px;
        }
      }
      &__map-area {
        left: -10px;
        right: unset;
      }

      &__filters-area {
        .filters {
          .big-input {
            &__children {
              padding-left: 0px;
              padding-right: 25px;
            }
            &__label {
              padding-left: 0px;
              padding-right: 25px;
            }
            &__button {
              padding-left: 0px;
              padding-right: 25px;
            }
          }

          &__search-button {
            margin-left: 0;
            margin-right: 40px;
          }
        }
      }
      &__filters {
        margin-left: unset;
        margin-right: 180px;
        right: 0;
        left: unset;
        .filters-button {
          min-width: 320px;
        }
      }
    }
    .google_maps {
      &__auto-pan {
        .auto-pan-button {
          padding: 5px 5px 5px 16px;
          .icon_holder {
            margin-left: 10px;
            margin-right: unset;
          }
        }
      }
    }

    .custom_datepicker {
      .custom-nav {
        &.nav-right {
          right: unset;
          left: 28px;
          svg {
            transform: rotate(180deg);
          }
        }
        &.nav-left {
          right: 28px;
          left: unset;
          svg {
            transform: rotate(180deg);
          }
        }
      }
    }

    .suite {
      &__section {
        .section {
          &__title {
            &--large {
              .section {
                &__title {
                  &__icon {
                    transform: translate3d(46px, 2px, 0);
                  }
                }
              }
            }
            &__icon {
              transform: translate3d(46px, 4px, 0);
            }
          }
        }
      }
      &__book-area {
        .book-area {
          &__left {
            margin-left: 50px;
            margin-right: unset;
          }
        }
      }

      &__cancellation-policy {
        .cancellation-policy {
          &__content {
            .policy {
              &__text {
                padding-left: 80px;
                padding-right: unset;
              }

              &__refunds {
                .refund {
                  &__amount {
                    margin-right: 80px;
                    margin-left: unset;
                  }
                }
              }
            }
          }
        }
      }

      &__question-box {
        .background {
          right: unset;
          left: 30px;
        }
      }

      &__floating-topbar {
        .right-buttons {
          .book-modal-button {
            .book-button-left {
              text-align: left;
              margin-left: 15px;
              margin-right: unset;
            }
          }
          .contact-button {
            &--shown {
              margin-right: 15px;
              margin-left: unset;
            }
          }
        }
      }

      &__neighbourhood {
        .neighbourhood {
          &__content {
            .content {
              &__text {
                padding-left: 45px;
                padding-right: unset;
              }
            }
          }
        }
      }
    }

    .about-us {
      .our-values {
        .value-title {
          margin-left: 40px;
          margin-right: unset;
        }
      }
      .our-story-image {
        margin-right: 90px;
        margin-left: unset;
      }
    }
  }

  // ≥1200px
  @include media-breakpoint-up(xl) {
    .homepage {
      &__gallery {
        .gallery {
          .gallery-item {
            margin-right: 0;
            margin-left: 15px;
          }
        }
      }
    }

    .search-map-markers {
      &__cluster-marker,
      &__listing-marker {
        transform: translate3d(50%, -50%, 0);
        padding: 7px 15px 5px 15px;
        .cluster-label {
          margin-left: 0;
          margin-right: 4px;
        }
      }
    }

    .search_page {
      &__listings {
        padding: 0 10px 15px 20px;
      }
      &__filters {
        margin-left: unset;
        margin-right: 128px;
        right: 0;
        left: unset;
      }
    }

    .suite {
      &__our-place-offers {
        .our-place-offers {
          &__content {
            .content {
              &__items {
                .unit-offers-item {
                  &__content {
                    padding-left: 30px;
                    padding-right: unset;
                  }
                  &__icon {
                    margin-left: 15px;
                    margin-right: unset;
                  }
                }
              }
            }
          }
        }
      }

      &__neighbourhood {
        .neighbourhood {
          &__content {
            .content {
              &__text {
                padding-left: 65px;
                padding-right: unset;
              }
            }
          }
        }
      }
    }

    .about-us {
      .our-values {
        .value:nth-of-type(1),
        .value:nth-of-type(3) {
          padding-left: 70px;
          padding-right: unset;
        }
      }
    }
  }

  // ≥1800px
  @media (min-width: 1800px) {
    .search_page {
      &__filters {
        display: flex;
        justify-content: center;
        left: 0;
        right: -154px;
        margin: 0 auto;
        max-width: max-content;
      }
    }
  }
}
