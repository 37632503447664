/* modal-form-general */
.modal-form {
  font-size: 13px;
  font-weight: 500;
  color: $light-text;
  margin: 0 auto;
  padding-top: 25px;
  padding-bottom: 25px;
  min-height: 110vh;

  &.remove-padding {
    padding: unset;
    overflow-y: auto;
  }

  .title {
    margin: 0px 0px $container-padding 0;
    text-align: center;
  }

  .mutation-error {
    margin-top: -15px;
    text-align: center;
    font-family: $text-font;
    font-size: 12px;
    font-weight: 500;
    color: $error-info;
    margin-bottom: 20px;
  }

  /* login form */
  &.login-form {
    .error-text {
      position: absolute;
      top: 106%;
      left: 0;
    }

    .sign-up,
    .reset-password,
    .forgot-password {
      margin-top: 3px;
      margin-bottom: 7px;
    }

    .reset-password-text,
    .forgot-password-text {
      font-size: 14px;
      text-align: center;
    }

    .login-button,
    .reset-password-button,
    .forgot-password-button {
      button {
        height: 55px;
      }
    }

    .forgot-password-button {
      padding-top: 100px;
    }
  }

  /* sign up form */
  &.sign-up-form {
    button {
      min-height: 55px;
    }
  }
  &.sign-up-with-email-form {
    .info {
      font-weight: 400;
      margin: 0;
      margin-top: -7px;
      margin-bottom: 20px;
      font-size: 11px;

      a {
        font-weight: 500;
        text-decoration: underline;
      }
    }

    .birthday-error {
      font-size: 13px;
      margin-bottom: 25px;
      color: #c65760;
      font-weight: 600;
      font-family: $primary-font;
      margin-left: 0;
    }
  }
}

@media (min-width: 768px) {
  .modal-form {
    background: $white;
    max-width: 450px;
    margin-bottom: 30px;
    padding: 25px 20px;
    min-height: unset;
    border-radius: $border-radius * 2;
    box-shadow: 0px 10px 20px 10px rgba($color: $primary, $alpha: 0.08);

    input {
      background-color: rgba($color: $body-bg, $alpha: 0.7);
    }
  }
}

@media (min-width: 992px) {
  .modal-form {
  }
}

@media (min-width: 1200px) {
  .modal-form {
  }
}
