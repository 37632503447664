// Block: guest-card
@import "src/variables";
@import "src/mixin";
$card-padding: 24px;

.guest-card {
  background-color: $white !important;
  border-radius: 16px;
  border: 1px solid #d9e5e9;
  margin: 20px 0;
  box-shadow: 0px 4px 4px 0px rgba(35, 76, 91, 0.1);
  &--error {
    border: 2px solid #dc3545;
  }
  &__title {
    border-radius: 16px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $white;
    border: none;
    font-family: $primary-font;
    font-size: 16px;
    font-weight: 600;
    color: $black;
    touch-action: manipulation;
    padding: 0 $card-padding;
    &:focus {
      outline: none;
    }
  }

  &__content-holder {
    display: block;
    position: relative;
    height: 0px;
    overflow-y: hidden;
    transition: height 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
    will-change: contents;
  }

  &__content {
    font-size: 13px;
    white-space: pre-wrap;
    position: absolute;
    height: max-height;
    font-size: 13px;
    cursor: default;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    // gap: 0px;
    padding: 10px;
    padding-top: 8px;
    // padding-bottom: 24px;
  }
}

.file-input {
  &__label {
    height: 55px !important;
    border: 1px solid #e0e0e0;
    border-radius: 6px;
    color: #828282;
    font-size: 16px;
    cursor: pointer;
    @include media-query(lg, max) {
      font-size: 14px;
    }
    @include media-query(md, max) {
      font-size: 12px;
    }
    &--error-border {
      border: 1px solid #dc3545 !important;
    }
    &--error-color {
      color: #dc3545 !important;
    }
  }

  &__button {
    width: 100px;
    text-align: center;
    background-color: #f6f8f9;
    border: 1px solid #d9e5e9;
    color: #828282;
    padding: 6px 12px;
    border-radius: 24px;
    cursor: pointer;
  }
}
