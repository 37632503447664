.search_page {
  background: $colored-bg;
  position: relative;
  height: 100%;
  width: 100%;
  $listing-border-radius: 16px;
  $listing-margin: 16px;
  $slider-height: 240px;
  $listing-content-height: 100px;
  $listing-card-total-height: calc(
    #{$slider-height} + #{$listing-content-height}
  );

  &__listings {
    position: relative;
    width: 100%;

    .listings {
      min-height: 100vh;

      .skeleton-loader {
        height: $listing-card-total-height;
        border-radius: $listing-border-radius;
        margin-top: $listing-margin;
      }

      .horizontal-listings-holder {
        overflow-x: hidden;
        padding: 0;
      }

      .horizontal-listings {
        overflow-x: scroll;
        display: flex;
        scroll-snap-type: x mandatory;
        padding-left: 0px;
        padding-right: 0px;
      }

      .alternative-title-holder {
        .scroll-buttons {
          display: none;
        }
      }

      .alternative-col {
        padding-left: 7px;
        padding-right: 7px;
        scroll-snap-align: center;
        width: 100%;
        max-width: 90vw;

        &:first-of-type {
          margin-left: 8px;
        }
        &:last-of-type {
          margin-right: 8px;
        }
      }
    }

    .contact_us {
      font-family: $text-font;

      &--small {
        opacity: 0.8;
        font-size: 12px;
      }

      &--end {
        margin: 0 auto;
        max-width: 440px;
        margin-top: 25px;
        margin-bottom: 30px;
        font-size: 13px;
        text-align: center;
      }

      a {
        text-decoration: underline;
      }
    }

    .not-found-any {
      border-top: 1px solid rgba($primary-darkest, 0.1);
      border-bottom: 1px solid rgba($primary-darkest, 0.1);
      margin-top: 20px;
      padding: $container-padding 0;

      &__text {
        font-family: $text-font;
        font-size: 14px;
        margin-bottom: 25px;
      }
    }

    .listings-title-holder {
      margin-top: 35px;
    }

    .listing-count {
      font-weight: 500;
      font-family: $primary-font;
      opacity: 0.8;
      font-size: 13px;
      display: flex;

      .dot {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 5px;
        &::after {
          display: block;
          width: 3px;
          height: 3px;
          content: "";
          background: rgba(0, 0, 0, 0.3);
          border-radius: 4px;
        }
      }
    }

    .map-button {
      position: sticky;
      bottom: 0;
      padding-bottom: 36px;
      margin-bottom: -45px;
      width: 100%;
      display: flex;
      justify-content: flex-end;
      z-index: 1;

      &__button {
        border: none;
        font-size: 13px;
        margin-top: 20px;
        font-weight: 500;
        background: $dark-bg;
        border-radius: 50px;
        padding: 12px 25px;
        text-align: right;
        color: $white;
        cursor: pointer;
        touch-action: manipulation;
        box-shadow: 0px 5px 10px 2px rgba(0, 0, 0, 0.2);
        transform: scale(1);
        transition: transform 150ms cubic-bezier(0.645, 0.045, 0.355, 1);
        user-select: none;

        &:hover,
        &:focus {
          transform: scale(1.1);
        }
      }
    }
  }

  &__listing-card {
    border-radius: $listing-border-radius;
    background: white;
    margin-top: $listing-margin;
    position: relative;
    opacity: 1;
    transition: opacity 300ms cubic-bezier(0.645, 0.045, 0.355, 1);

    &.selected {
      box-shadow: 0 12px 24px 12px rgba(0, 0, 0, 0.08);
    }

    &.muted {
      opacity: 0.3;
    }

    .box-shadow {
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: $listing-border-radius;
      z-index: 2;
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.08) inset;
      pointer-events: none;
      user-select: none;
    }

    .listing {
      $padding-left: 18px;
      $padding-right: 18px;
      &__price {
        position: absolute;
        z-index: 1;
        top: 15px;
        right: 0;
        background: white;
        padding: 7px 15px 5px 15px;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        color: $black;
        font-family: $primary-font;
        font-weight: 500;
        font-size: 14px;
        box-shadow: 0 5px 10px 5px rgba(0, 0, 0, 0.05);
        cursor: text;
        user-select: text;

        span {
          font-weight: 600;
        }
      }

      &__content {
        min-height: $listing-content-height;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      &__availability {
        margin-top: 10px;
        margin-bottom: 4px;
        padding-left: $padding-left;
        display: flex;
        align-items: center;
        color: $primary-darkest;
        font-size: 14px;
        font-family: $primary-font;

        .icon-holder {
          margin-right: 5px;
          color: $primary;
        }
        .label {
          font-weight: 600;
          padding-top: 3px;
          margin-right: 5px;
        }
      }

      &__title {
        padding-left: $padding-left;
        padding-right: $padding-right;
        min-height: 45px;
        .title {
          color: black;
        }
      }

      &__bottom {
        display: flex;
        justify-content: space-between;
        padding-bottom: 5px;
        font-size: 13px;
        padding-left: $padding-left;
        padding-right: $padding-right;
        align-items: center;

        .properties {
          display: flex;
          font-family: $primary-font;
          font-size: 13px;
          font-weight: 500;
          padding-bottom: 10px;

          .property {
            padding: 0 10px 0 6px;
            cursor: text;
            user-select: text;
            color: $primary-darkest;
            position: relative;

            &::after {
              position: absolute;
              right: 0;
              top: 0;
              content: "·";
              display: block;
            }
            &:first-of-type {
              padding-left: 0;
            }

            &:last-of-type {
              &::after {
                display: none;
              }
            }
          }
        }
      }
    }
  }

  &__slider {
    border-top-left-radius: $listing-border-radius;
    border-top-right-radius: $listing-border-radius;
    background: rgba(0, 0, 0, 0.15);
    .slick-list {
      border-top-left-radius: $listing-border-radius;
      border-top-right-radius: $listing-border-radius;
    }
    .slick {
      &__slider,
      &__image {
        height: $slider-height;
      }

      &__image img {
        display: block;
        height: $slider-height;
        width: 100%;
      }
      &__image .lazy-load-image-loaded {
        width: 100%;
      }
    }
  }

  &__slider-placeholder {
    display: block;
    width: 100%;
    height: $slider-height;
  }

  &__map-area {
    $map-gap: 15px;
    $map-border-radius: 16px;

    width: 100%;
    position: fixed;
    z-index: 5;
    bottom: -100vh;
    left: 0;
    width: 100vw;
    height: 100vh;

    .map-bg {
      display: block;
      width: 100%;
      height: 100%;
      background: black;
    }

    .map-holder {
      position: fixed;
      bottom: 0;
      height: calc(100% - #{$map-gap});
      width: 100%;
      overflow: hidden;
      border-top-left-radius: $map-border-radius;
      border-top-right-radius: $map-border-radius;

      transition: border-top-left-radius 300ms
          cubic-bezier(0.645, 0.045, 0.355, 1),
        border-top-right-radius 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
    }
    .map-container,
    .mobile-map {
      position: relative;
      height: 100%;
      width: 100%;
    }
    .map-container {
      background: white;
    }

    .map-swiper {
      position: absolute;
      height: 60px;
      margin-top: -15px;
      padding-top: 30px;
      z-index: 1;
      width: 100%;
      $height: 30px;

      .handle {
        padding: 0;
        margin: 0;
        border: none;
        background: none;
        height: $height;
        width: 100%;
        display: flex;
        justify-content: center;

        .closer {
          width: 50px;
          height: 4px;
          border-radius: 3px;
          display: block;
          background: rgba(0, 0, 0, 0.5);
        }
      }
    }
  }

  &__mobile-swipeable-card {
    $swipe-threshold: 50px;
    $card-height: 550px;
    $border-radius: 16px;

    position: fixed;
    z-index: 10;
    bottom: -$swipe-threshold;
    width: 100%;
    background: white;
    height: auto;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    overflow: hidden;

    box-shadow: 0 -12px 24px 12px rgba(0, 0, 0, 0.08);

    &.hide {
      transform: translate3d(0, 100%, 0);
    }

    .top {
      position: absolute;
      top: 0;
      width: 100%;
      padding-top: 10px;
      display: flex;
      justify-content: flex-end;
      $height: 30px;
      cursor: grab;
      z-index: 1;

      &__close {
        padding: 0;
        margin: 0;
        border: none;
        background: none;
        height: $height;
        width: 100%;
        display: flex;
        justify-content: center;
        .closer {
          width: 50px;
          height: 4px;
          border-radius: 3px;
          display: block;
          background: rgba(0, 0, 0, 0.5);
        }
      }
    }

    .card-content {
      &__listing-area {
        border-top-left-radius: $border-radius;
        border-top-right-radius: $border-radius;

        .lazy-load-image-background {
          width: 100%;
        }

        .listing-carousel {
          position: relative;

          .carousel {
            overflow: hidden;
            $slider-height: 180px;

            .slick__slider {
              height: $slider-height;
            }
            .slick-list {
              background: rgba(#000, 0.15);
            }

            img {
              height: $slider-height;
              object-fit: cover;
            }
          }
        }

        .content {
          position: relative;
          bottom: $swipe-threshold;
          padding: $container-padding;
          width: 100%;
          color: black;
          margin-top: 50px;
          font-family: $primary-font;

          &__availability {
            display: flex;
            align-items: center;
            font-size: 13px;
            font-weight: 500;
            color: rgba(0, 0, 0, 0.6);

            .icon {
              color: $primary;
              width: 16px;
              height: 16px;
              margin-bottom: 3px;
              margin-right: 8px;
            }
          }

          &__title {
            font-size: 18px;
            font-weight: 600;
            padding-right: 15px;
            line-height: 21px;
            margin-top: 5px;
            margin-bottom: 5px;
          }

          &__meta {
            display: flex;
            margin-bottom: 10px;

            .meta__item {
              font-size: 12px;
              color: rgba(0, 0, 0, 0.6);
              font-weight: 500;

              &::after {
                content: "";
                display: inline-block;
                width: 3px;
                height: 3px;
                background: rgba(0, 0, 0, 0.15);
                border-radius: 3px;
                margin-top: 7px;
                margin-left: 7px;
                position: absolute;
              }

              &:last-of-type {
                &::after {
                  display: none;
                }
              }
            }
          }

          &__bottom {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-family: $primary-font;

            .bottom {
              &__price {
                padding-top: 5px;
                font-size: 15px;

                span {
                  font-weight: 600;
                }
              }

              &__anchor {
                .anchor {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  width: 100%;
                  height: 45px;
                  padding: 12px 40px 11px 40px;
                  border-radius: 50px;
                  background: $primary-darkest;
                  color: white;
                  font-weight: 600;
                  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.15);
                  text-decoration: none;
                  white-space: nowrap;

                  &:hover,
                  &:focus {
                    text-decoration: none;
                  }

                  &:focus-visible {
                    box-shadow: 0 0 0 3px white;
                  }
                }
              }
            }
          }
        }

        &.cluster-item .listing-carousel {
          .slick-list {
            border-radius: 12px 12px 0 0;
          }
        }
      }

      &__cluster-bubbles {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 35px;
        touch-action: manipulation;

        .cluster-bubbles {
          &__title {
            text-align: center;
            font-family: $primary-font;
            font-size: 14px;
            font-weight: 600;
            color: black;
          }

          &__bubbles {
            display: flex;
            overflow-x: scroll;

            .bubble {
              $image-size: 60px;
              $container-margin: 15px;
              $margin: 12px;

              padding: 0;
              background: none;
              border: none;

              display: flex;
              flex-direction: column;
              justify-content: center;
              text-align: center;
              margin: 15px $margin;
              transition: transform 80ms cubic-bezier(0.645, 0.045, 0.355, 1);

              &:focus {
                outline: none;
              }

              &:focus-visible {
                .bubble__image img {
                  box-shadow: 0 0 0 3px white, 0 0 0 5px $primary;
                }
              }

              &:first-of-type {
                margin-left: $container-margin;
              }
              &:last-of-type {
                padding-right: $container-margin;
              }

              &__image {
                display: flex;
                justify-content: center;
                width: 100%;
                img {
                  height: $image-size;
                  width: $image-size;
                  border-radius: $image-size;
                  object-fit: cover;
                }
              }

              &.selected {
                transform: scale(1.07);
                .bubble__price {
                  color: black;
                }
              }

              &__price {
                display: block;
                width: 100%;
                margin-top: 4px;
                font-family: $primary-font;
                font-size: 11px;
                font-weight: 600;
                color: #a1a1a1;
                text-align: center;

                span {
                  margin-top: -5px;
                  display: block;
                }
              }
            }
          }
        }
      }
    }
  }

  .listing_anchor {
    border-radius: $listing-border-radius;
    display: block;
    color: unset;
    text-decoration: none;
    height: 100%;

    &.disabled {
      pointer-events: none;
    }

    &:focus-visible {
      box-shadow: 0 0 0 4px $primary;
    }
  }

  .contact_us a {
    direction: ltr;
    unicode-bidi: embed;
  }
}

@include media-breakpoint-up(sm) {
  .search_page {
    &__listings {
      .listings {
        .alternative-col {
          max-width: 400px;
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .search_page {
    &__listings {
      .listings {
        .alternative-title-holder {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .alternative-title {
            margin: 0;
            padding: 0;
          }

          .scroll-buttons {
            display: flex;
            align-items: center;

            .scroll-indicator {
              font-family: $text-font;
              font-size: 14px;
              margin-right: 10px;
              font-variant-numeric: tabular-nums;
              letter-spacing: -1px;
            }

            .scroll-button {
              &__left,
              &__right {
                border: none;
                background: none;
                padding: 8px;
                margin: 0;
                margin-left: 8px;
                display: flex;
                justify-content: center;
                align-items: center;
                background: white;
                box-shadow: 0 0 0 1px rgba($primary-darkest, 0.1);
                border-radius: 50px;
                transition: all 90ms ease;

                .icon {
                  width: 20px;
                  height: 20px;
                }

                &:focus {
                  outline: none;
                }

                &:not(:disabled):not(:active):hover {
                  box-shadow: 0 0 0 1px rgba($primary-darkest, 0.1),
                    0 3px 6px 3px rgba($primary-darkest, 0.1);
                }

                &:focus-visible {
                  box-shadow: 0 0 0 1px rgba($primary-darkest, 0.1),
                    0 0 0 3px white, 0 0 0 5px $primary-darkest;
                }

                &:active {
                  transform: scale(0.95);
                  box-shadow: 0 0 0 1px rgba($primary-darkest, 0.1),
                    0 1px 2px 1px rgba($primary-darkest, 0.1);
                }
              }
            }
          }
        }

        .horizontal-listings {
          overflow: visible !important;
          scroll-snap-type: unset;
          padding-left: 0;
          padding-right: 0;
          transition: transform 0.5s ease 0s;
        }

        .alternative-col {
          scroll-snap-align: unset;
          max-width: 50%;
          padding-left: 8px;
          padding-left: 8px;

          &:first-of-type {
            margin-left: 0;
          }
          &:last-of-type {
            margin-right: 0;
          }

          .slick__image img,
          .slick__image,
          .slick__slider {
            height: 180px;
          }

          .search_page__slider-placeholder {
            display: block;
            width: 100%;
            height: 180px;
          }
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .search_page {
    .contact_us {
      font-family: $text-font;

      &--small {
        opacity: 0.8;
        font-size: 12px;
      }

      &--end {
        margin: 0 auto;
        max-width: 400px;
        margin-top: 25px;
        margin-bottom: 30px;
        font-size: 12px;
        text-align: center;
      }

      a {
        text-decoration: none;
        border-radius: 4px;
        padding: 2px;
        margin: -2px;

        &:hover,
        &:focus-visible {
          background: rgba($primary-darkest, 0.1);
          text-decoration: none;
          color: $primary-darkest;
        }

        &:focus-visible {
          text-decoration: underline;
        }
      }
    }

    .listings-row {
      margin-left: -8px;
      margin-right: -8px;
    }

    .listings-col {
      padding-left: 8px;
      padding-right: 8px;
    }

    &__map-area {
      background: none;
      transition: none;
      top: $desktop-nav-height;

      &.show {
        transition: none;
        bottom: 0px;
        display: block;
      }

      &.hide {
        display: none;
      }

      .map-bg {
        display: none;
      }

      .map-swiper {
        display: none;
      }

      .map-holder {
        position: relative;
        height: 100%;
        border-radius: 0px;
        transition: none;
        bottom: unset;

        .fake-swiper {
          display: none;
        }
        .swiper_closer {
          right: unset;
          left: 0;
        }
      }
    }
  }
}

@media (min-width: 375px) {
  .search_page {
    &__mobile-swipeable-card {
      .card-content {
        &__listing-area {
          .listing-carousel {
            .carousel {
              $slider-height: 250px;

              .slick__slider {
                height: $slider-height;
              }

              img {
                height: $slider-height;
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 1200px) {
  .search_page {
    $listing-area-width: 60%;
    $listing-area-max-width: 850px;

    $map-area-width: calc(
      100% - #{$listing-area-width} - #{$container-padding}
    );

    .listing-count {
      font-size: 14px;

      .dot {
        margin: 0 6px;
        &::after {
          height: 4px;
        }
      }
    }

    .listing-title {
      font-size: 30px;
      font-weight: 600;

      &.alternative-title {
        margin-top: 50px;
        font-size: 24px;
      }
    }

    .not-found-any {
      margin-top: 30px;
    }

    .listing-area__holder {
      padding: 0;
    }
    .listing-area__container {
      height: 100%;
      width: 100%;
      display: flex;
      margin-right: (-$container-padding);
    }

    &__filters {
      width: $listing-area-width;
      max-width: $listing-area-max-width;
    }

    &__listings {
      padding: 0 20px 15px 10px;
      display: block;
      width: $listing-area-width;
      max-width: $listing-area-max-width;
      z-index: 1;
      flex-grow: 1;
      flex-shrink: 0;

      .map-button {
        display: none;
      }
    }

    &__map-area {
      background: none;
      display: block;
      position: relative;
      width: 100%;
      left: unset;
      right: -10px;
      top: 0;
      flex-grow: 1;
      opacity: 1;
      transform: translateY(0);
      z-index: 1;
      height: 100%;
      pointer-events: all;
      margin-top: 0px;

      &.hide {
        display: block;
      }

      .map-swiper {
        display: none;
      }

      .map-holder {
        position: sticky;
        height: calc(100vh - #{$desktop-nav-height});
        top: $desktop-nav-height;
        margin-top: 0;
        transform: translate3d(0, 0, 0) !important;
        border-radius: 0;

        .fake-swiper,
        .swiper_closer {
          display: none;
        }
      }
    }
  }
}
