/* FOOTER */
.footer {
  background-color: $primary-dark-bg;

  padding: 50px 0px 30px 0px;
  color: white;

  h4 {
    color: $white;
    font-family: $text-font;
    font-size: 11px;
    color: rgba(white, 0.6);
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 2px;
  }

  .logo-holder {
    margin-bottom: 40px;
  }

  .section {
    margin-bottom: 40px;

    ul,
    ul li {
      margin: 0;
      padding: 0;
    }

    ul li {
      margin: 15px 0;
    }

    a {
      font-family: $primary-font;
      color: white;
      font-weight: 500;
      font-size: 16px;
      text-decoration: none;

      &:hover,
      &:focus,
      &:active {
        background: none;
        text-decoration: underline;
      }
    }
  }

  .badges {
    display: flex;
    align-content: center;
    gap: 10px;

    .store-badge {
      height: 40px;
    }
  }

  .logo {
    height: 46px;
  }

  .copyright {
    color: rgba(white, 0.4);
    font-family: $text-font;
    font-size: 14px;
    display: flex;
    align-content: center;

    .left {
      border-top: 1px solid rgba(white, 0.1);
      padding-top: 20px;
      margin-bottom: 20px;
    }

    .brand {
      margin-bottom: 8px;
    }

    ul,
    ul li {
      margin: 0;
      padding: 0;
    }

    ul {
      display: flex;
    }
    ul li {
      margin-right: 30px;
      position: relative;

      &:not(:last-of-type)::after {
        display: block;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate3d(18px, -50%, 0);
        content: "";
        width: 3px;
        border-radius: 10px;
        height: 3px;
        background: rgba(white, 0.2);
      }
    }
    a {
      color: rgba(white, 0.4);
      font-family: $text-font;
      font-size: 14px;
      text-decoration: underline;
    }
  }

  .social-media {
    a {
      color: $white;

      .icon {
        width: 28px;
        height: 28px;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .footer {
    padding: 80px 0px 15px 0px;

    .menu {
      margin-bottom: 30px;
    }

    .copyright {
      .left {
        margin-top: 5px;
        border-top: none;
        padding-top: 0;
        margin-bottom: 0;
      }

      a {
        text-decoration: none;
      }
    }
    .copyright-holder {
      border-top: 1px solid rgba(white, 0.1);
      padding-top: 20px;
    }
  }
}
@include media-breakpoint-up(lg) {
  .footer {
    .logo {
      margin-left: -15px;
    }

    .section {
      ul li {
        margin: 10px 0;
      }

      a {
        font-size: 15px;
        color: rgba(white, 0.8);

        &:hover,
        &:focus,
        &:active {
          text-decoration: none;
          color: white;
        }
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .footer {
    .menu {
      width: 80%;
    }
  }
}
