@import "src/_main-imports.scss";

.main {
  // background: linear-gradient(180deg, white 0%, #ffffff 100%);
}

.header {
  position: relative;
  overflow: hidden;

  img {
    width: 100%;
    height: 32vh;
    object-fit: cover;
    object-position: center center;
    @include media-breakpoint-up(sm) {
      height: 48vh;
    }
  }
  .text {
    height: 78vh;
    position: relative;
    display: inline-flex;
    padding: 0 12px;
    margin-top: -10%;

    @include media-breakpoint-up(lg) {
      background: transparent;
      margin-top: 0;
    }

    * {
      position: relative;
    }

    .textBlock {
      width: 100%;
      padding: 1.5rem;

      @include media-breakpoint-up(lg) {
        max-width: 420px;
        padding: 4rem 2rem;
        margin-left: 4%;
      }
    }

    .textBg {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: linear-gradient(180deg, #ffffff 0%, #ffffff 100%);
      border-radius: 24px;

      @include media-breakpoint-up(lg) {
        top: 0;
        right: 0;
        bottom: 0;
        left: -100vw;
        background: rgba(255, 255, 255, 0.8);
      }

      @include media-breakpoint-down(lg) {
        &.alternateBG {
          background: linear-gradient(
            180deg,
            #ffffff 0%,
            $light-background 100%
          );
        }
      }
    }

    h1 {
      color: $primary-text;
      line-height: 100%;
      font-weight: 700;
      position: relative;
      font-size: 64px;
      text-align: left;
    }
    h2 {
      color: $gray-text;
      font-weight: 600;
      position: relative;
      text-align: left;
      max-width: 320px;
      text-shadow: 0 0 28px white;
    }

    @include media-breakpoint-down(lg) {
      height: auto;
      border-radius: 24px;
      h1 {
        font-size: 36px;
        text-align: left;
        line-height: 42px;
        color: $black;
        text-shadow: none;
        width: 100%;
        color: $primary-text;
      }
      h2 {
        font-size: 16px;
        font-weight: 500;
        text-align: left;
        color: $body-text-color;
        text-shadow: none;
        color: $gray-text;
        margin: 0;
      }
    }
  }

  .imgOverlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    border-radius: 25px;
    opacity: 0;

    @include media-breakpoint-up(lg) {
      border-radius: 0;
      padding: 12px 25px 0 25px;

      background: linear-gradient(
          90deg,
          rgba(0, 0, 0, 0.2) 0%,
          rgba(0, 0, 0, 0) 50%
        ),
        linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 50%);
    }
  }

  @include media-breakpoint-up(lg) {
    img {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      height: 100%;
      border-radius: 0 0 25px 25px;
    }
  }
}

html[dir="rtl"] {
  .header {
    .text {
      .textBlock {
        @include media-breakpoint-up(lg) {
          margin-right: 4%;
          margin-left: 0;
        }
      }

      .textBg {
        right: 0;
        left: 0;
        @include media-breakpoint-up(lg) {
          right: -100vw;
          left: 0;
        }
      }

      h1 {
        margin-bottom: 1rem;
        line-height: 120%;
        @include media-breakpoint-up(lg) {
          margin-bottom: 2rem;
        }
      }

      h1,
      h2 {
        text-align: right;
      }
    }

    img,
    .imgOverlay {
      transform: scaleX(-1);
    }
  }
}
