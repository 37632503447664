.suite {
  &__sleeping-arrangements {
    .sleeping-arrangements {
      &__content {
        .sleeping {
          &__room {
            margin-bottom: 15px;
            display: inline-block;
            width: 50%;
            .room {
              &__index {
                font-family: $primary-font;
                font-weight: 500;
                font-size: 14px;
                color: rgba($body-text-color, 0.6);
              }
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .suite {
    &__sleeping-arrangements {
      .sleeping-arrangements {
        &__content {
          .sleeping {
            &__room {
              width: 33%;
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .suite {
    &__sleeping-arrangements {
      .sleeping-arrangements {
        &__content {
          .sleeping {
            &__room {
              width: 20%;
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .suite {
  }
}
