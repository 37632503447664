/* IMAGE CARD */

.image-card {
  position: relative;
  border-radius: $border-radius * 2;
  overflow: hidden;
  margin: 15px 0 0 0;
  opacity: 0;
  transition: opacity 0.2s ease-in-out 0.2s, box-shadow 0.2s ease;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.08);

  .title {
    font-family: $primary-font;
    font-weight: 600;
    font-size: 15px;
    color: $light-text;
    padding: 10px $container-padding;
    background: white;
    line-height: 20px;
    transition: color 0.2s ease-in-out;
  }

  img {
    width: 100%;
    height: 100px;
    object-fit: cover;
  }

  &:hover {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.08),
      0 5px 10px 5px rgba($primary-darkest, 0.08);

    .title {
      color: $dark-text;
    }
  }
}

.image-card.loaded {
  opacity: 1;
}

@media (min-width: 768px) {
  .image-card {
    margin: 25px 0 0 0;

    .title {
      font-size: 16px;
      height: auto;
    }

    img {
      width: 100%;
      height: 120px;
      object-fit: cover;
    }
  }
}

@media (min-width: 992px) {
}

@media (min-width: 1200px) {
}
