.cookie-consent {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 9999;

  width: 100vw;
  padding: 10px;

  text-align: center;
  display: flex;
  justify-content: center;

  &__content {
    max-width: 500px;
    width: 100%;
    background: rgba(255, 255, 255, 0.92);
    color: black;
    backdrop-filter: saturate(180%) blur(25px);
    padding: 25px;
    border-radius: 20px;
    box-shadow: 0 0 0 1px rgba($primary-darkest, 0.08),
      0 3px 6px 3px rgba($primary-darkest, 0.08);
  }
  &__title {
    font-family: $text-font;
    font-weight: 500;
    margin-bottom: 10px;
  }

  &__legal-text {
    font-size: 12px;
    line-height: 15px;
    opacity: 0.55;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  }

  &__buttons {
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;

    button {
      margin: 2px;
      border: none;
      background: none;
      width: max-content;
      font-weight: 500;
      font-size: 14px;

      &.accept {
        padding: 12px 40px;
        color: white;
        border-radius: 50px;
        background: $primary-darkest;
      }

      &.decline {
        margin-top: 5px;
        opacity: 0.7;
      }
    }
  }
}

@media (min-width: 992px) {
  .cookie-consent {
    justify-content: flex-end;

    &__buttons {
      flex-direction: row;
      justify-content: center;

      button {
        &.decline {
          margin-top: 0;
          margin-left: 5px;
        }
      }
    }
  }
}
