.blog-page {
  position: relative;

  &__header {
    position: relative;
    margin-top: -$mobile-nav-height;
    width: 100%;
    height: 60vh;

    .header {
      position: relative;
      width: inherit;
      height: inherit;

      .relative {
        position: relative;
      }

      &__image {
        width: inherit;
        height: inherit;
        position: relative;

        &::before {
          pointer-events: none;
          position: absolute;
          content: "";

          z-index: 1;
          display: block;
          height: 100%;
          width: 100%;

          background: rgba(0, 0, 0, 0.3);
          background: linear-gradient(
            180deg,
            rgba(black, 0) 0%,
            rgba(black, 0.45) 80%
          );
        }

        .image {
          height: inherit;
          width: inherit;
          object-fit: cover;
        }
      }

      &__title-content {
        z-index: 1;
        padding: 0 $container-padding;
        position: absolute;
        bottom: 25px;
        width: 100%;

        * {
          color: white;
        }

        .title {
          font-size: 30px;
        }

        .category {
          font-weight: 500;
          font-size: 11px;
          letter-spacing: 1px;
          margin-bottom: 5px;
        }

        .date {
          display: block;
          margin-top: -5px;
          font-size: 13px;
          color: white;
        }

        .subtitle {
          padding-right: 20px;
          margin-top: 15px;
          font-size: 14px;
          font-weight: 400;
          font-family: $text-font;
        }
      }
    }
  }

  &__image-credit {
    margin-top: 5px;
    z-index: 1;
    color: rgba(103, 104, 104, 1);
    font-size: 14px;
    text-align: center;

    a {
      color: rgba(103, 104, 104, 1);
    }
  }

  &__share {
    padding-top: 0px;
    border-top: 1px solid rgba(black, 0.2);
    padding-bottom: 30px;

    .share {
      padding-top: 10px;

      &__title {
        padding-top: 10px;

        font-size: 13px;
      }

      &__items {
        display: flex;
        margin-left: -10px;

        a,
        button {
          width: 36px;
          height: 36px;
          background: none;
          border: none;
          margin: 0;
          padding: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 10px;
          border-radius: 100px;

          .icon {
            width: 18px;
            height: 18px;
            color: black;
          }
        }
      }
    }

    .copied-to-clipboard {
      $float-margin: 10px;
      position: fixed;
      bottom: 0;
      margin-bottom: $float-margin;
      width: calc(100vw - (#{$float-margin} * 2));
      left: $float-margin;
      background: rgba(black, 0.7);
      backdrop-filter: saturate(180%) blur(20px);
      box-shadow: 0 0 0 1px rgba(black, 0.05) inset,
        0 2px 4px 2px rgba(black, 0.2);
      border-radius: 20px;
      padding: 12px 15px;
      text-align: center;
      font-weight: 500;
      font-size: 16px;
      opacity: 0;
      transform: translateY(calc(100% + #{$float-margin})) scale(0.8);
      transition: all 320ms $easeInOutCubic, opacity 200ms ease 120ms;

      &--copied {
        opacity: 1;
        transform: translateY(0) scale(1);
        color: white !important;
      }
    }

    &--title-area {
      border-top: 1px solid rgba(white, 0.2);
      padding-bottom: 0;
      padding-top: 5px;
      .share {
        &__title {
          display: none;
        }
        &__items {
          padding-top: 10px;
          margin-left: 0;
          a,
          button {
            width: auto;
            height: auto;
            margin-right: 20px;
            box-shadow: none;
            .icon {
              width: 17px;
              height: 17px;
              color: rgba(white, 0.8);
            }
          }
        }
      }
    }
  }

  &__article {
    padding: 30px 0;
    white-space: pre-line;
  }

  &__media {
    margin-left: (-$container-padding);
    width: calc(100% + (#{$container-padding} * 2));
    .fluid-media {
      position: relative;
      padding-bottom: 56.25%; /* proportion value to aspect ratio 16:9 (9 / 16 = 0.5625 or 56.25%) */
      height: 0;
      overflow: hidden;
      margin-bottom: 20px;
    }

    .fluid-media iframe,
    .fluid-media img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &__article-meta {
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    padding-top: 30px;
    padding-bottom: 15px;

    .meta {
      margin-bottom: 15px;
      &-label {
        font-size: 11px;
        opacity: 0.8;
        margin-bottom: -2px;
      }
      .source-link {
        color: black;
      }
    }
  }
}

@include media-breakpoint-up(sm) {
  .blog-page {
    &.container {
      padding: 0;
    }

    &__media {
      margin-left: 0;
      width: 100%;

      .fluid-media {
        position: relative;
        padding-bottom: 56.25%; /* proportion value to aspect ratio 16:9 (9 / 16 = 0.5625 or 56.25%) */
        height: 0;
        overflow: hidden;
        margin-bottom: 20px;
      }

      .fluid-media iframe,
      .fluid-media img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}
@include media-breakpoint-up(md) {
  .blog-page {
    &__header {
      margin-top: -$desktop-nav-height;
      .header {
        &__title-content {
          .title {
            padding: 10px 0;
            font-size: 48px;
            line-height: 100%;
          }

          .category {
            font-size: 12px;
          }

          .date {
            font-size: 14px;
          }

          .subtitle {
            font-size: 16px;
            line-height: 160%;
          }
        }
      }
    }

    &__article {
      margin-top: 30px;
      font-size: 18px;
      line-height: 170%;
    }
    &__media {
      margin-left: 0;
      width: 100%;
    }
  }
}

@include media-breakpoint-up(lg) {
  .blog-page {
    &__header {
      height: 75vh;

      .header {
        &__title-content {
          .title {
            text-align: center;
            padding: 8px 0;
            font-size: 62px;
          }

          .category {
            text-align: center;
            font-size: 12px;
          }

          .date {
            text-align: center;
            font-size: 14px;
          }

          .subtitle {
            text-align: center;
            font-size: 16px;
            line-height: 160%;
            padding: 0 180px;
          }
        }
      }
    }

    &__article-meta {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      .meta {
        margin-bottom: 15px;
        &-label {
          font-size: 11px;
          opacity: 0.8;
          margin-bottom: -2px;
        }
        .source-link {
          color: black;
        }
      }
    }

    &__share {
      .copied-to-clipboard {
        width: 300px;
        margin-bottom: 15px;
        right: 15px;
        left: unset;
      }

      &--title-area {
        border-top: 1px solid rgba(white, 0.2);
        padding-bottom: 0px;
        padding-top: 10px;
        margin-top: 20px;
        .share {
          &__items {
            padding-top: 10px;
            justify-content: center;
            a,
            button {
              width: auto;
              height: auto;
              margin: 0 10px;
              box-shadow: none;
              .icon {
                width: 22px;
                height: 22px;
                color: rgba(white, 0.8);
              }
            }
          }
        }
      }
    }
  }
}
