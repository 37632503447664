/* guests-input */
.guests-input {
  width: 100%;
  height: 100%;
  background: $white;
  color: $dark-text;
  border-radius: $border-radius;
  display: flex;
  align-items: center;
  font-size: 15px;
  justify-content: space-between;
  height: 55px;

  font-size: 16px;
  font-family: $primary-font;
  color: $dark-text;
  font-weight: 500;

  .label {
    position: relative;
    padding-left: 20px;
    padding-top: 2px;
    padding-right: 10px;

    color: rgba($color: $dark-text, $alpha: 0.8);
  }
  .indicator-area {
    padding-right: 5px;
    display: flex;
    position: relative;

    .indicator,
    .minus,
    .plus {
      padding-top: 2px;
      z-index: 1;
      height: 45px;
      line-height: 45px;
      text-align: center;
      user-select: none;
    }

    .indicator {
      width: 35px;
    }

    .minus,
    .plus {
      width: 45px;
      cursor: pointer;
      border-radius: $border-radius;
    }

    .disabled {
      cursor: default;
      background: none;
      color: inherit;
      opacity: 0.6;
      &:active,
      &:hover {
        background: none;
        color: inherit;
      }
    }

    span {
      padding-top: 10px;
      margin: 0px 13px;
      .icon {
        color: #000000;
      }
    }
  }
}

@media (min-width: 375px) {
  .guests-input {
    .indicator-area {
      .minus,
      .plus,
      .label {
        width: 45px;
      }
    }
  }
}

@media (min-width: 768px) {
  .guests-input {
  }
}

@media (min-width: 992px) {
  .guests-input {
    height: 55px;
    padding-right: 2px;

    .minus,
    .plus {
      &:hover {
        background: rgba(183, 199, 204, 0.2);
      }
    }
  }
}

@media (min-width: 1200px) {
  .grouped-form {
    .guests-input {
      padding-right: 5px;
    }
  }
}
