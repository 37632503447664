$suite-images-height: 290px;
$suite-images-desktop-height: 410px;

.suite {
  color: $body-text-color;

  &__top-sections {
    display: flex;
    flex-direction: column-reverse;
    margin-top: -$mobile-nav-height;
  }

  &__section {
    padding: 30px 0 0 0;

    .section {
      &__title {
        padding-right: 40px;
        display: flex;
        flex-direction: row;

        &__icon {
          margin-right: 10px;
          filter: $icon-to-primary;
        }
        &__subtitle {
          margin-top: 9px;
          font-family: $text-font;
          font-size: 14px;
        }
        &__title {
          font-family: $primary-font;
          font-size: 22px;
          font-weight: 600;
          line-height: 130%;
        }

        &--large {
          flex-direction: column-reverse;

          .section__title__icon {
            display: none;
          }
          .section__title__title {
            font-size: 26px;
          }
        }
      }

      &__content {
        padding-right: 10px;
        margin-top: 20px;
      }
    }
  }

  &__section-divider {
    display: block;
    width: 100%;
    height: 1px;
    background: rgba($primary, 0.2);
  }

  // States
  &--loading {
    padding-bottom: 25px;

    .loading-top-section {
      display: flex;
      flex-direction: column-reverse;
    }

    .loading-box {
      display: block;
      width: 100%;
      height: 80px;

      &__header {
        margin-top: -$mobile-nav-height;
        height: $suite-images-height;
      }

      &__title,
      &__first-section {
        margin: 25px 0 0 0;
        border-radius: 12px;
      }

      &__title {
        height: 120px;
      }

      &__first-section {
        height: 800px;
      }

      &__book-modal {
        display: none;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .suite {
    // General
    &__top-sections {
      flex-direction: column;
      margin-top: 0;
    }

    &__header {
      margin-bottom: 20px;
    }

    &__section {
      .section {
        &__title {
          flex-direction: column;
          position: relative;

          &__icon {
            position: absolute;
            left: 0;
            transform: translate3d(-46px, 4px, 0);
          }
          &__subtitle {
            margin-top: 3px;
            font-size: 14px;
          }
          &__title {
            margin-top: 7px;
            color: black;
            font-size: 22px;
          }

          &--large {
            .section__title__icon {
              display: unset;
            }

            .section__title__title {
              font-size: 26px;
            }
          }
        }

        &__content {
          padding-right: 80px;
        }
      }
    }

    // States
    &--loading {
      .loading-top-section {
        flex-direction: column;
      }

      .loading-box {
        &__title,
        &__first-section {
          margin: 25px 0 0 0;
          border-radius: 12px;
        }

        &__title {
          height: 80px;
        }

        &__header {
          margin-left: 0;
          width: 100%;
          margin-top: 20px;
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .suite {
    &__section {
      padding: 35px 0 0 0;
      .section {
        &__title {
          &__title {
            font-size: 22px;
          }

          &__icon {
            transform: translate3d(-46px, 4px, 0);
          }

          &--large {
            .section__title__icon {
              transform: translate3d(-46px, 2px, 0);
            }
            .section__title__title {
              font-size: 32px;
            }
          }
        }
      }
    }

    &__book-area {
      display: flex;
      .book-area__left {
        margin-right: 50px;
      }
    }

    // States
    &--loading {
      .loading-bottom-section {
        display: flex;
        flex-direction: row;
        margin-top: 20px;
      }

      .loading-box {
        border-radius: 20px;
        &__header {
          border-radius: 0;
          height: $suite-images-desktop-height;
        }

        &__title {
          height: 90px;
        }

        &__book-modal {
          flex: 0 0 auto;
          width: 400px;
          display: block;
          margin-top: 25px;
          height: 200px;
        }
      }
    }
  }
}
