$slider-hover-amount: 5px;

.featured-stays {
  margin: 0 (-$container-padding);
  padding: 50px 0px;

  &__header-wrapper {
    margin: $container-padding 0;
    padding: 0 $container-padding;

    .slider-buttons {
      display: none;
    }

    .header {
      &__brand {
        font-family: $primary-font;
        font-weight: 600;
        font-size: 110%;
      }
    }
  }

  &__slider .slider {
    $gap: 3px;
    &__wrapper {
      display: flex;
      overflow-x: scroll;
      width: 100%;
      scroll-snap-type: x mandatory;
      padding-top: $slider-hover-amount;
      padding-left: calc(#{$container-padding} - #{$gap});

      &::-webkit-scrollbar {
        opacity: 0;
        width: 0px;
      }
    }
    &__property-link {
      text-decoration: none;
      &:hover {
        text-decoration: none;
      }
      &:focus {
        outline: none;
      }
      &:focus-visible {
        .property {
          &__image {
            box-shadow: 0 0 0 2px white, 0 0 0 4px $primary-darkest;
          }
          &__info .info__name {
            text-decoration: underline;
          }
        }
      }
    }

    &__property {
      display: block;
      flex: 0 0 100%;
      padding-left: $gap;
      padding-right: $gap;
      scroll-snap-align: center;
      width: 100%;
      max-width: 85vw;

      &--loading {
        display: block;
        flex: 0 0 100%;
        margin-left: $gap;
        margin-right: $gap;
        scroll-snap-align: center;
        width: 100%;
        max-width: 85vw;
        height: 240px;
        background-color: rgba(0, 0, 0, 0.05);
        border-radius: 16px;
      }

      &:last-of-type {
        .property {
          margin-right: $container-padding;
        }
      }

      .property {
        position: relative;
        transition: transform 140ms ease;

        &__image {
          width: 100%;
          height: 200px;
          object-fit: cover;
          border-radius: 18px;
        }

        &__price {
          position: absolute;
          top: 8px;
          right: 8px;
          font-family: $primary-font;
          font-size: 14px;
          color: white;
          font-weight: 500;
          background: rgba(0, 0, 0, 0.7);
          box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.3) inset;
          backdrop-filter: saturate(180%) blur(20px);
          border-radius: 30px;
          padding: 5px 16px 4px 16px;

          .price {
            font-weight: 600;
          }
        }

        &__info {
          padding: 8px 10px;

          .info {
            &__name {
              color: black;
              font-family: $primary-font;
              font-size: 16px;
              font-weight: 600;
              line-height: 20px;
            }
            &__meta {
              font-family: $text-font;
              font-size: 13px;
              color: rgba(0, 0, 0, 0.7);
              display: flex;
              align-items: center;

              .divider-dot {
                display: block;
                margin: 0 8px;
                background: rgba(0, 0, 0, 0.3);
                width: 3px;
                height: 3px;
                border-radius: 3px;
              }
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(sm) {
  .featured-stays {
    &__slider .slider {
      &__property {
        &--loading {
          max-width: 60vw;
        }
        max-width: 60vw;
        &:last-of-type {
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .featured-stays {
    &__header-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .slider-buttons {
        display: flex;
        margin-top: 40px;

        .slider-button {
          &__left,
          &__right {
            border: none;
            background: none;
            padding: 14px;
            margin: 0;
            margin-left: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: white;
            box-shadow: 0 0 0 1px rgba($primary-darkest, 0.1);
            border-radius: 50px;
            transition: all 90ms ease;

            .icon {
              width: 16px;
              height: 16px;
            }

            &:not(:disabled) {
              .icon {
                color: $primary-darkest;
              }
            }

            &:focus {
              outline: none;
            }

            &:not(:disabled):not(:active):hover {
              box-shadow: 0 0 0 1px rgba($primary-darkest, 0.1),
                0 3px 6px 3px rgba($primary-darkest, 0.1);
            }

            &:focus-visible {
              box-shadow: 0 0 0 1px rgba($primary-darkest, 0.1), 0 0 0 3px white,
                0 0 0 5px $primary-darkest;
            }

            &:active {
              transform: scale(0.95);
              box-shadow: 0 0 0 1px rgba($primary-darkest, 0.1),
                0 1px 2px 1px rgba($primary-darkest, 0.1);
            }
          }
        }
      }
    }
    &__slider {
      overflow-x: hidden;

      .slider {
        &__wrapper {
          overflow: visible !important;

          transition: transform 0.5s ease 0s;
        }
        &__property-link {
          &:hover {
            .property {
              transform: translateY(-$slider-hover-amount);
            }
          }
        }
        &__property {
          &--loading {
            max-width: 400px;
            height: 260px;
          }
          max-width: 400px;
          &:last-of-type {
            .property {
              margin-right: unset;
            }
          }
          .property {
            &__image {
              height: 240px;
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .featured-stays {
    &__slider .slider {
      &__property {
        &--loading {
          height: 240px;
        }
        .property {
          &__image {
            height: 220px;
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .featured-stays {
    &__header-wrapper {
      margin-top: 30px;
      margin-bottom: 30px;
    }
    &__slider {
      margin-bottom: 30px;
    }
  }
}
