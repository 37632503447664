@function tint($color, $percentage) {
  @return mix(white, $color, $percentage);
}
@function shade($color, $percentage) {
  @return mix(black, $color, $percentage);
}

$border-radius: 4px;
$border-radius-lg: 12px;
$border-radius-xl: 16px;
$border-radius-xxl: 20px;
/* FONTS */ /* FONTS */ /* FONTS */ /* FONTS */ /* FONTS */ /* FONTS */
$primary-font: "Gilroy", "Almarai", sans-serif;
$text-font: "Inter", "Almarai", -apple-system, BlinkMacSystemFont, "Segoe UI",
  Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif !important;

/* COLORS */ /* COLORS */ /* COLORS */ /* COLORS */ /* COLORS */ /* COLORS */
// General colors
$white: #ffffff;
$black: #000000;
$body-text-color: #222222;

$container-padding: 25px;
$half-container-padding: calc(#{$container-padding} / 2);
$third-container-padding: calc(#{$container-padding} / 3);
$two-third-container-padding: calc(#{$container-padding} / 3 * 2);

// Info colors
$error-info: rgb(224, 70, 70);
$success-info: rgb(63, 151, 66);
$warning-info: rgb(180, 142, 16);

// Danger colors
$danger-color: #e00;
$danger-color-dark: #c50000;

// Background colors
$colored-bg: #f9f9fa;
$lightest-gray: #f9f9fa;
$light-bg: #ffffff;
$hover-bg: #f0f0f3;
$dark-bg: #0f0f0f;
$primary-dark-bg: #2b4650;
// $body-bg: #f1f1f4;
$body-bg: #ffffff;

// Border
$border-color: #dee2e6;

$primary: #75969f;
$primary-btn: #576f76;
// $primary: #72a2af;
$primary-lightest: tint($primary, 48%);
$primary-lighter: tint($primary, 37%);
$primary-light: tint($primary, 26%);
$primary-dark: shade($primary, 26%);
$primary-darker: shade($primary, 37%);
$primary-darkest: shade($primary, 48%);

$loading-bg: rgba($primary, 0.2);

$light-background: #f6f8f9;
$light-background-border: #d9e5e9;

$icon-to-primary: invert(56%) sepia(24%) saturate(326%) hue-rotate(146deg)
  brightness(97%) contrast(94%);

// Text colors
$light-text: #858b8a;
$dark-text: #000000;
$darker-text: #0f0f0f;
$primary-text: #234c5b;
$gray-text: #4b5154;
$gray-text-dark: #484848;

// Skeleton loader
$skeleton_loader-color: shade(#f3f3f3, 2%);

// Anchor colors
$dark-anchor: #c8c8c8;

// Icon colors
$normal-icon-color: #75969f;

// Border colors
$light-border: rgba($primary, 0.15);
$dark-border: #575757;

/* MEASUREMENTS */ /* MEASUREMENTS */ /* MEASUREMENTS */ /* MEASUREMENTS */
// Navbar
$mobile-nav-height: 58px;
$desktop-nav-height: 67px;
$footer-small-height: 100px;

@import "Styles/_easings";
