@import "src/_main-imports.scss";

.question {
  background-color: $light-background;
  border-top: 1px solid rgba($primary, 0.25);
  //   border-radius: 16px;
  height: calc(100% - 16px);
  display: flex;
  font-size: 14px;

  .section {
    width: 100%;
    height: 100%;
    position: relative;
    padding: 25px;
    .title {
      .icon {
        filter: $icon-to-primary;
      }
    }
  }
}

html[dir="rtl"] {
  .question {
    .section {
      .content {
        padding-left: 0;
        padding-right: 32px;
        .background {
          left: 0;
          right: unset;
        }
      }
    }
  }
}
