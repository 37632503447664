.whatsapp-icon {
  position: fixed;
  bottom: 86px;
  right: 42px;
  z-index: 3;
  border-radius: 50px;
  box-shadow:rgba(0, 0, 0, 0.24) 0px 3px 8px;;
  padding: 17px;
  background-color: #576F76;

  &:dir(rtl) {
    left: 42px;
    right: unset;
  }
}