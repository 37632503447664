.slick {
  $slider-height: 200px;

  &__slider {
    height: $slider-height;
    position: relative;

    &:hover {
      .slick__navigation {
        opacity: 1;
      }
    }
  }

  &__image {
    height: $slider-height;

    img {
      display: block;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  &__dots-wrapper {
    position: absolute;
    width: 74px;
    height: 22px;
    bottom: 8px;
    right: 8px;
    overflow: hidden;
    pointer-events: none;
  }
  &__dots-content {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
  }
  &__dots-bg {
    pointer-events: none;
    position: absolute;
    display: block;
    width: inherit;
    height: inherit;
    border-radius: 60px;
    background: rgba(#000, 0.3);
    backdrop-filter: saturate(180%) blur(20px);
  }
  &__dots-holder {
    position: absolute;
    width: calc(12px * 5);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__dots {
    position: relative;
    width: 100%;
    display: flex;
    z-index: 1;
    margin: 0;
    padding: 0;
    bottom: 1px;
    transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
    pointer-events: none;
  }

  &__dot {
    padding: 0;
    border: none;
    display: inline-block;
    background: white;
    height: 7px;
    width: 7px;
    border-radius: 50px;
    margin: 0 2.5px;
    opacity: 0.65;
    transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
    pointer-events: all;
    cursor: pointer;

    &.active {
      opacity: 1;
    }
  }

  &__navigation {
    position: absolute;
    z-index: 1;
    height: 100%;
    top: 0;
    display: flex;
    align-items: center;
    margin: 0 10px;
    transition: opacity 150ms cubic-bezier(0.645, 0.045, 0.355, 1);
    opacity: 0;

    &.next {
      right: 0;

      button .icon {
        margin-left: 2px;
      }
    }

    &.prev {
      button .icon {
        margin-right: 2px;
      }
    }

    button {
      border: none;
      padding: 0;
      background: rgba(#000, 0.3);
      backdrop-filter: saturate(180%) blur(20px);
      height: 36px;
      width: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 30px;
      transform: scale(1);
      transition: transform 120ms cubic-bezier(0.645, 0.045, 0.355, 1),
        background 240ms ease;

      &:focus {
        outline: none;
        transform: scale(1.1);
      }
      &:focus-visible {
        background: rgba(#000, 0.8);
        box-shadow: 0 0 0 3px #fff;
      }
      &:hover {
        background: rgba(#000, 0.8);
      }
      &:active {
        transform: scale(1.2);
      }

      .icon {
        font-size: 16px;
        color: white;
      }
    }
  }
}
