.blog {
  &__article {
    height: 100%;
    width: 100%;
    position: relative;
    margin-bottom: 30px;

    .content {
      background: rgba(white, 0.8);
      backdrop-filter: blur(20px);
      box-shadow: 0 0 0 1px white inset;
      z-index: 1;
      padding: 20px;
      border-radius: 0 0 12px 12px;
      width: 100%;

      .category {
        font-weight: 500;
        color: #797979;
        font-size: 11px;
        letter-spacing: 1px;
        margin-bottom: 5px;
      }

      .title {
        font-family: $primary-font;
        font-size: 20px;
        font-weight: 600;
        line-height: 110%;
        color: black;
      }

      .date {
        margin-top: 5px;
        font-size: 12px;
        color: rgba($body-text-color, 0.6);
      }

      .subtitle {
        margin-top: 15px;
        font-size: 14px;
      }

      .read-more {
        display: block;
        font-size: 14px;
        padding-top: 5px;
      }
    }
    .image {
      width: 100%;
      border-radius: 12px 12px 0 0;
      overflow: hidden;
      height: 200px;
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
    a {
      color: inherit;
      text-decoration: inherit;
    }
  }

  .section__title {
    padding-bottom: 10px;
  }

  &__latest-news {
    margin-top: 10px;
    padding-top: 40px;
  }

  &__header {
    margin: 0px calc(#{$container-padding} * -1);

    .slick {
      &__slider,
      &-list,
      &-track,
      &__image {
        height: 400px;
      }

      &__dots-wrapper {
        bottom: unset;
        left: 0;
        right: 0;
        margin: 0 auto;
        top: 5px;
        transform: scale(0.8);
      }
      &__dots-bg {
        display: none;
      }
    }

    .header {
      $double-container-padding: calc(#{$container-padding} * 2);

      &__title {
        margin-left: $container-padding;
        margin-top: $container-padding;
      }
      &__article {
        padding: calc(#{$container-padding} / 2);
        height: 100%;
        position: relative;
        padding-top: 0;
        .content {
          position: absolute;
          bottom: 0;
          left: 0;
          background: rgba(white, 0.8);
          backdrop-filter: blur(20px);
          box-shadow: 0 0 0 1px white inset;
          z-index: 1;
          margin: calc(#{$container-padding});
          padding: 20px;
          border-radius: 12px;
          width: calc(100% - #{$double-container-padding}) !important;
        }
        .image {
          border-radius: 12px;
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .blog {
    &__articles {
      .blog__article {
        border-radius: 16px;
        overflow: hidden;

        &[data-index="0"] {
          height: 450px;
          &::before {
            display: block;
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            background: rgb(0, 0, 0);
            background: linear-gradient(
              180deg,
              rgba(0, 0, 0, 0.2) 0%,
              rgba(22, 30, 38, 0.8281687675070029) 100%
            );
            pointer-events: none;
            z-index: 1;
          }
          .content {
            position: absolute;
            bottom: 0;
            background: none;
            box-shadow: none;
            z-index: 2;
            backdrop-filter: blur(4px);
            padding: 30px;

            .category {
              color: white;
            }
            .title {
              font-size: 30px;
              color: white;
            }
            .subtitle {
              color: rgba(white, 0.8);
            }
            .date {
              color: rgba(white, 0.8);
            }
            .read-more {
              color: white;
            }
          }
          .image {
            height: inherit;
            border-radius: 0;
          }
        }
      }
    }

    &__header {
      .slick {
        &__slider,
        &-list,
        &-track,
        &__image {
          height: 70vh;
          min-height: 550px;
          max-height: 700px;
        }

        &__navigation {
          left: unset;
          right: unset;
          pointer-events: none;

          &.next {
            transform: translateX(100%);
          }
          button {
            margin-left: 5px;
            margin-bottom: 30px;
            background: none;
            pointer-events: all;

            .icon {
              color: black;
            }
          }
        }
      }

      .header {
        &__title {
          margin-bottom: -50px;
        }

        &__article {
          .content {
            position: absolute;
            bottom: 30px;
            margin: $container-padding;
            padding: 20px;
            padding-left: 0;
            border-radius: 12px;
            height: auto;

            min-height: 300px;
            max-height: 340px;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            padding-bottom: 30px;
            padding-top: 50px;

            width: 60% !important;

            .title {
              font-size: 32px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2; /* number of lines to show */
              -webkit-box-orient: vertical;
            }

            .subtitle {
              line-height: 165%;
            }
          }
          .image {
            width: 60%;
            float: right;
            height: 100%;
            margin-right: calc(#{$container-padding}/ 2);
            border-radius: 12px;
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .blog {
    &__header {
      .header {
        &__article {
          .image {
            width: 65%;
          }
        }
      }
    }
  }
}
