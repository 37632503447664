@import "src/_main-imports.scss";

.corporateBanner {
  position: relative;
  background: $primary-dark-bg;
  padding: 64px 0;
  overflow: hidden;
  * {
    z-index: 1;
  }

  h2,
  p {
    color: white;
    z-index: 1;
    @include media-breakpoint-down(lg) {
      text-align: center;
    }
  }

  a {
    width: 248px;
    padding: 24px 12px;
    border-radius: 8px;
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    color: $primary-darker;

    &:hover {
      color: $primary-darkest;
      box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    }
  }

  .img {
    display: none;
    pointer-events: none;
    clip-path: polygon(20% 0, 100% 0%, 100% 100%, 0 100%);
    -webkit-clip-path: polygon(20% 0, 100% 0%, 100% 100%, 0 100%);
    object-fit: cover;

    @include media-breakpoint-up(lg) {
      display: block;
      width: auto;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      height: 100%;
    }
  }
}

html[dir="rtl"] {
  .corporateBanner {
    .img {
      top: 0;
      bottom: 0;
      left: 0;
      right: unset;
      transform: scaleX(-1);
    }
  }
}
