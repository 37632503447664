.suite__cancellation-policy {
  .policy {
    &__text {
      span {
        font-weight: 500;
      }
    }
    &__refunds {
      margin-top: 30px;
      .refund {
        position: relative;
        margin-bottom: 42px;

        &__title {
          margin: 0px 0 10px 0;
          font-size: 14px;
          font-weight: 500;
        }

        &::after {
          position: absolute;
          display: block;
          content: "";
          height: calc(100% + 42px);
          width: 1px;
          top: 5px;
          left: 7px;
          background: $primary;
        }

        &__dot {
          top: 2px;
          position: absolute;
          display: block;
          width: 15px;
          height: 15px;
          border-radius: 40px;
          background: $primary;
        }

        &__date {
          margin-left: 30px;
          font-weight: 500;

          span {
            font-weight: 400;
            display: block;
            margin-top: -3px;
            font-size: 11px;
            color: rgba($body-text-color, 0.6);
          }
        }

        &__amount {
          margin-left: 30px;
          margin-top: 10px;
          .amount {
            &__price {
              font-weight: 500;
            }
            &__title {
              font-size: 13px;
            }
          }
        }

        &.half-refund {
          &::after,
          .refund__dot {
            background: #c4ccce;
          }
        }
        &.no-refunds {
          &::after {
            display: none;
          }
          .refund__dot {
            background: #ebebeb;
          }
          .refund__amount {
            margin-top: 10px;
            font-size: 14px;
            color: #5e5e5e;
          }
        }
      }
    }
  }

  .cancellation-description {
    font-size: 13px;
    margin-top: 15px;
    line-height: 16px;
    .line {
      margin-bottom: 8px;
    }
  }
}

@include media-breakpoint-up(md) {
  .suite__cancellation-policy {
    .cancellation-policy {
      &__content {
        padding-right: 0;
        display: flex;
        .policy {
          display: flex;
          width: 100%;

          &__text {
            padding-right: 30px;
          }
          &__refunds {
            margin-top: 0;
            flex: 0 0 40%;

            .refund {
              display: flex;

              &__date {
                width: 90px;
              }
              &__amount {
                margin-top: 0;
                margin-left: 50px;
              }
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .suite__cancellation-policy {
    .cancellation-policy {
      &__content {
        .policy {
          &__text {
            padding-right: 80px;
          }
          &__refunds {
            margin-top: -45px;
            flex: 0 0 50%;
            .refund {
              &__title {
                margin-bottom: 25px;
              }

              &__amount {
                margin-top: 0;
                margin-left: 80px;
              }
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .suite {
  }
}
