/* about-us */
.about-us {
  &:first-of-type {
    margin-top: -$mobile-nav-height;
  }

  .relative {
    position: relative;
  }

  .our-mission {
    position: relative;
    .header-image {
      width: 100%;
      height: 80vh;
      position: relative;
    }
    .overlay {
      display: block;
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgb(0, 0, 0);
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(22, 30, 38, 0.6) 100%
      );
    }

    .header-text {
      padding: $container-padding;
      min-height: 80vh;
      padding-top: 120px;
      padding-bottom: 24px;

      h1 {
        color: white;
        font-weight: bold;
        text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3),
          0px 40px 80px rgba(0, 0, 0, 0.3);
      }
      .description {
        color: white;
        line-height: 170%;
        text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3),
          0px 40px 80px rgba(0, 0, 0, 0.3);
      }
    }
  }

  .our-story {
    padding: $container-padding;
    white-space: pre-line;

    .title {
      font-size: 32px;
    }

    .header-text {
      .description {
        line-height: 170%;
      }
    }
  }

  .our-values {
    padding: $container-padding;
    padding-top: 50px;
    padding-bottom: 30px;
    white-space: pre-line;
    h2 {
      color: black;
      font-size: 32px;
    }

    .values {
      margin-left: (-$container-padding);
      margin-right: (-$container-padding);
      display: flex;
      overflow-x: scroll;
      scroll-snap-type: x mandatory;
    }

    .value {
      scroll-snap-align: start;
      flex: 0 0 80%;
      padding: $container-padding;
      margin-top: 30px;

      &:last-of-type {
        flex: 0 0 100%;
      }

      .description {
        line-height: 170%;
      }
    }
  }
  .our-values-mobile-image {
    margin: 0;
    width: 100%;
    height: 600px;
    object-fit: cover;
  }

  .our-personality {
    margin-top: 40px;
    padding-top: 80px;
    padding-bottom: 120px;
    padding: $container-padding;

    h2 {
      color: black;
      font-size: 32px;
    }

    .personalities {
      margin-top: 30px;

      .personality {
        margin-bottom: 40px;

        .title {
          color: $primary-darkest;
          font-family: $primary-font;
          font-weight: 600;
          font-size: 22px;
        }
        .icon {
          display: none;
          filter: $icon-to-primary;
        }
        .description {
          line-height: 170%;
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .about-us {
    &:first-of-type {
      margin-top: -$desktop-nav-height;
    }
    .our-story {
      display: flex;
      padding-top: 70px;
      padding-bottom: 0;
      align-items: flex-end;

      .header-text {
        padding-bottom: 70px;
      }
      &-image {
        flex: 0 0 40%;
        height: 600px;
        margin-left: 45px;

        img {
          border-radius: 20px 20px 0 0;
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
    }

    .our-values {
      padding-top: 80px;
      h2 {
        color: black;
        font-size: 38px;
      }

      .values {
        margin-left: 0;
        margin-right: 0;
        overflow-x: unset;
        scroll-snap-type: unset;

        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 10px;
      }

      .value {
        display: block;
        scroll-snap-align: unset;
        width: 100%;
        padding: 0;
        padding-right: 40px;
        margin-top: 30px;

        &:last-of-type {
          flex: 0 0 100%;
        }

        &-title {
          display: block;
        }

        .description {
          line-height: 170%;
        }
      }
    }

    .our-personality {
      .personalities {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 30px;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .about-us {
    .our-story {
      &-image {
        margin-left: 90px;
        flex: 0 0 50%;
      }
    }

    .our-values {
      .value {
        display: flex;
        flex-direction: column;

        &-title {
          display: block;
          width: 200px;
          margin-right: 40px;
        }
      }
    }

    .our-personality {
      .personalities {
        grid-template-columns: repeat(3, 1fr);
        .personality {
          padding: 20px;
          border-radius: 20px;
          box-shadow: 0 0 0 1px rgba($primary-darker, 0.12),
            0 1px 2px 1px rgba($primary-darker, 0.06);
        }
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .about-us {
    .our-story {
      &-image {
        flex: 0 0 60%;
      }
    }

    .our-values {
      .value {
        display: flex;
        flex-direction: row;
        padding-right: 0;

        &:nth-of-type(1),
        &:nth-of-type(3) {
          padding-right: 70px;
        }
        &-title {
          flex: 0 0 25%;
          width: auto;
        }
      }
    }
  }
}

@include media-breakpoint-up(xxl) {
  .about-us {
    .our-values {
      .value {
        display: flex;
        flex-direction: row;

        &:nth-of-type(2),
        &:nth-of-type(4) {
          margin-left: 40px;
        }
        &-title {
          flex: 0 0 25%;
        }
      }
    }
  }
}
