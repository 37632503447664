.booking-confirmation {
  p {
    font-size: 13px;
    line-height: 155%;
    color: #000000;
  }

  $card-padding: 14px;
  .shadow-card {
    margin: 7px -7px $container-padding -7px;
    background: #ffffff;
    box-shadow: 0px 5px 6px 2px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
    padding: $card-padding;
    overflow: hidden;

    &.large-shadow {
      box-shadow: 0px 5px 10px 5px rgba(0, 0, 0, 0.06);
    }
  }

  .journey-area {
    .congrats {
      .title {
        color: $primary-dark;
        font-weight: 900;
        margin: 0;
      }
      .subtitle {
        font-family: $primary-font;
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
      }
    }

    .before-check_in {
      margin: 25px 0;

      p {
        opacity: 0.6;
      }
    }

    .step {
      display: flex;

      &__indicator {
        $indicator-size: 30px;
        margin-right: 10px;
        margin-left: -5px;
        .indicator {
          &__text {
            width: $indicator-size;
            height: $indicator-size;
            border-radius: $indicator-size;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid $primary-dark;
            font-weight: 600;
            font-family: $primary-font;
            background-color: white;
            padding-top: 2px;
          }

          &__line {
            height: 100%;
            background-color: $primary;
            width: 1px;
            margin: 0 auto;
          }
        }
      }

      &__content {
        padding-top: 8px;
        padding-bottom: 10px;
      }

      &__title {
        color: $primary-darkest;
        font-family: $primary-font;
      }

      &__description {
        font-size: 12px;
        opacity: 0.7;
      }

      &__store-badges {
        margin-bottom: 10px;
        .store-badge {
          display: inline-block;
          height: 50px;
          margin-bottom: 10px;

          &.app-store {
            // margin-right: 10px;
          }
        }
      }
    }
  }

  .listing-details {
    .listing-content {
      padding: 10px $card-padding;
      border-bottom: 1px solid #eaeaea;
    }

    .data-label {
      font-family: $primary-font;
      font-size: 12px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.6);
      margin: 0;
      padding: 0;
      display: block;
    }

    .data-content {
      font-family: $primary-font;
      font-weight: 600;
    }

    &__image {
      margin-top: calc(-#{$card-padding} - 1px);

      img {
        width: 100%;
        height: 200px;
        object-fit: cover;
      }
    }

    &__title {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .title {
        &__label {
          margin: 0;
        }

        &__confirmation {
          background-color: rgba(0, 0, 0, 0.03);
          border: 1px solid rgba(0, 0, 0, 0.06);
          padding: 5px 10px 3px 20px;
          margin-right: -10px;
          border-radius: 12px;

          span {
            display: block;
            text-align: right;
            font-size: 11px;
          }

          .code {
            font-size: 15px;
            letter-spacing: 2px;
            font-family: $primary-font;
            font-weight: 600;
            margin-top: -5px;
          }
        }
      }
    }

    &__property {
      .content-item {
        margin-bottom: 10px;
        padding-right: 15px;
        span {
          display: block;
        }

        .date-arrow {
          width: 16px;
          height: 16px;
          // margin: 0 5px 3px 5px;
        }
      }
    }

    &__fees {
      .content-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 7px 0;

        .data-label {
          font-size: 14px;
          color: rgba(0, 0, 0, 0.8);
        }

        .data-content {
          font-size: 15px;
        }
      }
    }

    &__total-amount {
      display: flex;
      justify-content: space-between;
      padding: 30px $card-padding;
      margin-bottom: calc(-#{$card-padding} - 1px);
      background-color: #f9f9fa;

      h5 {
        opacity: 1;
        color: black;
        margin: 0;
      }
    }
  }

  .policies {
    .policy {
      &__title {
        padding: $card-padding;

        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: white;
        border: none;
        border-bottom: 1px solid #eaeaea;
        font-family: $primary-font;
        font-size: 16px;
        font-weight: 600;
        color: black;
        touch-action: manipulation;

        &:focus {
          outline: none;
        }

        &:focus-visible {
          box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.06) inset;
        }
      }

      &__content-holder {
        display: block;
        position: relative;
        height: 0px;
        overflow-y: hidden;
        transition: height 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
      }

      &__content {
        padding: $card-padding;
        font-size: 13px;
        white-space: pre-wrap;
        padding-bottom: 30px;
        position: absolute;
        display: block;
        height: max-height;
        font-size: 13px;
        padding: 0 $container-padding;
        padding-top: 10px;
        padding-bottom: 10px;
        cursor: default;
      }
    }
  }

  .help {
    padding-bottom: 0;
    .contact-info {
      a {
        font-weight: 500;
        direction: ltr;
        unicode-bidi: embed;
      }
    }
  }
}

@media (min-width: 576px) {
  .booking-confirmation {
    padding-top: 15px;

    $card-padding: 20px;
    .shadow-card {
      padding: $card-padding;
    }

    .listing-details {
      .listing-content {
        padding: 10px $card-padding;
      }
      &__image {
        margin-top: calc(-#{$card-padding} - 1px);
      }

      &__total-amount {
        padding: 30px $card-padding;
        margin-bottom: calc(-#{$card-padding} - 1px);
      }
    }

    .policies {
      .policy {
        &__title {
          padding: 15px $card-padding;
        }

        &__content {
          padding: $card-padding;
        }
      }
    }

    .help {
      .contact-info {
        margin-bottom: 0;
      }
    }
  }
}

@media (min-width: 992px) {
  .booking-confirmation {
    .sticky-card {
      padding-bottom: 15px;
    }
    .listing-details {
      margin: 0px !important;
    }
  }
}

@media (max-width: 992px) {
  .booking-confirmation {
    .sticky-box {
      position: relative !important;
      top: unset !important;
    }
  }
}
