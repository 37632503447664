@import "src/variables";
@import "src/mixin";
.campaign-promotion-text {
  position: relative;
  font-size: 17px;
  font-weight: 500;
  font-family: $primary-font;
  letter-spacing: 0.36px;
  color: $primary-text;
  border: 1px solid rgba(35, 76, 91, 0.6);
  border-radius: 14px;
  background: rgba(108, 156, 174, 0.1);
  padding: 24px 16px;
  margin-top: 16px;
  margin-bottom: 24px;
  @include media-query(md, max) {
    font-size: 16px;
  }
}
