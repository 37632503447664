.mobile-menu {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  z-index: 5;
  background: $body-bg;
  width: 100%;
  font-family: $primary-font;
  overflow-y: auto;
  min-width: 320px;

  .navbar-icon {
    position: absolute;
    right: 0;
    cursor: pointer;
    height: 25px;
    width: 25px;
    color: $normal-icon-color;
    margin: 20px 16px;
  }

  .content {
    width: 100%;
    margin-top: 10px;
    h5 {
      width: 100%;
      user-select: none;
      margin: 20px 0 0 10px;
    }
    .quick-links,
    .user-links {
      padding: 20px 0;

      a,
      span {
        display: block;
        text-decoration: none;
        color: $dark-text;
        font-size: 16px;
        font-weight: 600;
        padding: 10px;
        border-radius: $border-radius;

        &:active {
          background: rgba(0, 0, 0, 0.05);
        }
      }
    }

    .quick-links {
      border-top: 1px solid $border-color;
    }
  }
}

.mobile-menu.active {
  display: block;
}
.mobile-menu.inactive {
  display: none;
}

@media (min-width: 992px) {
  .mobile-menu,
  .mobile-menu.active {
    display: none;
  }
}

@media (min-width: 1200px) {
}
