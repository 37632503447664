@import "src/_main-imports.scss";

.buildingHero {
  padding: 56px 12px;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  border-radius: 16px;
  overflow: hidden;
  position: relative;
  background-position: 50% 0% !important;

  @include media-breakpoint-up(lg) {
    padding: 240px 0;
    max-height: 550px;

    .textBlock {
      transform: translateY(-120%);
    }
  }

  h2 {
    font-weight: 800;
  }
  h2,
  p {
    color: white;
    z-index: 1;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  }

  p {
    width: 100%;
    font-size: 15px;
    @include media-breakpoint-up(md) {
      width: 50%;
      font-size: 17px;
    }
    @include media-breakpoint-up(lg) {
      width: 50%;
    }
    @include media-breakpoint-up(xl) {
      width: 40%;
    }
  }

  .overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.2);
    z-index: 0;
  }
}
