@import "src/_main-imports.scss";

.block {
  background: $light-background;
  border: 1px solid $light-background-border;
  border-radius: 6px;
  .title {
    font-family: $primary-font;
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 14px;
    color: $primary-text;
    margin: 0;
  }

  .content {
    font-family: $primary-font;
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 16px;
    color: $body-text-color;
    margin: 0;
  }
}
