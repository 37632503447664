/* feedback-modal */
.feedback-modal {
  position: fixed;
  left: 0;
  margin-left: 50%;
  transform: translateX(-50%);
  bottom: -60px;
  z-index: 9999;
  width: max-content;
  min-width: 200px;
  text-align: center;
  max-width: 100vw;
  padding: 15px 30px;
  border-radius: 100px;
  background-color: $white;
  box-shadow: 0px 5px 10px 5px rgba($color: $primary, $alpha: 0.2);
  transition: bottom 0.3s ease-out;
  font-size: 14px;
  font-family: $primary-font;
  font-weight: 600;
  color: $dark-text;

  &.show {
    bottom: 20px;
    transition: bottom 0.5s ease-out;
  }
}
