.confirm-and-pay {
  min-height: 600px;

  &__suite {
    color: black;
    font-family: $primary-font;

    .header {
      position: relative;

      display: flex;
      align-items: center;

      &__listing_name {
        margin-top: 8px;
        margin-bottom: 3px;
        font-size: 16px;
        line-height: 140%;
        font-weight: 600;
      }
      &__location {
        font-family: $text-font;
        font-weight: 400;
        opacity: 0.6;
        font-size: 12px;
        line-height: 14px;
        padding-bottom: 8px;
      }

      &__image {
        $size: 100px;
        .image {
          height: 100%;
          width: $size;
          border-radius: 8px;
          object-fit: cover;
          background: rgba(0, 0, 0, 0.2);
        }
      }
    }

    .info {
      font-size: 14px;
      font-weight: 500;
      border-top: 5px solid rgba($primary-darkest, 0.12);
      margin-top: 15px;
      margin-bottom: 15px;

      &__title {
        font-size: 16px;
        margin-top: 14px;
        font-weight: 600;
      }
      &__small-title {
        font-size: 13px;
        margin-top: 8px;
        font-weight: 600;
      }

      &__data {
        font-family: $text-font;
        font-weight: 400;
        opacity: 0.7;
        font-size: 14px;
      }

      &__dates {
        .dates {
          display: flex;

          &__arrow {
            opacity: 0.4;
          }
        }
      }
    }

    .price {
      font-size: 14px;
      font-weight: 500;
      color: black;
      font-family: $primary-font;
      margin-bottom: 15px;
      margin-top: 25px;
      border-top: 1px solid rgba($primary-darkest, 0.12);

      &__title {
        font-size: 16px;
        margin-top: 14px;
        font-weight: 600;
        margin-bottom: 8px;
      }

      &__item {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .item {
          &__label {
            font-family: $text-font;
            font-weight: 400;
            opacity: 1;
            color: $gray-text;
          }
          &__price {
            font-weight: 500;
            opacity: 1;
            font-family: $text-font;
            &__positive-amount {
              color: $success-info;
            }
          }
        }
      }
    }

    .total {
      font-size: 16px;
      margin-top: 14px;
      font-weight: 600;
      margin-bottom: 40px;
      color: black;
      font-family: $primary-font;
      display: flex;
      justify-content: space-between;
      align-content: center;
      padding-bottom: 10px;
    }
  }

  &__breadcrumb {
    margin: 5px -10px;
    .go-back-button {
      background: none;
      border: none;
      margin: 0;
      padding: 8px 10px;
      margin: 0;
      display: flex;
      align-items: center;
      font-family: $primary-font;
      font-weight: 600;
      font-size: 14px;
      border-radius: 40px;
      color: $black;

      span {
        margin-top: 2px;
        margin-left: 8px;
        padding-right: 8px;
      }

      .icon {
        color: $primary-darker;
        width: 24px;
        height: 24px;
      }

      &:hover,
      &:focus,
      &:focus-visible,
      &:active {
        outline: none;
        background: rgba($primary-darkest, 0.12);
      }
    }
  }

  &__form {
    .form-title {
      margin-bottom: 30px;

      &__title {
        font-size: 17px;
      }
      &__icon {
        display: block;
        margin-bottom: 5px;
        color: $primary;
        width: 20px;
        height: 20px;
      }
      &__subtitle {
        font-size: 13px;
        font-weight: 300;
      }
    }

    .info {
      margin-top: -12px;
      font-size: 12px;
      font-weight: 400;
      font-family: $text-font;
      opacity: 0.7;
      margin-bottom: 30px;
    }
  }

  &__agreement {
    .agreement {
      display: flex;

      &__button {
        // padding-right: 10px;
        .check-button {
          min-height: unset;

          padding: 0;
          margin: 0;
          display: flex;
          justify-content: center;
          align-content: center;
          width: 22px;
          height: 22px;

          border-radius: 4px;
          box-shadow: 0 0 0 1px rgba($primary-darkest, 0.5);
          background: white;
          margin-top: 5px;

          .icon {
            width: 20px;
            height: 20px;
          }

          &--checked {
            color: white;
            background: $primary;
            box-shadow: 0 0 0 1px rgba($primary, 1);
          }
        }
      }

      &__text {
        font-family: $text-font;
        font-size: 12px;

        a {
          text-decoration: underline;
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .confirm-and-pay {
    &__agreement .agreement {
      &__button .check-button {
        width: 18px;
        height: 18px;

        .icon {
          width: 17px;
          height: 17px;
        }
      }
      &__text {
        font-size: 13px;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .confirm-and-pay {
    min-height: 800px;
    &__suite {
      // background: rgba($primary-darkest, 0.04);
      border-radius: 12px;
      box-shadow: 0 0 0 1px rgba($primary-darkest, 0.12),
        0 6px 12px 6px rgba($primary-darkest, 0.06);
      margin: -10px;
      margin-bottom: 24px;

      .header {
        flex-direction: column;

        &__right {
          padding: 8px 20px 8px 12px;
        }
        &__listing_name {
          font-size: 18px;
        }

        &__image {
          width: 100%;
          margin-right: 0;
          .image {
            height: 200px;
            width: 100%;
            object-fit: cover;
            border-radius: 8px 8px 0 0;
          }
        }
      }

      .info {
        padding: 0 20px 0 12px;
      }

      .price {
        padding: 0 20px 0 12px;
      }

      .total {
        border-bottom: none;
        padding: 0 20px 20px 12px;
      }
    }

    &__breadcrumb {
      margin-top: 30px;
      margin-bottom: 20px;
      margin-left: -18px;
      .go-back-button {
        .icon {
          width: 30px;
          height: 30px;
        }

        &:hover,
        &:focus,
        &:focus-visible,
        &:active {
          background: none;

          .icon {
            background: rgba($primary-darkest, 0.12);
            border-radius: 50px;
          }
        }
      }
    }

    &__form {
      .form-title {
        &__title {
          font-size: 30px;
        }
        &__icon {
          margin-bottom: 10px;
          width: 20px;
          height: 20px;
        }
        &__subtitle {
          font-size: 17px;
        }
      }
    }
  }
}
