.contact-us-page {
  padding-bottom: 70px;
  .contact-us-container {
    transform: translateY(-200px);
    margin-bottom: -200px;
    z-index: 1;
    padding: $third-container-padding;

    .form {
      border-radius: 20px 20px;
      padding: $two-third-container-padding;
      background: rgba(white, 0.95);
      backdrop-filter: saturate(180%) blur(20px);
      box-shadow: 0 0 0 1px white inset,
        0 1px 2px 1px rgba($primary-darkest, 0.06);
    }
  }
}

@include media-breakpoint-up(md) {
  .contact-us-page {
    .contact-us-container {
      transform: translateY(-400px);
      margin-bottom: -400px;
      .form {
        padding: $container-padding;
      }
    }
  }
}
