/* CITY LIST DROPDOWN */

.city-list-container {
  width: 100%;
  position: relative;
  display: none;
}

.city-list-container.active {
  display: block;
}

.city-list {
  position: absolute;
  width: 100%;
  z-index: 2;
  background: $light-bg;
  overflow-y: scroll;
  margin-top: 10px;
  max-height: 300px;
  padding: 10px 0px;
  border-radius: $border-radius * 2;
  box-shadow: 0px 8px 16px 8px rgba($color: $primary, $alpha: 0.15);

  div {
    width: 100%;
    text-align: left;
    padding: 15px 20px;
    background: none;
    border: none;
    &:hover:not(.disabled) {
      color: $white;
      background: $primary;
    }
  }

  div:not(.disabled) {
    cursor: pointer;
  }

  .disabled {
    user-select: none;
    opacity: 0.6;
  }
}
