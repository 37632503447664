@import "../../../fonts";
@import "../../../variables";
@import "../../../imports";

.city {
  text-decoration: none;
  display: flex;
  flex-direction: column;
  position: relative;

  .thumb {
    overflow: hidden;
    border-radius: 8px;
    transition: all 0.2s ease-out;
    height: 100%;
    &:hover {
      box-shadow: 0 1px 8px 0px rgba(0, 0, 0, 0.1);
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 40%;
      transition: all 0.4s ease-out;
      min-height: 8rem;
    }
    .overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      background: rgba(250, 250, 250, 0.8);
      mix-blend-mode: color;
      opacity: 30%;
    }
  }

  .description {
    padding: 12px;
    border: 1px solid white;
    background: rgba(255, 255, 255, 1);
    overflow: hidden;
    position: relative;
    h3 {
      color: $primary-text;
      z-index: 0;
    }

    svg {
      z-index: 0;
      min-width: 18px;
      min-height: 18px;
    }
  }
}

@include media-breakpoint-up(md) {
  .city {
    height: 14.25rem;
    .description {
      padding: 12px;
    }
  }
  .citiesSection {
    .customBorder {
      &:nth-child(1) {
        .city {
          .thumb {
            border-radius: 64px 8px 8px 8px;
          }
        }
      }
      &:nth-child(3) {
        .city {
          .thumb {
            border-radius: 8px 64px 8px 8px;
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .city {
    height: 16.25rem;
    .description {
      padding: 12px 8px;
    }
  }
}

html[dir="rtl"] {
  .city {
    .description {
      svg {
        transform: rotate(180deg);
      }
    }
  }
  @include media-breakpoint-up(md) {
    .citiesSection {
      .customBorder {
        &:nth-child(1) {
          .city {
            .thumb {
              border-radius: 8px 64px 8px 8px;
            }
          }
        }
        &:nth-child(3) {
          .city {
            .thumb {
              border-radius: 64px 8px 8px 8px;
            }
          }
        }
      }
    }
  }
}
