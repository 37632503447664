@import "src/_main-imports.scss";

.article {
  margin-bottom: 12px;
  img {
    height: auto;
    max-width: 220px;
    max-height: 32px;
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
  }
}

