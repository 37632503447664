.suite {
  &__neighbourhood {
    .neighbourhood {
      &__content {
        padding-right: 0;

        .content {
          &__title {
            font-family: $primary-font;
            font-weight: 500;
            font-size: 18px;
            margin-bottom: 0px;
          }

          &__subtitle {
            margin-top: 5px;
            margin-bottom: 15px;
            line-height: 165%;
          }

          &__location {
            font-size: 14px;
            color: rgba($body-text-color, 0.6);
          }

          &__building-amenities {
            padding-top: 15px;

            .content__subtitle {
              margin-bottom: 15px;
              margin-top: -5px;
            }

            .amenities {
              .amenity-item {
                display: inline-block;
                width: 50%;
                margin-bottom: 15px;

                &__content {
                  display: flex;
                  align-items: center;
                  padding-right: 10px;
                }

                &__icon {
                  margin-right: 12px;
                }
              }
            }
          }

          &__map {
            display: block;
            margin-top: 25px;
            height: 370px;
            width: 100%;
            border-radius: $border-radius * 2;
            overflow: hidden;
            position: relative;

            .neighbourhood__marker {
              .marker {
                &__marker {
                  position: absolute;
                  bottom: 0;
                  transform: translateX(-50%);
                  filter: drop-shadow(0px 0px 16px rgba(0, 0, 0, 0.15));
                }

                &__house {
                  position: absolute;
                  top: -66px;
                  transform: translateX(-50%);
                }
              }
            }
          }

          &__open-in-google-maps {
            position: absolute;
            top: 10px;
            right: 10px;

            z-index: 1;

            .google-maps-button {
              padding: 5px 15px;
              border-radius: 50px;
              font-family: $text-font;
              font-size: 14px;
              font-weight: 500;
              color: black;
              text-decoration: none;
              background: white;
              box-shadow: 0 4px 8px 4px rgba(black, 0.06);
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .suite {
    &__neighbourhood {
      .neighbourhood {
        &__content {
          padding-right: 0;
          .content {
            &__text {
              width: 50%;
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .suite {
    &__neighbourhood {
      .neighbourhood {
        &__content {
          padding-right: 0;
          display: flex;

          .content {
            &__text {
              padding-right: 45px;
            }
            &__map {
              flex: 0 0 50%;
              margin-top: -50px;
              height: 370px;
              border-radius: 20px;
            }

            &__open-in-google-maps {
              top: unset;
              bottom: 10px;
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .suite {
    &__neighbourhood {
      .neighbourhood {
        &__content {
          .content {
            &__text {
              padding-right: 65px;
            }
          }
        }
      }
    }
  }
}
