.suite__images {
  // Remove container fluid margin
  padding: 0;
  position: relative;

  .images-carousel-holder {
    width: 100%;
    display: block;
    overflow-x: hidden;
  }

  .images-carousel {
    display: flex;
    overflow-x: scroll;
    width: 100%;
    scroll-snap-type: x mandatory;

    &__counter {
      position: absolute;
      right: 10px;
      bottom: 10px;
      z-index: 1;
      background-color: rgba(0, 0, 0, 0.3);
      color: white;
      backdrop-filter: saturate(180%) blur(20px);
      padding: 4px 8px;
      font-size: 12px;
      border-radius: 30px;
      font-variant-numeric: tabular-nums;
      box-shadow: 0 0 0 1px rgba(white, 0.2);
    }

    &__index-detector {
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      width: 1px;
      height: 1px;
      z-index: 1;
    }

    .image-holder {
      display: block;
      flex: 0 0 100%;
      scroll-snap-align: center;

      .suite-image {
        object-fit: cover;
        height: $suite-images-height;
        width: 100%;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .suite__images {
    padding: 8px 0;
    .container {
      position: relative;
    }

    .images-carousel {
      overflow-x: visible;
      scroll-snap-type: unset;
      will-change: transform, margin-left, margin-right;

      &__show-all {
        position: absolute;
        margin-top: -60px;
        right: $container-padding;
        background: white;
        display: flex;
        align-items: center;
        padding: 3px;
        border: 1px solid rgba(110, 128, 134, 0.2);
        box-shadow: 0px 2px 4px 2px rgba(38, 49, 53, 0.06);
        border-radius: 12px;

        .divider {
          display: block;
          height: 20px;
          width: 1px;
          margin: 0 12px 0 8px;
          background-color: rgba(0, 0, 0, 0.14);
        }

        button {
          background: none;
          border: none;
          margin: 0;
          padding: 6px 12px;
          border-radius: 10px;

          &:hover {
            background-color: rgba(0, 0, 0, 0.08);
          }
        }

        .show-all {
          font-family: $primary-font;
          font-weight: 600;
          font-size: 14px;
          padding-top: 8px;
        }
        .nav {
          width: 30px;
          height: 30px;
          display: flex;
          justify-content: center;
          align-content: center;
          border-radius: 50px;
          padding: 10px;
        }
      }

      .image-holder {
        display: block;
        flex: 0 0 51%;
        scroll-snap-align: center;
        margin: 0 4px;

        &:first-of-type {
          padding-left: 4px;
        }
        &:last-of-type {
          padding-right: 4px;
        }

        .suite-image {
          height: 290px;
          border-radius: 12px;
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .suite__images {
    .images-carousel {
      .image-holder {
        display: block;
        flex: 0 0 auto;

        margin: 0 6px;

        &:first-of-type {
          padding-left: 6px;
        }
        &:last-of-type {
          padding-right: 6px;
        }

        .suite-image {
          object-fit: cover;
          height: $suite-images-desktop-height;
          width: 100%;
          width: 760px;
        }
      }
    }
  }
}
