@import "src/variables";
@import "src/mixin";

// Mixin for media queries
$small-button-width: 220px;
$small-button-width-mobile: 150px;

.online-check-in {
  &__title {
    color: $black;
    font-family: $primary-font;
    font-weight: 600;
    font-size: 36px;
    line-height: 44.1px;
    @include media-query(xl, max) {
      font-size: 30px;
      line-height: 38px;
    }
    @include media-query(md, max) {
      font-size: 20px;
      line-height: 24.5px;
    }
  }

  &__subtitle {
    color: $black;
    font-family: $primary-font;
    font-weight: 500;
    font-size: 30px;
    line-height: 36.39px;
    @include media-query(xl, max) {
      font-size: 24px;
      line-height: 30px;
    }
    @include media-query(md, max) {
      font-size: 20px;
      line-height: 24.26px;
    }
  }

  &__description {
    font-family: $text-font;
    font-weight: 400;
    font-size: 14px;
    line-height: 16.94px;
    color: $gray-text;
    @include media-query(xl, max) {
      font-size: 12px;
      line-height: 14.52px;
    }
  }

  &__section-separator {
    margin: 2rem 0px;
    @include media-query(md, max) {
      margin: 1.5rem 0px;
    }
  }

  &__info {
    &--light-gray {
      color: #828282 !important;
    }
    &--gray {
      font-size: 14px;
      font-family: $text-font;
      color: $gray-text;

      @include media-query(md, max) {
        font-size: 12px;
      }
    }
    &--dark-gray {
      color: #3d4e53;
      font-family: $text-font;
      font-weight: 500;
    }
    &--xl {
      font-size: 18px;

      @include media-query(md, max) {
        font-size: 16px;
      }
    }
    &--lg {
      font-size: 16px;

      @include media-query(md, max) {
        font-size: 14px;
      }
    }
    &--md {
      font-size: 14px;

      @include media-query(md, max) {
        font-size: 14px;
      }
    }
    &--sm {
      font-size: 14px;
      @include media-query(md, max) {
        font-size: 12px;
      }
    }
    &--primary-bold {
      font-size: 14px;
      color: $primary-text;
      font-weight: 500;
      font-family: $text-font;
      @include media-query(md, max) {
        font-size: 12px;
      }
    }
    &--error {
      &--text {
        color: #e04646 !important;
      }
      &--text-dark {
        color: #b33838 !important;
      }
      &--border {
        border: 1px solid #e04646 !important;
      }
      &--bg {
        background-color: #fdf2f2;
      }
    }

    &--bold {
      font-weight: 600;
    }
  }

  &__button {
    font-family: $primary-font;
    font-size: 14px;
    letter-spacing: 2%;
    height: 50px;
    font-weight: 600;
    border-radius: 8px;
    border-radius: $border-radius-xl;
    @include media-query(md, max) {
      height: 44px;
    }
    &--secondary {
      background: none !important;
      background-color: $white !important;
      color: $primary-text !important;
      border: 1px solid $primary-text !important;
      border-radius: $border-radius-xl;
      -webkit-border-radius: $border-radius-xl;
      -moz-border-radius: $border-radius-xl;
      -ms-border-radius: $border-radius-xl;
      -o-border-radius: $border-radius-xl;
    }
    &--small {
      width: $small-button-width;
      @include media-query(md, max) {
        width: $small-button-width-mobile;
        font-size: 12px;
      }
    }
    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
}
.id-verification {
  form {
    button {
      min-height: unset;
      font-size: 15px;
    }
  }
}

.remove-button-background {
  background: none !important;
}
.primary-font {
  font-family: $primary-font !important;
}
.text-font {
  font-family: $text-font !important;
}
.font-normal {
  font-weight: 400 !important;
}
.font-medium {
  font-weight: 500 !important;
}
.font-semibold {
  font-weight: 600 !important;
}
.font-bold {
  font-weight: 700 !important;
}
