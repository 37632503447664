.search_page__filters {
  $filters-button-height: 56px;

  position: sticky;
  z-index: 3;
  width: 100%;
  max-width: 100vw;
  top: 0;
  left: 0;
  height: $filters-button-height;
  display: flex;
  justify-content: center;

  .filters-button {
    position: absolute;
    z-index: 2;
    padding: 0;
    border: none;
    border-radius: 0px;
    background: white;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: inherit;
    width: 100%;
    touch-action: manipulation;
    padding: 2px $container-padding 0 $container-padding;

    &.hide {
      opacity: 0;
      pointer-events: none;
    }

    &:focus {
      outline: none;
    }

    &:focus-visible {
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0 0 0 3px $primary inset;
    }

    .section {
      display: flex;
      flex-direction: column;
      text-align: left;
      font-family: $primary-font;
      font-weight: 600;

      .title {
        display: block;
        height: 14px;
        font-size: 11px;
        color: $primary;
      }

      .data {
        font-size: 13px;
        color: black;
        max-width: 150px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &--empty {
          font-weight: 500;
        }
      }
    }

    .right {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .point {
        position: relative;
        display: block;
        width: 4px;
        margin: 11px 8px;
        height: 100%;

        &::after {
          content: "";
          position: absolute;
          bottom: -8px;
          display: block;
          height: 3px;
          width: 3px;
          background: rgba(0, 0, 0, 0.4);
          border-radius: 10px;
        }
      }

      .chevron {
        display: none;

        border-left: 1px solid rgba(0, 0, 0, 0.1);
        padding-left: 11px;
        margin-left: 14px;
        .icon {
          color: $primary;
        }
      }
    }
  }
}

.search_page__filters-area {
  min-width: 320px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 3;
  transform: translateY(-100%);
  background: white;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 16px 32px 16px rgba(0, 0, 0, 0);
  pointer-events: none;
  transition: all 550ms cubic-bezier(0.85, 0, 0.15, 1);
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;

  .header {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;

    .title {
      margin: 0;
      padding: 0;
      color: black;
      font-size: 16px;
    }

    .close-button {
      position: absolute;
      right: 15px;
      border: none;
      background: none;
      padding: 0;
      margin: 0;

      .icon {
        width: 24px;
        height: 24px;
        color: $primary;
      }
    }
  }

  .filters {
    padding: 0 $container-padding;
    padding-bottom: 15px;

    &__group {
      margin-top: 10px;
      display: flex;
      gap: 8px;
    }

    &__group-item {
      width: 100%;
    }

    &__search-button {
      display: none;
    }

    &__date-input {
      max-width: calc(50% - 20px);
    }
  }

  &.show {
    pointer-events: all;
    transform: translateY(0);
    box-shadow: 0 16px 32px 16px rgba(0, 0, 0, 0.1);
    transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
  }
}

.search-filters {
  $border-radius: 10px;
  $box-shadow-width: 4px;

  .select-filter-container {
    border-radius: none;
    height: inherit;
  }
  .select-filter {
    $menu-item-gap: 5px;

    // Input area
    &__control {
      cursor: pointer;
      border: none;
      border-radius: $border-radius;
      margin: 0;
      padding: 0;
      height: inherit;
      background: none;
      padding-top: 20px;
      padding-bottom: 12px;
      padding-left: 11px;
      height: calc(100% - 2px);
    }

    &__control--menu-is-open {
      box-shadow: 0 0 0 $box-shadow-width $primary;
    }
    &__value-container {
      padding: 0;
    }

    &__placeholder {
      margin: 0;
    }
    &__indicators {
      display: none;
    }

    &__single-value {
      font-family: $primary-font;
      color: black;
      font-weight: 600;
    }

    // Menu
    &__menu {
      margin-top: 10px;
      border-radius: $border-radius;
      margin-left: calc(-#{$box-shadow-width} + 1px);
      width: calc(100% + #{$box-shadow-width * 2} - 2px);

      background: rgba(255, 255, 255, 0.78);
      backdrop-filter: saturate(0%) blur(16px);
      box-shadow: 0 0 0 1px white, 0 6px 12px 6px rgba(0, 0, 0, 0.12);
    }

    &__menu-list {
      padding: $menu-item-gap;
    }
    &__option {
      padding: 14px 12px 12px 12px;
      font-family: $primary-font;
      font-size: 16px;
      font-weight: 600;
      border-radius: calc(#{$border-radius} - 2px);
      margin-bottom: 4px;
      cursor: pointer;

      &:last-of-type {
        margin-bottom: 0px;
      }
    }
  }

  // City input
  .city_input {
    &__chevron {
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

@media (min-width: 375px) {
  .search_page__filters {
    .filters-button {
      .right {
        .chevron {
          display: flex;
        }
      }
    }
  }
  .search-filters {
    .guest_input {
      &__label-text {
        display: inline-block;
      }
    }
  }
}

@media (min-width: 768px) {
  .search_page__filters {
    position: fixed;
    z-index: 4;
    margin-left: 200px;
    left: 0;
    height: $desktop-nav-height;
    display: flex;
    align-items: center;
    width: auto;

    .filters-button {
      position: relative;
      border-radius: 50px;
      padding: 0;
      padding-left: 20px;
      padding-right: 20px;
      top: unset;
      height: 52px;
      width: 100%;
      transition: unset;
      border: 1px solid rgba($primary-darkest, 0.2);
      box-shadow: none;
      transition: all 200ms cubic-bezier(0.645, 0.045, 0.355, 1);
      min-width: 300px;

      &--basic {
        width: 220px !important;
        min-width: unset !important;
        display: flex;
        justify-content: center;
        font-family: $primary-font;
        font-weight: 600;
        font-size: 16px;
        padding-top: 2px;
        color: $body-text-color;
        height: 48px;

        .icon {
          margin-right: 10px;
          margin-bottom: 1px;
          filter: $icon-to-primary;
        }
        .text {
          margin-right: 10px;
        }
      }

      &.hide {
        transform: unset;
      }
      &--hidden {
        opacity: 0;
        transform: translateY(5px);
        transition: all 60ms cubic-bezier(0.645, 0.045, 0.355, 1);
        pointer-events: none;
      }

      &:hover {
        box-shadow: 0 3px 6px 3px rgba(0, 0, 0, 0.05);
      }
    }
  }
}

@media (min-width: 992px) {
  $button-radius: 32px;

  .search_page__filters-area {
    border-bottom: unset;
    box-shadow: unset;
    transform: unset;
    transition: none;
    background: none;
    border-radius: 0;

    width: 100%;
    height: 100%;

    overflow-y: scroll;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 0;

    opacity: 0;
    pointer-events: none;
    user-select: none;
    display: flex;
    justify-content: center;

    background: none;
    margin-top: $desktop-nav-height;

    .bg {
      width: 100%;
      min-height: 800px;
      height: 100%;
      position: absolute;
      z-index: -1;
      opacity: 0;
      background: rgba(0, 0, 0, 0.35);
      transition: all 500ms cubic-bezier(0.16, 1, 0.3, 1);
    }

    .content {
      position: absolute;
      width: 100%;
      height: 140px;
      background: white;
    }

    .header {
      position: absolute;
      .title {
        display: none;
      }

      .close-button {
        display: none;

        &:focus {
          outline: none;
        }

        &:hover {
          background: rgb(238, 238, 238);
        }

        &:focus-visible {
          box-shadow: 0 0 0 3px $primary-darkest, 0 0 0 6px white;
        }
      }
    }

    .filters {
      padding: 4px 8px 8px 8px;
      border-radius: 50px;
      border: 1px solid rgba($primary-darkest, 0.12);
      display: flex;
      flex-direction: column;
      max-width: 90vw;
      width: 1050px;
      margin: 0 auto;

      transform: translateY(-50px);
      transition: all 500ms cubic-bezier(0.16, 1, 0.3, 1);

      background: white;

      &__group {
        margin-top: 0;
        display: inherit;
      }

      &__inputs,
      &__group {
        display: flex;
        align-items: flex-start;
      }

      &__group {
        width: 70%;
      }

      // Custom input
      .big-input {
        &__label {
          padding-top: 14px;
          padding-left: 25px;
          color: rgba(0, 0, 0, 0.6);
          font-weight: 500;
          font-size: 12px;
        }
        &__children,
        &__button {
          border: none;
          border-radius: 80px;
          box-shadow: none;
          padding-top: 5px;
          padding-left: 25px;
          height: 64px;
          font-size: 17px;

          &:focus {
            outline: none;
          }
          &:focus-visible {
            box-shadow: 0 0 0 2px white, 0 0 0 4px $primary-darkest;
          }

          &:hover,
          &:focus {
            &::after {
              display: block;
              content: "";
              position: absolute;
              bottom: -5px;
              height: 3px;
              width: 100%;
              background: $primary-dark;
            }
          }
        }
      }

      &__city-input {
        width: 30%;
        margin-right: 8px;
        .select-filter__control {
          padding-top: 20px;
          padding-bottom: 20px;
        }
      }

      &__guest-input {
        width: 70%;
        .guests__holder {
          padding-left: 0;
        }

        .big-input {
          &__children,
          &__button {
            &:hover,
            &:focus {
              &::after {
                display: none;
              }
              box-shadow: none;
            }
          }
        }
      }

      &__date-input {
        max-width: 60%;
      }

      &__search-button {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        margin: 0;
        width: 68px;
        height: 60px;
        border: none;
        margin-left: 40px;
        border-radius: 60px;
        font-size: 22px;
        margin-top: 5px;
      }

      &__date-input {
        width: 100%;

        .big-input {
          &__children,
          &__button {
            padding-top: 18px;
          }
        }
      }
    }

    &.show {
      opacity: 1;
      pointer-events: all;
      user-select: unset;
      transition: none;
      box-shadow: none;

      .content .filters {
        transform: translateY(0);
      }

      .bg {
        opacity: 1;
      }
    }

    .desktop-spawn-area {
      position: relative;
      width: 100%;
      height: max-content;
      background: white;
    }

    .custom_datepicker {
      left: 0;
      margin: 0 auto;
      margin-top: 30px;
      transform: translateX(-60px);

      .button {
        &__close {
          display: none;
        }
        &__clear {
          margin-left: 35px;
        }
      }
    }

    .search-filters {
      $border-radius: 10px;
      $box-shadow-width: 4px;

      .select-filter-container {
        border-radius: none;
        height: inherit;
      }
      .select-filter {
        // Input area
        &__control {
          padding-left: 0px;
          margin-left: -2px;
        }

        &__control--menu-is-open,
        &__control--is-focused {
          box-shadow: none;
        }

        // Menu
        &__menu {
          border-radius: 20px;
          width: 400px;
          margin-left: -30px;
          padding: 10px;
          background: white;
        }

        &__option {
          padding: 14px 20px;
          border-radius: 14px;
        }
      }
    }
  }
}

@media (min-width: 1200px) {
  .search_page__filters {
    display: flex;
    justify-content: center;
    max-width: unset;
    margin-left: 128px;
    right: unset;
    left: 0;

    .filters-button {
      flex-grow: 1;
      min-width: 320px;

      transform: none;
      opacity: 1;

      .section {
        .data {
          font-size: 15px;
        }
      }

      &--hidden {
        opacity: 0;
        transform: translateY(5px);
        transition: all 60ms cubic-bezier(0.645, 0.045, 0.355, 1);
        pointer-events: none;
      }
    }
  }
}

// ≥1800px
@media (min-width: 1800px) {
  .search_page {
    &__filters {
      display: flex;
      justify-content: center;
      left: 0;
      right: 0;
      margin: 0 auto;
      max-width: max-content;
    }
  }
}
