/* floating-modal */
.floating-modal {
  position: fixed;
  z-index: 10;
  top: 100vh;
  left: 100vw;
  right: 0;
  width: 100vw;
  height: 100vh;
  background: $body-bg;

  overflow-y: scroll;
  padding-bottom: 80px;
  min-width: 320px;

  &.show {
    top: 0;
    left: 0;
  }

  .container {
    position: relative;
    margin-top: 0;
    overflow-y: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none; /* Chrome */
    }

    .modal-form {
      margin-top: 0;
    }
  }

  .close-button {
    $size: 28px;
    $padding: 12px;
    $offset: calc(#{$size} - #{$padding});

    z-index: 10;
    display: block;
    position: absolute;
    cursor: pointer;
    right: $offset;
    top: $offset;
    padding: $padding;
    border: none;
    background: none;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    border-radius: $size;

    .close-icon {
      width: $size;
      height: $size;
    }

    &:focus {
      outline: none;
    }

    &:focus-visible {
      box-shadow: 0 0 0 2px $primary;
    }

    &:hover {
      background: rgba($primary-darkest, 0.1);
    }
  }
}

@media (min-width: 768px) {
  .floating-modal {
    opacity: 0;
    transition: opacity 0.2s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 0px;
    background: none;

    &.show {
      .bg {
        position: fixed;
        width: 100vw;
        height: 100vh;
        left: 0;
        top: 0;
        background: rgba(0, 0, 0, 0.5);
      }

      .container {
        background: white;
        padding: 20px;
        border-radius: 12px;
        transform: translateY(0);
      }
      .auth-floating-container {
        background: none;
      }
    }

    .container {
      max-width: 600px;
      padding: 0;
      margin: 0;
      transform: translateY(80px);
      transition: transform 0.6s cubic-bezier(0.25, 1, 0.5, 1);
    }
    .auth-floating-container {
      max-width: max-content;

      .close-button {
        top: 30px;
        right: 30px;
      }
    }

    .close-button {
      $size: 28px;
      $padding: 8px;

      top: 12px;
      right: 8px;

      padding: $padding;
      border-radius: $size;

      .close-icon {
        width: $size;
        height: $size;
      }

      &:focus-visible {
        box-shadow: 0 0 0 2px $primary;
      }
    }

    &.show {
      opacity: 1;
    }
  }
}

@media (max-height: 800px) and (min-width: 768px) {
  .floating-modal {
    align-items: flex-start;

    .container {
      top: 50px;
    }
  }
}

@media (min-width: 992px) {
  .floating-modal {
  }
}

@media (min-width: 1200px) {
  .floating-modal {
  }
}
