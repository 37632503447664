.suite__about {
  margin-top: -20px;
  .about {
    &__meta {
      margin-bottom: 20px;
      display: flex;
      overflow-x: auto;
      .meta {
        &__item {
          font-size: 13px;
          white-space: nowrap;

          .meta-icon {
            transform: scale(0.9);
            filter: $icon-to-primary;
          }
        }
      }
    }
    &__content {
      line-height: 164.5%;
      font-size: 14px;
    }
  }
}

@include media-breakpoint-up(md) {
  .suite__about {
    margin-top: -15px;
    .about {
      &__meta {
        margin-bottom: 15px;
        .meta {
          &__item {
            font-size: 14px;

            .meta-icon {
              display: inline-block;
              transform: unset;
              margin-right: 5px;
            }
          }
        }
      }
      &__content {
        line-height: 164.5%;
        font-size: 14px;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .suite {
  }
}

@include media-breakpoint-up(xl) {
  .suite {
  }
}
