@media (min-width: 1281px) {
  #countdown-container {
    text-align: right;
  }
}
@media (max-width: 768px) {
  #crypto-network-selector
  {
    margin-top: 20px;
  }
  #crypto-qr-image {
    margin-top: 20px;
  }
}