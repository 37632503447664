.form-control {
  border: none;
  margin: 0;
  padding: 17px 15px;
  height: 55px;
  border-radius: 6px;
}

form {
  .btn,
  button {
    min-height: 55px;
    border-radius: 8px;
    font-weight: 500;
    font-size: 15px;
    border: none;
    background: $primary;
    background: linear-gradient(
      90deg,
      tint($primary, 5%) 0%,
      shade($primary, 5%) 100%
    );
  }
}

.btn {
  width: 100%;
}

.btn-primary {
  color: white;
}
